import settings from "../../settings";
import instance from "./instance.service";
import { getAuthHeader } from "./auth.service";

const basePath = `${settings.API_URL}`;

export default {
  getTrainingsList(filterData, successCallback, errorCallback) {
    instance
      .post(`${basePath}user/trainings`, filterData, {
        headers: getAuthHeader()
      })
      // .get(`${basePath}user/trainings`, { headers: getAuthHeader() })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getTrainingsFiltersData(successCallback, errorCallback) {
    instance
      .get(`${basePath}user/trainings/filters`, { headers: getAuthHeader() })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  }
};
