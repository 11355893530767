<template>
  <div>
    <NoticeSubscriptionRenewal v-if="!userSubscriptions"/>

    <div class="flex progress-wrap">
      <div class="bg-white padding-20px progress-col">
        <div class="portlet-title">
          <div class="caption">
            <p>Параметры</p>
          </div>
        </div>
        <div class="protlet-body">
          <div
            :class="{ loader: isLoading.actualBodyData }"
            class="table-scrollable"
          >
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Параметр</th>
                  <th>Значение</th>
                  <th>Действия</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Вес</td>
                  <td>{{ actualBodyData.weight || 0 }} кг</td>
                  <td>
                    <button
                      @click="showVideoModal('weight')"
                      class="btn btn--round"
                      type="button"
                    >
                      <i class="fas fa-info"></i>
                    </button>
                    <button
                      @click="editBodyData('weight')"
                      class="btn amrest-orange btn--round"
                      type="button"
                    >
                      <i class="fas fa-pencil-alt"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Обхват талии</td>
                  <td>{{ actualBodyData.waist || 0 }} см</td>
                  <td>
                    <button
                      @click="showVideoModal('waist')"
                      class="btn dark btn--round"
                      type="button"
                    >
                      <i class="fas fa-info"></i>
                    </button>
                    <button
                      @click="editBodyData('waist')"
                      class="btn amrest-orange btn--round"
                      type="button"
                    >
                      <i class="fas fa-pencil-alt"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Обхват бёдер</td>
                  <td>{{ actualBodyData.hip || 0 }} см</td>
                  <td>
                    <button
                      @click="showVideoModal('hip')"
                      class="btn dark btn--round"
                      type="button"
                    >
                      <i class="fas fa-info"></i>
                    </button>
                    <button
                      @click="editBodyData('hip')"
                      class="btn amrest-orange btn--round"
                      type="button"
                    >
                      <i class="fas fa-pencil-alt"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Обхват груди</td>
                  <td>{{ actualBodyData.chest || 0 }} см</td>
                  <td>
                    <button
                      @click="showVideoModal('chest')"
                      class="btn dark btn--round"
                      type="button"
                    >
                      <i class="fas fa-info"></i>
                    </button>
                    <button
                      @click="editBodyData('chest')"
                      class="btn amrest-orange btn--round"
                      type="button"
                    >
                      <i class="fas fa-pencil-alt"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Левая рука</td>
                  <td>{{ actualBodyData.left_hand || 0 }} см</td>
                  <td>
                    <button
                      @click="showVideoModal('hands')"
                      class="btn dark btn--round"
                      type="button"
                    >
                      <i class="fas fa-info"></i>
                    </button>
                    <button
                      @click="editBodyData('left_hand')"
                      class="btn amrest-orange btn--round"
                      type="button"
                    >
                      <i class="fas fa-pencil-alt"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Правая рука</td>
                  <td>{{ actualBodyData.right_hand || 0 }} см</td>
                  <td>
                    <button
                      @click="showVideoModal('hands')"
                      class="btn dark btn--round"
                    >
                      <i class="fas fa-info"></i>
                    </button>
                    <button
                      @click="editBodyData('right_hand')"
                      class="btn amrest-orange btn--round"
                    >
                      <i class="fas fa-pencil-alt"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Левая нога</td>
                  <td>{{ actualBodyData.left_leg || 0 }} см</td>
                  <td>
                    <button
                      @click="showVideoModal('legs')"
                      class="btn dark btn--round"
                    >
                      <i class="fas fa-info"></i>
                    </button>
                    <button
                      @click="editBodyData('left_leg')"
                      class="btn amrest-orange btn--round"
                      type="button"
                    >
                      <i class="fas fa-pencil-alt"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Правая нога</td>
                  <td>{{ actualBodyData.right_leg || 0 }} см</td>
                  <td>
                    <button
                      @click="showVideoModal('legs')"
                      class="btn dark btn--round"
                    >
                      <i class="fas fa-info"></i>
                    </button>
                    <button
                      @click="editBodyData('right_leg')"
                      class="btn amrest-orange btn--round"
                      type="button"
                    >
                      <i class="fas fa-pencil-alt"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-divider"></div>
      <div class="bg-white padding-20px progress-col">
        <div class="portlet-title flex flex--center">
          <div class="caption">
            <p>Фотографии</p>
          </div>
          <div class="actions">
            <a class="btn progress--info" data-toggle="modal" href="#example">
              Как правильно сделать фото
            </a>
          </div>
        </div>
        <div class="tabbable tabbable-tabdrop">
          <div>
            <div class="col-lg-12">
              <ul class="nav nav-tabs">
                <li :class="{ active: getActiveTab(1) }">
                  <a
                    @click="setActiveTab"
                    aria-expanded="true"
                    data-toggle="tab"
                    href="#tab1"
                  >
                    Анфас
                  </a>
                </li>
                <li :class="{ active: getActiveTab(2) }">
                  <a
                    @click="setActiveTab"
                    aria-expanded="true"
                    data-toggle="tab"
                    href="#tab2"
                  >
                    Профиль
                  </a>
                </li>
                <li :class="{ active: getActiveTab(3) }">
                  <a
                    @click="setActiveTab"
                    aria-expanded="true"
                    data-toggle="tab"
                    href="#tab3"
                  >
                    Сзади
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-lg-12">
              <div class="tab-content">
                <div
                  :class="{ active: getActiveTab(1) }"
                  class="tab-pane"
                  id="tab1"
                >
                  <MeasurementsPhotoTab
                    @crop-photo="cropPhoto"
                    :photos="photos.front"
                    angle="front"
                    ref="photo-tab-front"
                    :key="shit"
                  />
                </div>
                <div
                  :class="{ active: getActiveTab(2) }"
                  class="tab-pane"
                  id="tab2"
                >
                  <MeasurementsPhotoTab
                    @crop-photo="cropPhoto"
                    :photos="photos.side"
                    angle="side"
                    ref="photo-tab-side"
                    :key="shit"
                  />
                </div>
                <div
                  :class="{ active: getActiveTab(3) }"
                  class="tab-pane"
                  id="tab3"
                >
                  <MeasurementsPhotoTab
                    @crop-photo="cropPhoto"
                    :photos="photos.back"
                    angle="back"
                    ref="photo-tab-back"
                    :key="shit"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="col-lg-12">
        <div class="padding-20px bg-white bg-white--transparent">
          <div class="portlet-title">
            <div class="caption">
              <p>Изменения</p>
            </div>
          </div>

          <div v-if="graphData.length === 0">
            Графики будут доступны по мере добавления замеров
          </div>
          <div v-else>
            <div
              :class="{ loader: isLoading.allBodyData }"
              class="protlet-body col-md-12"
            >
              <select v-model="graphView">
                <option
                  v-for="graph of graphs"
                  v-show="graph.show"
                  :value="graph.type"
                  :key="`graph-type-option-${graph.type}`"
                >
                  {{ graph.label }}
                </option>
              </select>

              <apexchart
                ref="processChart"
                height="400"
                :options="options"
                :series="series"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="allListBodyData.length" class="data">
      <h3 class="data-title mb-4">
        Мои данные
      </h3>
      <div class="data-row d-flex">
        <div class="col-3">
          <div class="data-name text-center">Дата</div>
        </div>
        <div class="col-3">
          <div class="data-name text-center">Параметр</div>
        </div>
        <div class="col-3">
          <div class="data-name text-center">Значение</div>
        </div>
        <div class="col-3">
          <div class="data-name text-center">Действие</div>
        </div>
      </div>
      <div
        v-for="data in allListBodyData"
        :key="data.id"
        class="data-row d-flex"
      >
        <div class="col-3">
          <div class="data-value text-center">{{ moment(data.date).format("DD.MM.YYYY") }}</div>
        </div>
        <div class="col-3">
          <div class="data-value text-center">{{ data.name }}</div>
        </div>
        <div class="col-3">
          <div class="data-value text-center">{{ data.value }} {{ data.type === 'weight' ? 'кг' : 'см' }}</div>
        </div>
        <div class="col-3">
          <div class="d-flex justify-content-center align-items-center">
            <div @click="editBodyData(data.type, data)" class="data-icon mr-2">
              <img src="../assets/img/edit.svg" alt="">
            </div>
            <div @click="confirmRemoveItemAllListBodyData(data)" class="data-icon ml-2">
              <img src="../assets/img/delete.svg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      aria-hidden="true"
      class="modal fade"
      id="videoExample"
      role="videoExample"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-hidden="true"
              class="close"
              data-dismiss="modal"
              type="button"
            ></button>
            <h4 class="modal-title">
              <strong>{{ videoModal.title }}</strong>
            </h4>
          </div>
          <div class="modal-body modal-body--nopadding">
            <iframe
              allow="autoplay; encrypted-media"
              allowfullscreen
              frameborder="0"
              height="340"
              :src="videoModal.link"
              width="100%"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <div
      aria-hidden="true"
      class="modal fade"
      id="example"
      role="example"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-hidden="true"
              class="close"
              data-dismiss="modal"
              type="button"
            ></button>
            <h4 class="modal-title">
              <strong>Как правильно сделать фотографию</strong>
            </h4>
          </div>
          <div class="modal-body modal-body--nopadding">
            <iframe
              allow="autoplay; encrypted-media"
              allowfullscreen
              frameborder="0"
              height="340"
              src="https://www.youtube.com/embed/NCQbW6M3l9I?rel=0&showinfo=0&enablejsapi=1"
              width="100%"
              id="instructionPhoto"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <div aria-hidden="true" class="modal fade" id="cropper" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-hidden="true"
              class="close"
              data-dismiss="modal"
              type="button"
            ></button>
            <h4 class="modal-title"><strong>Обрезать фото</strong></h4>
          </div>
          <div class="modal-body modal-body--nopadding measurements-cropper">
            <vue-cropper
              :aspect-ratio="3 / 4"
              :guides="true"
              :min-crop-box-height="400"
              :min-container-width="400"
              :min-container-height="400"
              :img-style="{
                width: '400px',
                height: '400px'
              }"
              :src="photoForCrop.photo"
              :view-mode="2"
              drag-mode="crop"
              ref="cropper"
            />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Отмена
            </button>
            <button
              type="button"
              @click="uploadPhoto"
              class="btn btn-primary"
              :disabled="photoForCrop.isPhotoUploading"
            >
              Загрузить
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL -->
    <div
      aria-hidden="true"
      class="modal fade"
      id="modal"
      role="edit"
      tabindex="-1"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-hidden="true"
              class="close"
              data-dismiss="modal"
              type="button"
            ></button>
            <h4 class="modal-title">
              <strong>{{ modal.title }}</strong>
            </h4>
          </div>
          <div class="modal-body">
            <div class="mt-element-card mt-element-overlay">
              <div class="flex flex--wrap">
                <div
                  v-if="bodyUpdateError"
                  class="col-xs-12 alert alert-danger"
                >
                  <span>{{ errorMessage }}</span>
                </div>
                <div class="flex">
                  <div class="form-group">
                    <div
                      class="input-group"
                      :class="{ 'has-error': bodyUpdateError }"
                    >
                      <input
                        type="text"
                        ref="modalInput"
                        class="form-control"
                        style="min-width:90px"
                      />
                      <span class="input-group-addon">{{ modal.unit }}</span>
                    </div>
                  </div>
                  <div class="col-divider"></div>
                  <div class="form-group">
                    <button
                      @click="updateBodyData(modal.param)"
                      class="btn green-meadow"
                    >
                      <i class="fa fa-save"></i> Сохранить
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      aria-hidden="true"
      class="modal fade"
      id="remove-item-confirm"
      tabindex="-1"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-hidden="true"
              class="close"
              data-dismiss="modal"
              type="button"
            ></button>
            <h4 class="modal-title">
              <strong>Удалить этот показатель?</strong>
            </h4>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn dark btn-outline"
              data-dismiss="modal"
            >
              Отмена
            </button>
            <button
              type="button"
              class="btn red"
              data-dismiss="modal"
              @click="removeItemAllListBodyData"
            >
              Удалить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
// import "morris.js/morris";
import "inputmask/dist/jquery.inputmask";
import VueCropper from "vue-cropperjs";
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import MeasurementsPhotoTab from "@/components/MeasurementsPhotoTab.vue";
import NoticeSubscriptionRenewal from '@/components/content/NoticeSubscriptionRenewal.vue';

export default {
  name: "PageMeasurements",
  components: {
    MeasurementsPhotoTab,
    VueCropper,
    NoticeSubscriptionRenewal
  },
  data() {
    return {
      options: {
        stroke: {
          curve: "smooth"
        },
        chart: {
          id: "vuechart-example",
          type: "line"
        }
      },
      series: [],
      videoModal: {},
      graphView: "",
      graphViewValue: "",
      graphs: [
        {
          type: "weight",
          label: "Вес",
          morris: null,
          show: false
        },
        {
          type: "waist",
          label: "Обхват талии",
          morris: null,
          show: false
        },
        {
          type: "hip",
          label: "Обхват бедер",
          morris: null,
          show: false
        },
        {
          type: "chest",
          label: "Обхват груди",
          morris: null,
          show: false
        },
        {
          type: "left_hand",
          label: "Левая рука",
          morris: null,
          show: false
        },
        {
          type: "right_hand",
          label: "Правая рука",
          morris: null,
          show: false
        },
        {
          type: "left_leg",
          label: "Левая нога",
          morris: null,
          show: false
        },
        {
          type: "right_leg",
          label: "Правая нога",
          morris: null,
          show: false
        }
      ],
      currentModalValue: null,
      bodyUpdateError: false,
      errorMessage: "",
      photoForCrop: {
        photo: null,
        angle: null,
        name: null,
        isPhotoUploading: false
      },
      modal: {},
      modalVariants: {
        weight: {
          title: "Вес",
          unit: "кг",
          mask: "99[9][.9]",
          min: 30,
          max: 200
        },
        waist: {
          title: "Обхват талии",
          unit: "см",
          mask: "99[9][.9]",
          min: 40,
          max: 200
        },
        hip: {
          title: "Обхват бедер",
          unit: "см",
          mask: "99[9][.9]",
          min: 50,
          max: 200
        },
        chest: {
          title: "Обхват груди",
          unit: "см",
          mask: "99[9][.9]",
          min: 50,
          max: 200
        },
        left_hand: {
          title: "Левая рука",
          unit: "см",
          mask: "99[.9]",
          min: 15,
          max: 60
        },
        right_hand: {
          title: "Правая рука",
          unit: "см",
          mask: "99[.9]",
          min: 15,
          max: 60
        },
        left_leg: {
          title: "Левая нога",
          unit: "см",
          mask: "99[9][.9]",
          min: 30,
          max: 100
        },
        right_leg: {
          title: "Правая нога",
          unit: "см",
          mask: "99[9][.9]",
          min: 30,
          max: 100
        }
      },
      shit: new Date().getTime(),
      itemForRemoveAllListBodyData: null,
      itemForEditAllListBodyData: null
    };
  },
  computed: {
    actualBodyData() {
      return this.$store.getters.actualBodyData;
    },
    allListBodyData() {
      return this.$store.getters.allListBodyData;
    },
    bodyData() {
      return this.$store.getters.bodyData;
    },
    graphData() {
      return this.$store.getters.measurementsGraphData;
    },
    isLoading() {
      return this.$store.getters.measurementsLoaders;
    },
    photos() {
      return this.$store.getters.measurementsPhotos;
    },
    userSubscriptions() {
      return this.$store.getters.userSubscriptions;
    },
  },
  methods: {
    getActiveTab(id) {
      const activeTab =
        parseInt(localStorage.getItem("measurements-active-tab"), 10) ===
        parseInt(id, 10);

      return activeTab;
    },
    setActiveTab(event) {
      const id = event.target.getAttribute("href").substr(-1);

      localStorage.setItem("measurements-active-tab", id);

      this.$nextTick(() => {
        this.shit = new Date().getTime();
      });
    },
    filterGraphs() {
      for (const graphLocal of this.graphs) {
        if (this.graphData[graphLocal.type]) {
          graphLocal.show = true;

          if (!this.graphView) {
            this.graphView = graphLocal.type;
          }
        } else {
          graphLocal.show = false;
        }
      }
    },
    updateGraph(value) {
      let newGraphName = "";
      let newGraphData = [];

      for (const graph of this.graphs) {
        if (graph.type === value) {
          newGraphName = graph.label;
        }
      }
      if (this.graphData[value]) {
        this.graphData[value].forEach(item => {
          newGraphData.push({
            x: item.y,
            y: item.a
          });
        });
      }

      this.$refs.processChart.updateSeries(
        [
          {
            data: newGraphData,
            name: newGraphName
          }
        ],
        true
      );

      this.graphView = value;
    },
    renderGraph() {
      /* eslint-disable no-undef */
      this.filterGraphs();
    },
    cropPhoto(image, angle) {
      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = event => {
          this.photoForCrop.name = image.name;
          this.photoForCrop.image = event.target.result;
          this.$refs.cropper.replace(event.target.result);
          this.photoForCrop.angle = angle;
        };

        reader.readAsDataURL(image);

        $("#cropper").modal({
          backdrop: "static",
          keyboard: false
        });
      }
    },
    uploadPhoto() {
      const lastPhotosLength = this.photos[this.photoForCrop.angle]
        .all_photos_before_last.length;

      if (this.photoForCrop.image) {
        this.photoForCrop.isPhotoUploading = true;

        this.$refs.cropper
          .getCroppedCanvas({ width: 720, height: 960 })
          .toBlob(blob => {
            const formData = new FormData();

            formData.append(
              `photo_${this.photoForCrop.angle}`,
              blob,
              this.photoForCrop.name
            );

            this.$store.dispatch("uploadPhoto", formData).then(() => {
              this.photoForCrop.photo = "";

              this.$store
                .dispatch("getPhotos", this.photoForCrop.angle)
                .then(() => {
                  const currentPhotosLength = this.photos[
                    this.photoForCrop.angle
                  ].all_photos_before_last.length;

                  if (lastPhotosLength !== currentPhotosLength) {
                    this.$refs[
                      `photo-tab-${this.photoForCrop.angle}`
                    ].goToLastSlide();
                  }
                  this.photoForCrop.isPhotoUploading = false;

                  $("#cropper").modal("hide");
                });
            });
          });
      }
    },
    showVideoModal(mode) {
      this.videoModal = this.$store.state.measurements.videoModals[mode];
      $("#videoExample").modal("toggle");
    },
    editBodyData(param, itemForEditAllListBodyData) {
      this.itemForEditAllListBodyData = null;

      if (itemForEditAllListBodyData) {
        this.itemForEditAllListBodyData = itemForEditAllListBodyData;
      }

      const self = this;

      this.modal = this.modalVariants[param];
      this.modal.param = param;
      this.modal.placeholder = this.actualBodyData[param];

      $(this.$refs.modalInput)
        .inputmask(this.modalVariants[param].mask, { placeholder: "" })
        .on("input", event => {
          const input = $(event.target);

          self.currentModalValue = input.val();
          self.bodyUpdateError = false;
        });

      $("#modal").modal("toggle");
    },
    updateBodyData(param) {
      if (!this.currentModalValue) {
        this.errorMessage = "Введи значение";
        this.bodyUpdateError = true;

        return;
      }

      const currentValueArray = this.currentModalValue.split(".");
      const currentValue =
        currentValueArray.length === 2
          ? parseFloat(this.currentModalValue)
          : parseInt(this.currentModalValue, 10);

      if (
        currentValue > this.modalVariants[param].max ||
        currentValue < this.modalVariants[param].min
      ) {
        this.errorMessage = `Это значение должно быть от ${this.modalVariants[param].min}.0 до ${this.modalVariants[param].max}.0`;
        this.bodyUpdateError = true;

        return;
      }

      if (this.itemForEditAllListBodyData) {
        const payload = {
          parameter: this.itemForEditAllListBodyData.type,
          id: this.itemForEditAllListBodyData.id,
          value: this.currentModalValue
        }

        this.$store.dispatch("editItemAllListBodyData", payload)
          .then(() => {
            this.$store.dispatch(ADD_BODY_CLASSNAME, "content-loading");
            this.$store.dispatch("getActualBodyData");
            this.$store.dispatch("getAllListBodyData");
            this.$store.dispatch("getAllBodyData").then(() => {
              this.updateGraph(this.itemForEditAllListBodyData.type);
              this.$store.dispatch(REMOVE_BODY_CLASSNAME, "content-loading");
            });

            $("#modal").modal("hide");
          })
          .catch(() => {
            this.bodyUpdateError = true;
          });
      } else {
        this.$store
          .dispatch("updateBodyData", {
            [param]: this.currentModalValue
          })
          .then(() => {
            this.$store.dispatch(ADD_BODY_CLASSNAME, "content-loading");
            this.$store.dispatch("getActualBodyData");
            this.$store.dispatch("getAllListBodyData");
            this.$store.dispatch("getAllBodyData").then(() => {
              this.updateGraph(param);
              this.$store.dispatch(REMOVE_BODY_CLASSNAME, "content-loading");
            });

            $("#modal").modal("hide");
          })
          .catch(() => {
            this.bodyUpdateError = true;
          });
      }
    },
    confirmRemoveItemAllListBodyData(data) {
      this.itemForRemoveAllListBodyData = data;

      $("#remove-item-confirm").modal("show");
    },
    removeItemAllListBodyData() {
      const payload = {
        parameter: this.itemForRemoveAllListBodyData.type,
        id: this.itemForRemoveAllListBodyData.id
      }

      this.$store.dispatch("removeItemAllListBodyData", payload)
        .then(() => {
          this.$store.dispatch(ADD_BODY_CLASSNAME, "content-loading");
          this.$store.dispatch("getActualBodyData");
          this.$store.dispatch("getAllListBodyData");
          this.$store.dispatch("getAllBodyData").then(() => {
            this.updateGraph(this.itemForRemoveAllListBodyData.type);
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "content-loading");
          });
        })
        .catch(() => {
          this.bodyUpdateError = true;
        });
    },
    moment
  },
  created() {
    this.$store.dispatch("getActualBodyData");
    this.$store.dispatch("getAllListBodyData");
    this.$store.dispatch("getAllBodyData").then(() => {
      this.renderGraph();
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "content-loading");
    });
  },
  mounted() {
    this.$store.dispatch(ADD_BODY_CLASSNAME, "content-loading");
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Мои прогресс" }]);

    if (!localStorage.getItem("measurements-active-tab")) {
      localStorage.setItem("measurements-active-tab", "1");
    }

    $("#modal").on("hidden.bs.modal", () => {
      this.currentModalValue = null;
      this.bodyUpdateError = false;
      this.errorMessage = "";
      this.$refs.modalInput.value = "";
    });

    $("#videoExample").on("hidden.bs.modal", () => {
      this.videoModal = {};
    });

    $("#example").on("hidden.bs.modal", () => {
      $("#instructionPhoto").attr("src", $("#instructionPhoto").attr("src"));
    });

    $("#cropper").on("hidden.bs.modal", () => {
      document.getElementById(`${this.photoForCrop.angle}-photo`).value = "";
    });
  },
  watch: {
    graphView(value) {
      this.updateGraph(value);
    }
  },
  setPageData() {
    switch (this.socialIsMyProfile) {
      case true:
        this.pageTitle = "Мой прогресс - FitStars.ru";
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Мой прогресс" }]);

        break;
      case false:
        this.pageTitle = "Мой прогресс - FitStars.ru";
        this.$store.dispatch(SET_BREADCRUMB, [
          {
            title: "Мой прогресс - FitStars.ru"
          }
        ]);

        break;
    }
  }
};
</script>

<style lang="sass">
  .data
    margin-top: 20px
    &-title
      font-size: 38px
      color: #181D31
      @media screen and (max-width: 991px)
        font-size: 20px
    &-name
      font-size: 14px
      font-weight: 700
      color: #000
    &-row
      padding: 16px 0
      &:nth-of-type(odd)
        background-color: #fff
    &-icon
      cursor: pointer
</style>