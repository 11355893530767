import authService from "../../services/auth.service";

export default {
  state: {
    token: localStorage.getItem("token") || ""
  },
  getters: {
    isLoggedIn: state => !!state.token
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        authService.login(
          user,
          data => {
            commit("auth_success", data);
            resolve();
          },
          () => {
            reject();
          }
        );
      });
    },
    login_token({ commit }, token) {
      return new Promise((resolve, reject) => {
        authService.loginToken(
          token,
          data => {
            commit("auth_success_token", data);
            resolve();
          },
          () => {
            reject();
          }
        );
      });
    },
    loginMaster({ commit }, user) {
      return new Promise((resolve, reject) => {
        authService.loginMaster(
          user,
          data => {
            commit("auth_success", data);
            resolve();
          },
          () => {
            reject();
          }
        );
      });
    },
    logout({ commit }) {
      return new Promise(resolve => {
        commit("logout");
        localStorage.removeItem("token");
        resolve();
      });
    },
    logoutSite() {
      return new Promise((resolve, reject) => {
        authService.logoutSite(
          () => {
            resolve();
          },
          () => {
            reject();
          }
        );
      });
    },
    sendRemember(context, email) {
      return new Promise((resolve, reject) => {
        authService.sendRemember(
          email,
          data => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    sendNewPassword(context, passwordData) {
      return new Promise((resolve, reject) => {
        authService.sendNewPassword(
          passwordData,
          data => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
      });
    }
  },
  mutations: {
    auth_success(state, data) {
      state.token = data.token;
    },
    auth_success_token(state, data) {
      state.token = data.token;
    },
    logout(state) {
      state.token = "";
    }
  }
};
