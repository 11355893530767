<template>
  <div class="row">
    <div class="col-xl-2"></div>
    <!--begin::Card-->
    <div class="col-xl-7 my-2">
      <!--begin::Form-->
      <div class="card-body">
        <form class="form" id="kt_nutrition_settings">
          <!--begin::Alert-->
          <div
            v-show="success"
            class="alert alert-custom alert-light-success fade show mb-10"
            role="alert"
            ref="message"
          >
            <div class="alert-icon">
              <span class="svg-icon svg-icon-3x svg-icon-success">
                <!--begin::Svg Icon | path:assets/media/svg/icons/Code/Info-circle.svg-->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                    <circle
                      fill="#000000"
                      opacity="0.3"
                      cx="12"
                      cy="12"
                      r="10"
                    />
                    <rect
                      fill="#000000"
                      x="11"
                      y="10"
                      width="2"
                      height="7"
                      rx="1"
                    />
                    <rect
                      fill="#000000"
                      x="11"
                      y="7"
                      width="2"
                      height="2"
                      rx="1"
                    />
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </div>
            <div class="alert-text font-weight-bold">
              Данные успешно обновлены
            </div>
            <div class="alert-close">
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <i class="ki ki-close"></i>
                </span>
              </button>
            </div>
          </div>
          <!--end::Alert-->
          <!--begin::Form Group-->
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Вес
            </label>
            <div class="col-9">
              <input
                class="form-control form-control-lg form-control-solid"
                type="text"
                ref="weight"
                name="weight"
                v-model="weight"
              />
            </div>
          </div>
          <!--end::Form Group-->
          <!--begin::Form Group-->
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Рост (см)
            </label>
            <div class="col-9">
              <input
                class="form-control form-control-lg form-control-solid"
                type="text"
                ref="height"
                name="height"
                v-model="height"
              />
            </div>
          </div>
          <!--end::Form Group-->
          <!--begin::Form Group-->
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Дата рождения
            </label>
            <div class="col-9">
              <b-form-datepicker
                id="example-datepicker"
                v-model="dob"
                class="mb-2"
                ref="dob"
                name="dob"
              ></b-form-datepicker>
            </div>
          </div>
          <!--end::Form Group-->
          <!--begin::Form Group-->
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Пол
            </label>
            <div class="col-9">
              <select
                class="form-control form-control-lg form-control-solid"
                ref="gender"
                name="gender"
              >
                <template v-for="(genderItem, index) in gendersList">
                  <option
                    v-bind:key="index"
                    :value="genderItem.value"
                    :selected="gender == genderItem.value ? true : false"
                  >
                    {{ genderItem.text }}
                  </option>
                </template>
              </select>
            </div>
          </div>
          <!--end::Form Group-->
          <!--begin::Form Group-->
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Моя цель
            </label>
            <div class="col-9">
              <select
                class="form-control form-control-lg form-control-solid"
                ref="target"
                name="target"
              >
                <template v-for="(targetItem, index) in targetsList">
                  <option
                    v-bind:key="index"
                    :value="targetItem.value"
                    :selected="target == targetItem.value ? true : false"
                  >
                    {{ targetItem.text }}
                  </option>
                </template>
              </select>
            </div>
          </div>
          <!--end::Form Group-->
          <!--begin::Form Group-->
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Уровень активности
            </label>
            <div class="col-9">
              <b-form-radio-group
                id="btn-radios-2"
                v-model="activity_level"
                :options="activityLevelList"
                buttons
                button-variant="outline-primary"
                size="sm"
                ref="activity_level"
                name="activity_level"
              ></b-form-radio-group>
            </div>
          </div>
          <!--end::Form Group-->
          <!--begin::Form Group-->
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Рекомендуемая программа
            </label>
            <div v-if="recomendedProgram" class="col-9">
              <router-link :to="{ name: 'training-pack-pack', params: { pack: recomendedProgram.id } }">"{{ recomendedProgram.name }}"</router-link>
            </div>
          </div>
          <!--end::Form Group-->
        </form>
      </div>
      <!--end::Form-->
      <div class="card-footer card-footer--settings d-flex flex-column align-items-center pb-0">
        <div class="d-flex flex-column">
          <button
            type="submit"
            class="btn btn-success mb-7"
            @click="save()"
            ref="kt_save_changes"
          >
            Изменить
          </button>
          <a
            @click="$emit('questionnaireOpen')"
            href="javascript:void(0)"
            class="text-decoration-underline"
          >
            Пройти анкетирование повторно
          </a>
        </div>
      </div>
    </div>
    <!--end::Card-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

export default {
  name: "AccountInformation",
  data() {
    return {
      success: false,
      height: null,
      weight: null,
      gender: null,
      dob: null,
      target: null,
      activity_level: null,
      activityLevelList: [
        { text: "Начальный", value: "1" },
        { text: "Средний", value: "2" },
        { text: "Продвинутый", value: "3" }
      ],
      targetsList: [
        { text: "Быть в тонусе", value: 1 },
        { text: "Похудеть", value: 2 },
        { text: "Стать сильнее", value: 3 },
        { text: "Улучшить гибкость", value: 4 }
      ],
      gendersList: [
        { text: "Мужской", value: "male" },
        { text: "Женский", value: "female" }
      ]
    };
  },
  computed: {
    userData() {
      return this.$store.getters.foodUserData;
    },
    recomendedProgram() {
      return this.$store.getters.foodRecomendedProgram;
    }
  },
  async mounted() {
    const account_information_form = KTUtil.getById("kt_nutrition_settings");

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Питание", route: "/nutrition" },
      { title: "Настройки питания" }
    ]);
    await this.$store.dispatch("getFoodUserData");

    this.$store.dispatch("updateFoodUserDataModal", this.userData);

    this.fv = formValidation(account_information_form, {
      fields: {
        dob: {
          validators: {
            notEmpty: {
              message: "Необходимо заполнить данные"
            }
          }
        },
        activity_level: {
          validators: {
            notEmpty: {
              message: "Выбери уровень активности"
            }
          }
        },
        gender: {
          validators: {
            notEmpty: {
              message: "Выбери пол"
            }
          }
        },
        weight: {
          validators: {
            notEmpty: {
              message: "Необходимо заполнить данные"
            },
            greaterThan: {
              inclusive: true,
              min: 25,
              message: "Вес не может быть ниже 25 кг"
            },
            lessThan: {
              inclusive: true,
              max: 300,
              message: "Вес не может превышать 300 кг"
            }
          }
        },
        target: {
          validators: {
            notEmpty: {
              message: "Выбери цель"
            }
          }
        },
        height: {
          validators: {
            notEmpty: {
              message: "Необходимо заполнить данные"
            },
            greaterThan: {
              inclusive: true,
              min: 140,
              message: "Рост не может быть ниже 140 см"
            },
            lessThan: {
              inclusive: true,
              max: 250,
              message: "Рост не может превышать 250 см"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
        submitButton: new SubmitButton()
      }
    });

    this.fv.on("core.form.valid", () => {
      const submitButton = this.$refs["kt_save_changes"];
      const gender = this.$refs.gender.value;
      const target = this.$refs.target.value;
      const activity_level = this.activity_level;

      // set spinner to submit button
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      const payload = {
        height: this.height,
        weight: this.weight,
        gender: gender,
        dob: this.dob,
        target: target,
        activity_level: activity_level
      };

      this.$store
        .dispatch("updateFoodUserData", payload)
        .then(() => {
          this.$store.dispatch("updateFoodUserDataModal", payload)
            .then(() => {
              submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
              );

              this.$notify({
                type: "success",
                title: "Личные данные изменены"
              });
            })
        })
        .catch(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });
  },
  methods: {
    save() {
      this.fv.validate();
    }
  },
  watch: {
    userData() {
      this.height = this.userData.height;
      this.weight = this.userData.weight;
      this.gender = this.userData.gender;
      this.dob = this.userData.dob;
      this.target = this.userData.target;
      this.activity_level = this.userData.activity_level;
    }
  }
};
</script>
