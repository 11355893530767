import measurementsService from "../../services/measurements.service";

const state = {
  videoModals: {
    weight: {
      link: "https://www.youtube.com/embed/sugdMRijQW0?enablejsapi=1",
      title: "Замер веса"
    },
    waist: {
      link: "https://www.youtube.com/embed/sqrizs_iYFI?enablejsapi=1",
      title: "Замер обхвата талии"
    },
    hip: {
      link: "https://www.youtube.com/embed/WA0P1i72Spg?enablejsapi=1",
      title: "Замер обхвата бедер"
    },
    chest: {
      link: "https://www.youtube.com/embed/fF5T68bXrgM?enablejsapi=1",
      title: "Замер обхвата груди"
    },
    hands: {
      link: "https://www.youtube.com/embed/VHurs9yAgIY?enablejsapi=1",
      title: "Замер обхвата рук"
    },
    legs: {
      link: "https://www.youtube.com/embed/1ta2vlARhgk?enablejsapi=1",
      title: "Замер обхвата ног"
    }
  },
  bodyData: {},
  graphData: [],
  actualBodyData: {
    weight: 0,
    waist: 0,
    hip: 0,
    chest: 0,
    left_hand: 0,
    right_hand: 0,
    left_leg: 0,
    right_leg: 0
  },
  allListBodyData: [],
  photos: {
    front: {},
    side: {},
    back: {}
  },
  isLoading: {
    actualBodyData: false,
    photos: false,
    allBodyData: false
  }
};

const getters = {
  actualBodyData: state => state.actualBodyData,
  allListBodyData: state => state.allListBodyData,
  bodyData: state => state.bodyData,
  measurementsGraphData: state => state.graphData,
  measurementsPhotos: state => state.photos,
  photos: state => state.photos,
  measurementsLoaders: state => state.isLoading
};

const actions = {
  getActualBodyData({ commit }) {
    commit("actual_body_data_loading_start");

    return new Promise((resolve, reject) => {
      measurementsService.getActualBodyData(
        data => {
          commit("set_actual_body_data", data);
          commit("actual_body_data_loading_finish");
          resolve(data);
        },
        error => {
          commit("actual_body_data_loading_finish");
          reject(error);
        }
      );
    });
  },
  getAllListBodyData({ commit }) {
    return new Promise((resolve, reject) => {
      measurementsService.getAllListBodyData(
        data => {
          commit("set_all_list_body_data", data);
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  removeItemAllListBodyData(context, payload) {
    return new Promise((resolve, reject) => {
      measurementsService.removeItemAllListBodyData(
        payload,
        data => {
          // commit("remove_item_all_list_body_data", payload)
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  editItemAllListBodyData(context, payload) {
    return new Promise((resolve, reject) => {
      measurementsService.editItemAllListBodyData(
        payload,
        data => {
          // commit("edit_item_all_list_body_data", payload)
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getPhotos({ commit }, angle) {
    return new Promise((resolve, reject) => {
      commit("photos_loading_start");

      measurementsService.getPhotos(
        angle,
        data => {
          commit("set_photos", { angle, data });
          commit("photos_loading_finish");
          resolve(data);
        },
        error => {
          reject(error);
          commit("photos_loading_finish");
        }
      );
    });
  },
  uploadPhoto(context, formData) {
    return new Promise((resolve, reject) => {
      measurementsService.uploadPhotos(
        formData,
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  removePhoto(context, photoParams) {
    return new Promise((resolve, reject) => {
      measurementsService.removePhoto(
        photoParams,
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getAllBodyData({ commit }) {
    commit("all_body_data_loading_start");

    return new Promise((resolve, reject) => {
      measurementsService.getAllBodyData(
        data => {
          const graphData = {};

          commit("set_body_data", data);

          for (const graph in data.graphData) {
            graphData[graph] = [];

            for (
              let i = 0;
              i < JSON.parse(data.graphData[graph].labels).length;
              i++
            ) {
              const label = JSON.parse(data.graphData[graph].labels)[i];
              const value = JSON.parse(data.graphData[graph].vals)[i];

              graphData[graph].push({
                y: label,
                a: value
              });
            }
          }

          commit("set_measurements_graph_data", graphData);
          commit("all_body_data_loading_finish");
          resolve(data);
        },
        error => {
          reject(error);
          commit("all_body_data_loading_finish");
        }
      );
    });
  },
  updateBodyData(context, updatedParam) {
    return new Promise((resolve, reject) => {
      measurementsService.updateBodyData(
        updatedParam,
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }
};

const mutations = {
  set_actual_body_data(state, newActualBodyData) {
    state.actualBodyData = newActualBodyData;
  },
  set_all_list_body_data(state, allListBodyData) {
    state.allListBodyData = allListBodyData;
  },
  remove_item_all_list_body_data(state, item) {
    state.allListBodyData = state.allListBodyData.filter(el => el.id !== item.id);
  },
  edit_item_all_list_body_data(state, item) {
    const indexItem = state.allListBodyData.findIndex(el => el.id === item.id);

    state.allListBodyData[indexItem].value = item.value;
  },
  set_body_data(state, newBodyData) {
    state.bodyData = newBodyData;
  },
  set_measurements_graph_data(state, newGraphData) {
    state.graphData = newGraphData;
  },
  set_photos(state, photos) {
    state.photos[photos.angle] = photos.data;
  },
  actual_body_data_loading_start(state) {
    state.isLoading.actualBodyData = true;
  },
  actual_body_data_loading_finish(state) {
    state.isLoading.actualBodyData = false;
  },
  photos_loading_start(state) {
    state.isLoading.photos = true;
  },
  photos_loading_finish(state) {
    state.isLoading.photos = false;
  },
  all_body_data_loading_start(state) {
    state.isLoading.allBodyData = true;
  },
  all_body_data_loading_finish(state) {
    state.isLoading.allBodyData = false;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
