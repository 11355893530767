import settings from "../../settings";
import $ from "jquery";
import instance from "./instance.service";
import { getAuthHeader } from "./auth.service";

const basePath = `${settings.API_URL}`;

export default {
  getLiveList(filter, successCallback, errorCallback) {
    let url = `${basePath}user/live`;
    let urlConditions = "";
    let filterEmpty = true;

    $.each(filter, (key, value) => {
      if (!value) {
        return;
      }

      urlConditions += `${filterEmpty ? "?" : "&"}${key}=${value}`;
      filterEmpty = false;
    });

    url += `${urlConditions}`;

    instance
      .get(url, { headers: getAuthHeader() })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getAuthHeader(type) {
    return {
      Authorization: `Bearer ${window.localStorage.getItem(settings.ID_TOKEN)}`,
      "Content-Type": type
    };
  }
};
