<template>
  <div class="programs">
    <!-- BEGIN: Необходимо заполнить настройки питания  -->
    <!-- <div>
      <p>Данные, необходимые для рассчета рациона, не указаны</p>

      <p>
        Пожалуйста, заполни свои
        <router-link to="/new-profile">
          настройки питания
        </router-link>
      </p>
    </div> -->
    <!-- END: Необходимо заполнить настройки питания -->

    <!-- BEGIN: Блок вывод рациона -->
    <!-- BEGIN: Блок выбора рациона в зависимости от калорий -->
    <div class="page-title diet-page">
      <h2>
        Рацион питания
      </h2>
    </div>
    <div class="diet-filter-block">
      <div
        v-for="(rationCaloriesItem, index) of rationCaloriesList"
        :key="`ration-calories-item-${index}`"
        class="diet-filter-block__filter"
        :class="[
          rationCaloriesItem.isShow === false ? 'favorite' : '',
          filter.type == rationCaloriesItem.type ? 'selected' : ''
        ]"
        @click="getRecipesData(rationCaloriesItem.type)"
      >
        <div v-if="rationCaloriesItem.type == 4 && hasFavorite == false">
          <span class="diet-filter-block__name">
            Твой рацион
          </span>
          <span class="diet-filter-block__kkal">
            Выбери рацион и нажми
            <img src="@/assets/img/icons-svg/heart.svg" alt="" />
          </span>
        </div>
        <div v-else>
          <span class="diet-filter-block__name">
            {{ rationCaloriesItem.description }}
          </span>
          <span class="diet-filter-block__kkal">
            {{
              rationCaloriesItem.type == 4
                ? `${kkalFavorite} Ккал`
                : `${
                    rationCaloriesItem.min ? rationCaloriesItem.min + " - " : ""
                  }${rationCaloriesItem.max} Ккал`
            }}
          </span>
        </div>
      </div>
    </div>
    <!-- END: Блок выбора рациона в зависимости от калорий -->

    <!-- BEGIN: Блок описания рациона -->
    <div class="diet-sort-block">
      <div class="diet-sort-block__item  diet-sort-block--reroll">
        <button class="filter-btn" type="button" @click="getRecipesData(filter.type)">
          Подобрать другой
          <img
            style="margin-left: 8px;"
            src="@/assets/img/recipe/reroll.svg"
            alt=""
          />
        </button>
      </div>
      <div class="diet-sort-block__item diet-section">
        <div class="diet-section__title">Дневной рацион на:</div>
        <div class="d-flex">
          <div class="diet-section__data">
            <p class="value">{{ `${Math.round(KBJU.kkal)}` }}</p>
            <p class="text">Ккал</p>
          </div>
          <div class="diet-section__data">
            <p class="value">{{ `${Math.round(KBJU.protein)}` }}</p>
            <p class="text">Белки</p>
          </div>
          <div class="diet-section__data">
            <p class="value">{{ `${Math.round(KBJU.fat)}` }}</p>
            <p class="text">Жиры</p>
          </div>
          <div class="diet-section__data">
            <p class="value">{{ `${Math.round(KBJU.carbon)}` }}</p>
            <p class="text">Угл.</p>
          </div>
        </div>
        <div class="text-center diet-sort-block--text">
          Вы можете исключить некоторые продукты и аллергены в <p class="open-modal" @click="openFilters">настройках рациона</p>, а также заменить конкретные блюда в карточках
        </div>
      </div>
      <div class="diet-sort-block__item diet-sort-block__item--sort">
        <button class="filter-btn filter-btn--reroll" type="button" @click="getRecipesData(filter.type)">
          Подобрать другой
          <img
            style="margin-left: 8px;"
            src="@/assets/img/recipe/reroll.svg"
            alt=""
          />
        </button>
        <div class="d-flex" style="justify-content: end;">
          <button class="filter-btn" type="button" @click="openFilters">
            <img src="@/assets/img/icons-svg/filter.svg" alt="" />
          </button>
          <button class="filter-btn" type="button" @click="addToFavorite">
            <img src="@/assets/img/icons-svg/heart.svg" alt="" />
          </button>
          <!-- <button class="filter-btn" type="button">
            <img src="@/assets/img/icons-svg/dowload.svg" alt="" />
          </button>
          <button class="filter-btn" type="button">
            <img src="@/assets/img/icons-svg/print.svg" alt="" />
          </button> -->
        </div>
      </div>
    </div>
    <div class="text-center diet-sort-block--footer-txt">
      Вы можете исключить некоторые продукты и аллергены в <p class="open-modal" @click="openFilters">настройках рациона</p>, а
      также заменить конкретные блюда в карточках
    </div>
    <!-- END: Блок описания рациона -->

    <!-- BEGIN: Блок списка блюд по типу приема пищи -->
    <div class="categorys-list">
      <div
        v-for="(recipeType, eatingType) in filters.columns"
        :key="`eating-id-${eatingType}`"
        class="category-list"
      >
        <div class="category-list__name">
          {{ eatings[eatingType] }}
        </div>

        <div class="diet-dish-wr">
          <div
            v-for="(recipe, recipeIndex) of recipes[eatingType]"
            class="diet-dish"
            :key="`eating-dishes-id-${recipeIndex}`"
          >
            <router-link :to="`/recipes/${recipe.id}`" class="diet-dish__img-wr">
              <img style="width:100%" :src="`${recipe.image.path}`" alt="" />
            </router-link>
            <div class="diet-dish__info">
              <p class="dish-category">{{ recipe.type.name }}</p>
              <p class="dish-name">
                {{ recipe.name }}
              </p>
              <div class="dish-footer">
                <div class="dish-footer__col">
                  <p class="dish-kkal">{{ `${Math.round(recipe.kkal)}` }} Ккал</p>
                </div>
                <div class="dish-footer__col">
                  <button
                    class="dish-btn"
                    type="button"
                    @click="deleteSelectedRecipe(eatingType, recipeIndex)"
                  >
                    <img src="@/assets/img/recipe/delete.svg" alt="" />
                  </button>
                  <div class="columne-divider--8"></div>
                  <button
                    class="dish-btn"
                    type="button"
                    @click="openRecipeFood(eatingType, recipeType, recipeIndex)"
                  >
                    <p class="hide-mob">Заменить</p>
                    <img class="show-mob" src="@/assets/img/recipe/reroll.svg" alt=""/>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="category-list__add category-list__add--desktop"
            @click="openRecipeFood(eatingType, recipeType)"
          >
            Добавить
          </div>
        </div>
        <div class="category-list__footer">
        </div>
      </div>
    </div>
    <div class="categorys-list__wr-btn-back">
      <router-link to="/recipes" class="categorys-list__btn-back">
        Посмотреть все рецепты
      </router-link>
    </div>
    <!-- END: Блок списка блюд по типу приема пищи -->
    <!-- END: Блок вывод рациона -->
  </div>
</template>

<script>
import $ from "jquery";
import Swal from "sweetalert2";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_RECIPES_FOOD_DATA,
  DELETE_RECIPES_FOOD_DATA,
  SET_RECIPES_FOOD_FILTER,
  UPDATE_RECIPES_FOOD_DATA,
  GET_RECIPES_FOOD_FILTERS,
  GET_RECIPES_FOOD_FILTER_BY_TYPE,
  UPDATE_RECIPES_FOOD_FAVORITES
} from "@/core/services/store/recipesFood.module";

export default {
  name: "PageRecipesOLDFood",
  data() {
    return {
      isParamsNotFound: false,
      // type: null,
      recipesForModal: [],
      rationCaloriesList: [
        {
          type: 1,
          description: "Быстро похудеть",
          min: 800,
          max: 1100,
          isShow: true
        },
        {
          type: 2,
          description: "Похудеть",
          min: 1100,
          max: 1300,
          isShow: true
        },
        {
          type: 3,
          description: "Быть в тонусе",
          min: 1300,
          max: 1600,
          isShow: true
        },
        {
          type: 4,
          description: "Любимый рацион",
          min: null,
          max: null,
          isShow: false
        }
      ],
      eatings: {
        breakfast: "Завтрак",
        "second-breakfast": "Перекус",
        lunch: "Обед",
        snack: "Перекус",
        dinner: "Ужин"
      }
    };
  },
  computed: {
    hasFavorite() {
      return this.$store.getters.recipesFoodHasFovorite;
    },
    kkalFavorite() {
      return this.$store.getters.recipesFoodKkalFovorite;
    },
    filter() {
      return this.$store.getters.recipesFoodFilter;
    },
    filters() {
      return this.$store.getters.recipesFoodFilters;
    },
    recipes() {
      return this.$store.getters.recipesFoodRecipesByRation;
    },
    KBJU() {
      return this.$store.getters.recipesFoodKBJUByRation;
    }
  },
  created() {},
  destroyed() {
    $(document).off("click", ".js-button-select-recipe-food");
  },
  mounted() {
    $(document).on("click", ".js-button-select-recipe-food", event => {
      const element = $(event.target);

      this.confirmRecipeFood(element);
    });

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Рецепты", route: "/recipes" },
      { title: "Рацион питания" }
    ]);

    this.getFilters();
    this.getRecipesData();
  },
  methods: {
    addToFavorite() {
      this.$store.dispatch(UPDATE_RECIPES_FOOD_FAVORITES, this.recipes);
    },
    getRecipesData(type = null) {
      // if (type !== null) {
      //   this.type = type;
      // }

      this.$store.dispatch(GET_RECIPES_FOOD_DATA, {
        type: type,
        allergies: this.filter.allergies
      });
    },
    confirmRecipeFood(elementButton) {
      let requestData;

      requestData = elementButton.data();

      this.$store.dispatch(UPDATE_RECIPES_FOOD_DATA, requestData).then(() => {
        Swal.close();
      });
    },
    confirmFilters() {
      let elements;
      let selected = [];

      elements = $(".js-filter-item");
      elements.each((index, item) => {
        let element = $(item);

        if (element.prop("checked") === true) {
          selected.push(parseInt(element.val()));
        }
      });

      this.$store.dispatch(SET_RECIPES_FOOD_FILTER, selected);
    },
    openRecipeFood(eatingType, recipeType, recipeIndex = null) {
      let request = this.filter;
      request.recipe_type = recipeType;
      request.eating_type = eatingType;

      this.$store
        .dispatch(GET_RECIPES_FOOD_FILTER_BY_TYPE, request)
        .then(recipes => {
          let title =
            recipeIndex !== null ? "Заменить рецепт:" : "Добавить рецепт:";

          Swal.fire({
            title: title,
            customClass: "change-recipes-modal",
            showCancelButton: true,
            html: this._htmlForAddChangeRecipe(
              recipes,
              eatingType,
              recipeIndex
            ),
            heightAuto: false,
            showConfirmButton: false
          });
        });
    },
    openFilters() {
      Swal.fire({
        title: "Исключить:",
        html: this._htmlForFilter(),
        confirmButtonText: "Подтвердить",
        confirmButtonColor: "#35D205",
        customClass: "allergy-modal",
        showCancelButton: true,
        preConfirm: this.confirmFilters,
        heightAuto: false
      });
    },
    getFilters() {
      this.$store.dispatch(GET_RECIPES_FOOD_FILTERS);
    },
    _htmlForAddChangeRecipe(recipes, eatingType, currentRecipeIndex) {
      let html = "";

      recipes.forEach(function(recipe, recipeIndex) {
        let text = `
          <div class="catalog--item__link">
            <div class="catalog-item-wrapper">
              <div class="catalog--item__image">
                <img style="width:100%" src="${recipe.image.path}" alt="" />
              </div>
              <div class="change-recipes-modal--description">
                <p class="change-recipes-modal--name">${recipe.name}</p>
                <div class="change-recipes-modal--footer">
                  <p class="kkal" title="Ккалории">
                    ${Math.round(recipe.kkal)} Ккал
                  </p>
                  <p class="cook-time" title="Время готовки">
                    <img class="disctop-icon" src="https://userapp.fitstars.ru/img/cock-time.8e0966c6.svg" alt="" />
                    ${recipe.cook_time}
                  </p>
                  <p class="reiting" title="Рейтинг">
                    <img class="disctop-icon" src="https://userapp.fitstars.ru/img/star.0f170082.svg" alt="" />
                    ${recipe.rating}
                  </p>
                </div>
              </div>
            </div>
            <div class="change-recipes-modal--btn-wr">
                <button
                  class="js-button-select-recipe-food dish-btn"
                  type="button"
                  data-eating-type="${eatingType}"
                  data-current-recipe-index="${currentRecipeIndex}"
                  data-recipe-index="${recipeIndex}"
                >
                  Выбрать
                </button>
            </div>
          </div>`;

        html = html + text;
      });

      return html;
    },
    _htmlForFilter() {
      let html = "";
      let __self__ = this;

      this.filters.allergies.forEach(function(item) {
        let text = `
          <div class="swal-checkbox-wr">
            <label class="checkbox" for="${item.id}">
              <input
                id="${item.id}"
                class="swal-checkbox-wr__label js-filter-item"
                type="checkbox"
                value="${item.id}" 
                ${
                  $.inArray(item.id, __self__.filter.allergies) > -1
                    ? "checked"
                    : ""
                }
              />
              <span class="mr-3"></span>
              ${item.value}
            </label>
          </div>`;

        html = html + text;
      });

      return html;
    },
    deleteSelectedRecipe(eatingType, index) {
      const data = {
        eatingType: eatingType,
        index: index
      };

      this.$store.dispatch(DELETE_RECIPES_FOOD_DATA, data);
    }
  },
  watch: {
    "filter.allergies": function(to, from) {
      if (this.filter.type !== 4 && this.filter.type !== null && to !== from) {
        this.getRecipesData(this.filter.type);
      }
    }
  }
};
</script>
