import settings from "../../settings";
import instance from "./instance.service";
import { getAuthHeader } from "./auth.service";

const basePath = `${settings.API_URL}`;

export default {
  getActualBodyData(successCallback, errorCallback) {
    instance
      .get(`${basePath}user/body_data/actual`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getAllListBodyData(successCallback, errorCallback) {
    instance
      .get(`${basePath}user/body_data/list`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  removeItemAllListBodyData(data, successCallback, errorCallback) {
    instance
      .post(`${basePath}user/body_data/clear`, data, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  editItemAllListBodyData(data, successCallback, errorCallback) {
    instance
      .post(`${basePath}user/body_data/update`, data, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getPhotos(angle, successCallback, errorCallback) {
    instance
      .get(`${basePath}user/photo/${angle}`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  uploadPhotos(photo, successCallback, errorCallback) {
    instance
      .post(`${basePath}user/photo/progress/store`, photo, {
        headers: getAuthHeader("multipart/form-data")
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  removePhoto(photoParams, successCallback, errorCallback) {
    instance
      .delete(
        `${basePath}user/body_data/${photoParams.date}/delete_photo/photo_${photoParams.angle}`,
        {
          headers: getAuthHeader()
        }
      )
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getAllBodyData(successCallback, errorCallback) {
    instance
      .get(`${basePath}user/body_data/all`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  updateBodyData(data, successCallback, errorCallback) {
    instance
      .post(`${basePath}user/body_data`, data, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  }
};
