/* eslint-disable no-shadow */

import ratingService from "../../services/rating.service";

const state = {
  currentModalInfo: null,
  trainings: {
    filters: {
      dateFrom: "1.04.2019",
      dateTo: new Date()
    },
    sorting: {
      field: "",
      order: "asc"
    },
    currentPage: 1,
    totalPages: 0,
    currentList: null
  },
  exercises: {
    filters: {
      name: "",
      surname: ""
    },
    sorting: {
      field: "",
      order: "asc"
    },
    currentPage: 1,
    totalPages: 0,
    currentList: null
  },
  isLoading: {
    trainings: false,
    tests: false
  }
};

const getters = {
  ratingLoaders: state => state.isLoading
};

const actions = {
  getRating({ commit, state }) {
    commit("rating_trainings_loading_start");

    ratingService.getRatingOfTrainings(
      state.trainings,
      data => {
        commit("set_rating_trainings_current_list", data.data);
        commit("set_total_trainings_pages", data.last_page);
        commit("rating_trainings_loading_finish");
      },
      () => {
        commit("rating_trainings_loading_finish");
      }
    );
  },
  getRatingTests({ commit, state }, page) {
    commit("rating_tests_loading_start");

    ratingService.getRatingOfTestsExercises(
      state.exercises,
      data => {
        commit("set_rating_exercises_current_list", data.data);
        commit("set_total_exercises_pages", data.last_page);
        commit("set_rating_exercises_current_page", page);
        commit("rating_tests_loading_finish");
      },
      () => {
        commit("rating_tests_loading_finish");
      }
    );
  },
  getModalInfo({ commit }, payload) {
    ratingService.getUserInfo(payload.id, data => {
      commit("set_current_user_modal", data);
    });
  },
  clearModalInfo({ commit }) {
    commit("clear_current_user_modal");
  },
  clearRatingTestFilters({ commit }) {
    commit("clear_rating_test_filters");
  },
  clearRatingTrainingsFilters({ commit }) {
    commit("clear_rating_trainings_filters");
  }
};

const mutations = {
  set_current_user_modal(state, info) {
    state.currentModalInfo = info;
  },
  clear_current_user_modal(state) {
    state.currentModalInfo = null;
  },
  clear_rating_test_filters() {
    state.exercises.filters.name = "";
    state.exercises.filters.surname = "";
    state.exercises.currentPage = 1;
    state.exercises.totalPages = 0;
    state.exercises.currentList = null;
  },
  clear_rating_trainings_filters() {
    state.trainings.filters.dateFrom = "01.04.2019";
    state.trainings.filters.dateTo = null;
    state.trainings.currentPage = 1;
    state.trainings.totalPages = 0;
    state.trainings.currentList = null;
  },
  set_exercises_filter_name(state, name) {
    state.exercises.filters.name = name;
  },
  set_exercises_filter_surname(state, surname) {
    state.exercises.filters.surname = surname;
  },
  set_rating_trainings_filter_date_from(state, date) {
    state.trainings.filters.dateFrom = date;
  },
  set_rating_trainings_filter_date_to(state, date) {
    state.trainings.filters.dateTo = date;
  },
  set_rating_trainings_current_list(state, list) {
    state.trainings.currentList = list;
  },
  set_rating_trainings_current_page(state, page) {
    state.trainings.currentPage = page;
  },
  set_total_trainings_pages(state, pages) {
    state.trainings.totalPages = pages;
  },
  set_rating_trainings_date_from(state, dateFrom) {
    state.trainings.filters.dateFrom = dateFrom;
  },
  set_rating_trainings_date_to(state, dateTo) {
    state.trainings.filters.dateTo = dateTo;
  },
  set_rating_exercises_current_list(state, list) {
    state.exercises.currentList = list;
  },
  set_rating_exercises_current_page(state, page) {
    state.exercises.currentPage = page;
  },
  set_total_exercises_pages(state, pages) {
    state.exercises.totalPages = pages;
  },
  set_exercises_sorting(state, sorting) {
    state.exercises.sorting.field = sorting.field;
    state.exercises.sorting.order = sorting.order ? "desc" : "asc";
  },
  rating_trainings_loading_start(state) {
    state.isLoading.trainings = true;
  },
  rating_trainings_loading_finish(state) {
    state.isLoading.trainings = false;
  },
  rating_tests_loading_start(state) {
    state.isLoading.tests = true;
  },
  rating_tests_loading_finish(state) {
    state.isLoading.tests = false;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
