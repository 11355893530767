import settings from "../../settings";
import instance from "./instance.service";
import ls from "./ls.service";

const basePath = `${settings.API_URL}`;

export const getAuthHeader = type => ({
  Authorization: `Bearer ${window.localStorage.getItem(settings.ID_TOKEN)}`,
  "Content-Type": type
});

export default {
  logoutSite(successCallback, errorCallback) {
    instance
      .get(`${basePath}user/logout`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  login(data, successCallback, errorCallback) {
    instance
      .post(`${basePath}sign-in`, data)
      .then(resp => {
        if (resp && resp.data) {
          ls.setItem(settings.ID_TOKEN, resp.data.token);
          successCallback(resp.data);
        }
      })
      .catch(err => {
        ls.removeItem(settings.ID_TOKEN);
        errorCallback(err);
      });
  },
  loginToken(data, successCallback, errorCallback) {
    // console.log("AUTH SERVICE", data.token);
    ls.setItem(settings.ID_TOKEN, data.token);
    instance
      .get(`${basePath}user/show`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        ls.removeItem(settings.ID_TOKEN);
        errorCallback(err);
      });
  },
  loginMaster(data, successCallback, errorCallback) {
    instance
      .post(`${basePath}masterlogin`, data)
      .then(resp => {
        if (resp && resp.data) {
          ls.setItem(settings.ID_TOKEN, resp.data.token);
          successCallback(resp.data);
        }
      })
      .catch(err => {
        ls.removeItem(settings.ID_TOKEN);
        errorCallback(err);
      });
  },
  sendRemember(data, successCallback, errorCallback) {
    instance
      .post(`${basePath}password/email`, data, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  sendNewPassword(data, successCallback, errorCallback) {
    if (data.password !== data.password_confirmation) {
      errorCallback("Пароли не совпадают");

      return;
    }

    instance
      .post(`${basePath}password/new`, data, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  }
};
