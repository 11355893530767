import settings from "../../settings";
import moment from "moment";
import instance from "./instance.service";
import { getAuthHeader } from "./auth.service";

const ratingAPi = `${settings.API_URL}rating/`;

export default {
  getRatingOfTrainings(state, successCallback, errorCallback) {
    let periodFrom = null;
    let periodTo = null;

    if (state.filters.dateFrom !== null) {
      periodFrom = moment(state.filters.dateFrom, "DD.MM.YYYY").format(
        "YYYY-MM-DD"
      );
    }

    if (state.filters.dateTo !== null) {
      periodTo = moment(state.filters.dateTo, "DD.MM.YYYY").format(
        "YYYY-MM-DD"
      );
    }

    instance
      .post(
        `${ratingAPi}trainings/${state.currentPage}`,
        {
          first_name: state.filters.name,
          surname: state.filters.surname,
          period_from: periodFrom,
          period_to: periodTo
        },
        {
          headers: getAuthHeader()
        }
      )
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getRatingOfTestsExercises(state, successCallback, errorCallback) {
    instance
      .post(
        `${ratingAPi}exercises`,
        {
          page: state.currentPage,
          name: state.filters.name,
          surname: state.filters.surname,
          sort: state.sorting.field,
          order: state.sorting.order
        },
        {
          headers: getAuthHeader()
        }
      )
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getUserInfo(id, successCallback, errorCallback) {
    instance
      .get(`${ratingAPi}exercises/user/${id}`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  }
};
