import videojs from "video.js";

const PlayToggle = videojs.getComponent('PlayToggle');

class CustomPlayToggle extends PlayToggle {

  constructor(player, options = {}) {
    super(player, options);

    options.replay = options.replay === undefined || options.replay;

    this.hide();

    this.on(player, 'useractive', function() {
      this.show();
    });

    this.on(player, 'userinactive', function() {
      if (!player.paused()) {
        this.hide();
      }
    });

    this.on(player, 'play', (e) => this.handlePlay(e));


    this.on(player, 'pause', function() {
      setTimeout(() => {
        let current = player.currentTime();
        if (current !== 1 && current !== 0) {
          this.show();
        }
      }, 200)
    });
    options.replay = options.replay === undefined || options.replay;

    this.on(player, 'play', (e) => this.handlePlay(e));
    this.on(player, 'pause', (e) => this.handlePause(e));

    if (options.replay) {
      this.on(player, 'ended', (e) => this.handleEnded(e));
    }
  }

  createEl() {
    return videojs.dom.createEl('div', {
      className: 'vjs-custom__play'
    });
  }

  handleSeeked(event) {
    this.removeClass('vjs-ended');

    if (this.player_.paused()) {
      this.handlePause(event);
    } else {
      this.handlePlay(event);
    }
  }

  handlePlay() {
    this.removeClass('vjs-ended', 'vjs-paused');
    this.addClass('vjs-playing');
  }

  handlePause() {
    this.removeClass('vjs-playing');
    this.addClass('vjs-paused');
  }

  handleEnded() {
    this.removeClass('vjs-playing');
    this.addClass('vjs-ended');
    this.one(this.player_, 'seeked', (e) => this.handleSeeked(e));
  }
}


export default CustomPlayToggle;