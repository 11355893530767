<template>
  <div class="topbar-item" v-if="userData">
    <div
      class="btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
    >
      <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      ></span>
      <span
        v-if="userData.name || userData.full_name"
        class="text-dark-50 font-weight-bolder d-none d-md-inline mr-3 userdata-name"
        style="white-space: nowrap"
        data-container="body"
        data-toggle="tooltip"
        data-placement="bottom"
        :title="`${userData.email}`"
      >
        Привет, {{ userData.name ? userData.name : userData.full_name }}!
      </span>
      <router-link :to="`/fitness-profile/${userData.url_slug}`">
        <v-avatar v-if="userData.avatar">
          <img :src="avatar" class="avatare" alt="avatar" />
        </v-avatar>
        <span
          v-if="!userData.avatar && userData.full_name"
          class="symbol symbol-35 symbol-light-success"
        >
          <span class="symbol-label font-size-h5 font-weight-bold">
            {{ userData.full_name.charAt(0).toUpperCase() }}
          </span>
        </span>
      </router-link>
    </div>
    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          Профиль пользователя
          <small class="text-muted font-size-sm ml-2">
            <!-- 12 messages -->
          </small>
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-custome mr-5">
            <router-link
              :to="`/fitness-profile/${userData.url_slug}`"
              @click.native="closeOffcanvas"
            >
              <img class="symbol-label" :src="avatar" alt="" />
              <i class="symbol-badge"></i>
              <!-- <i class="symbol-badge bg-success"></i> -->
            </router-link>
          </div>
          <div class="d-flex flex-column">
            <router-link
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              :to="`/fitness-profile/${userData.url_slug}`"
              @click.native="closeOffcanvas"
            >
              {{ userData.name }} {{ userData.surname }}
            </router-link>
            <div class="text-muted mt-1">
              <!-- Application Developer -->
            </div>
            <div class="navi mt-2">
              <div class="navi-item">
                <!-- <span class="navi-link p-0 pb-2"> -->
                <router-link
                  class="navi-link p-0 pb-2"
                  :to="`/fitness-profile/${userData.url_slug}`"
                  @click.native="closeOffcanvas"
                >
                  <span class="navi-text text-muted text-hover-primary">
                    {{ userData.email }}
                  </span>
                  <!--</span> -->
                </router-link>
              </div>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Выход
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <!--begin::Item-->
          <router-link
            to="/new-profile"
            @click.native="closeOffcanvas"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="../../media/svg/icons/General/Notification2.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">Мои данные</div>
                <div class="text-muted">
                  Измени настройки питания
                  <span
                    class="label label-light-danger label-inline font-weight-bold"
                  >
                    обновить
                  </span>
                </div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
          <!--begin::Item-->
          <router-link
            to="/personal-information"
            @click.native="closeOffcanvas"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="../../media/svg/icons/General/Settings-2.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">Редактировать профиль</div>
                <div class="text-muted">Измени свои личные данные</div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
          <!--begin::Item-->
          <router-link
            to="/change-password"
            @click.native="closeOffcanvas"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-danger">
                    <!--begin::Svg Icon-->
                    <inline-svg src="../../media/svg/icons/Home/Key.svg" />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">Сменить пароль</div>
                <div class="text-muted">Измени пароль своего пользователя</div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
        </div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

import settings from "@/settings";
import defaultPhoto from "@/assets/img/noimage.png";

export default {
  name: "KTQuickUser",
  data() {
    return {
      userName: "",
      userNameFirstLatter: "",
      list: [
        {
          title: "Another purpose persuade",
          desc: "Due in 2 Days",
          alt: "+28%",
          svg: "media/svg/icons/Home/Library.svg",
          type: "warning",
        },
        {
          title: "Would be to people",
          desc: "Due in 2 Days",
          alt: "+50%",
          svg: "media/svg/icons/Communication/Write.svg",
          type: "success",
        },
        {
          title: "Purpose would be to persuade",
          desc: "Due in 2 Days",
          alt: "-27%",
          svg: "media/svg/icons/Communication/Group-chat.svg",
          type: "danger",
        },
        {
          title: "The best product",
          desc: "Due in 2 Days",
          alt: "+8%",
          svg: "media/svg/icons/General/Attachment2.svg",
          type: "info",
        },
      ],
    };
  },
  mounted() {
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => {
        window.location.href = settings.APP_URL;
      });
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
  },
  computed: {
    ...mapGetters(["userData", "currentUserPersonalInfo"]),
    getFullName() {
      return (
        this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname
      );
    },
    avatar() {
      return this.userData.avatar
        ? `${this.userData.avatar.path}`
        : defaultPhoto;
    },
    picture() {
      return process.env.BASE_URL + "media/users/300_21.jpg";
    },
  },
  watch: {
    userData() {
      if (this.userData.name) {
        this.userName = `${this.userData.name} (${this.userData.email})`;
        this.userNameFirstLatter = this.userData.name.charAt(0).toUpperCase();
      } else {
        this.userName = this.userData.email;
        this.userNameFirstLatter = this.userData.email.charAt(0).toUpperCase();
      }
    },
  },
};
</script>
