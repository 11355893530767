import dictService from "@/core/services/dict.service";
import settings from "@/settings";

export default {
  state: {
    counters: {},
    appURL: settings.APP_URL
  },
  getters: {
    counters: state => state.counters,
  },
  actions: {
    getCounters({ commit }) {
      return new Promise((resolve, reject) => {
        dictService.getCounters(
          data => {
            commit("set_counters", data);
            resolve(data);
          },
          () => {
            reject();
          }
        );
      });
    },
  },
  mutations: {
    set_counters(state, data) {
      state.counters = data.data;
    },
  }
};
