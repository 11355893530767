<template>
  <div class="programs">
    <div class="class">
      <NoticeSubscriptionRenewal v-if="!myCoursesPage && !userSubscriptions"/>

      <div v-if="myCoursesPage === false" class="page-head">
        <div class="page-title">
          <h2>Курсы</h2>
        </div>

        <!-- BEGIN: Блок фильтров -->
        <!-- <FSCourseFilter
          :key="`program-item-key-component-filter`"
        ></FSCourseFilter> -->
        <!-- END: Блок фильтров -->
      </div>

      <div class="page-body">
        <NoticeSubscriptionRenewal v-if="myCoursesPage && !userSubscriptions"/>
        <!-- BEGIN: Блок пустого списка -->
        <!-- страница "Мои курсы" -->
        <div
          v-if="coursesByCategories.length === 0 && myCoursesPage === true"
          class="fs-ptile--programs-my fs-ptile--wrap"
        >
          <div>
            <h5 class="fs-ptile-title mb-8 mt-8 mr-8 ml-8" style="width: 100%">
              Тут будут твои курсы
            </h5>
          </div>
          <div class="fs-ptile-line"></div>
          <div class="mb-8 mt-8 mr-8 ml-8" style="width: 100%">
            Курсы, которые ты купил появляются в этом разделе
          </div>
          <div class="mb-8 ml-8">
            <router-link to="/courses" v-slot="{ href, navigate }">
              <a :href="href" class="btn btn-success mr-2" @click="navigate">
                Выбрать курс
              </a>
            </router-link>
          </div>
        </div>
        <!-- страница "Курсы" -->
        <div
          v-if="coursesByCategories.length === 0 && myCoursesPage === false"
          class="col-xs-12 mt-11"
        >
          На данный момент доступных курсов нет.
        </div>
        <!-- END: Блок пустого списка -->

        <!-- BEGIN: Блок списока курсов -->
        <div
          v-else-if="coursesByCategories.length > 0"
          v-for="(category, indexCategory) in coursesByCategories"
          :key="
            `programs-categories-${
              myCoursesPage === true ? 'my' : ''
            }-${indexCategory}`
          "
          :class="{ 'mt-0': indexCategory === 0 }"
        >
          <!-- страница "Курсы" -->
          <!-- <h3
            :id="`anchor-${category.code}`"
            class="programs-categories__name"
            v-if="
              !coursesIsFiltered &&
                category &&
                myCoursesPage === false &&
                category.code !== 'none'
            "
          >
            {{ category.name }}
          </h3> -->
          <div v-if="myCoursesPage === true">
            <div class="row" v-if="category">
              <div
                v-for="(course, indexCourse) of category.courses"
                :key="
                  `program-item-key-${indexCategory}-${indexCourse}-${
                    course && course.id ? course.id : 'nill'
                  }`
                "
                class="col-xl-6"
                @click="goToCourse(course)"
              >
                <FSCourseItemMy
                  v-if="course && course.id"
                  :indexCourse="indexCourse"
                  :course="course"
                  :key="
                    `program-item-key-component-${indexCategory}-${indexCourse}-${
                      course && course.id ? course.id : 'nill'
                    }`
                  "
                ></FSCourseItemMy>
              </div>
            </div>
          </div>
          <!-- страница "Мои курсы" -->
          <!-- <div v-else> -->
          <div class="catalog" v-else>
            <!-- <div class="catalog" v-if="categories">
              <div
                v-if="coursesIsFiltered === false"
                :id="`frame-${indexCategory}-${category.code}`"
                class="frame"
              >
                <ul class="slidee">
                  <li
                    v-for="(course, indexCourse) of category.courses"
                    :key="
                      `program-item-key-${indexCategory}-${indexCourse}-${course && course.id ? course.id : 'nill'}`
                    "
                    class="courses-list__item"
                    @click="goToCourse(course)"
                  >
                    <FSCourseItem
                      :indexCourse="indexCourse"
                      :indexCategory="indexCategory"
                      :course="course"
                      :key="
                        `program-item-key-component-${indexCategory}-${indexCourse}-${course && course.id ? course.id : 'nill'}`
                      "
                    ></FSCourseItem>
                  </li>
                </ul>
              </div>
              <div v-else> -->
            <ul class="flex flex-wrap catalog--on-filter courses-list">
              <li
                v-for="(course, indexCourse) of category.courses"
                :key="
                  `program-item-key-${indexCategory}-${indexCourse}-${
                    course && course.id ? course.id : 'nill'
                  }`
                "
                class="courses-list__item"
                @click="goToCourse(course)"
              >
                <FSCourseItem
                  :indexCourse="indexCourse"
                  :indexCategory="indexCategory"
                  :course="course"
                  :key="
                    `program-item-key-component-${indexCategory}-${indexCourse}-${
                      course && course.id ? course.id : 'nill'
                    }`
                  "
                ></FSCourseItem>
              </li>
            </ul>
            <!-- </div> -->
          </div>
          <!-- </div>
          <div v-if="category.hasAnchors && category.hasAnchors === true">
            <h3 class="mb-8">По направлениям</h3>
            <span
              v-for="(anchor, indexAnchor) of coursesByCategories"
              :key="`program-item-key-anchors-${indexAnchor}`"
            >
              <span
                v-if="anchor.isAnchorShow === true"
                class="btn btn--filter"
                @click="scrollToAchor(anchor.code)"
              >
                {{ anchor.name }}
              </span>
            </span>
          </div> -->
        </div>
        <!-- END: Блок списока курсов -->
      </div>
    </div>

    <!-- BEGIN: Модальное окно продления подписки -->
    <!-- <div class="remodal" data-remodal-id="buy-training">
      <div class="modalr">
        <div class="modalr-close" data-remodal-action="close"></div>
        <div class="modalr-content">
          <h3>
            Чтобы выбрать курс тренировок необходимо его купить
          </h3>
          <a
            :href="`${$APP_URL}/${computedModalUrl}`"
            class="btn btn-success btn-large"
          >
            Продлить
          </a>
        </div>
      </div>
    </div> -->
    <!-- END: Модальное окно продления подписки -->
  </div>
</template>

<script>
import $ from "jquery";
import "remodal/dist/remodal.min";

// import FSCourseFilter from "@/components/PagePartials/courses/Filter.vue";
import FSCourseItem from "@/components/PagePartials/courses/Item/index.vue";
import FSCourseItemMy from "@/components/PagePartials/courses/Item/My.vue";
import NoticeSubscriptionRenewal from '@/components/content/NoticeSubscriptionRenewal.vue';

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_COURSES_LIST } from "@/core/services/store/courses.module";
import { getYoutubeId, getFormattedDate } from "@/assets/js/helpers";

window.jQuery = $;

export default {
  name: "PageCourses",
  components: {
    // FSCourseFilter,
    FSCourseItem,
    FSCourseItemMy,
    NoticeSubscriptionRenewal
  },
  data() {
    return {
      slyElements: [],
      myCoursesPage: false,
      modalBuyTraining: null,
      modalUrl: null
    };
  },
  computed: {
    computedModalUrl() {
      return this.modalUrl;
    },
    coursesByCategories() {
      return this.$store.getters.coursesByCategories;
    },
    coursesIsFiltered() {
      return this.$store.getters.coursesIsFiltered;
    },
    isLoading() {
      return this.$store.getters.coursesIsLoading;
    },
    siteSettings() {
      return this.$store.getters.siteSettings;
    },
    siteAppURL() {
      return this.$store.getters.siteAppURL;
    },
    userSubscriptions() {
      return this.$store.getters.userSubscriptions;
    },
  },
  methods: {
    getYoutubeId,
    getFormattedDate,
    getCourses() {
      this.$store
        .dispatch(GET_COURSES_LIST, {
          my_courses: this.myCoursesPage,
          isFiltered: true
        })
        .then(() => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "content-loading");

          // this.setSlyForCourses();
          this.emitQuestionnaireModal();
        });
    },
    setSlyForCourses() {
      let __self__ = this;

      if (
        !this.coursesByCategories ||
        this.coursesByCategories === [] ||
        this.myCoursesPage === true
      ) {
        return;
      }

      setTimeout(() => {
        __self__.slyElements = [];

        __self__.coursesByCategories.forEach(function(category, index) {
          let elementSly = $(`#frame-${index}-${category.code}`);
          let options = {
            horizontal: true,
            itemNav: "basic",
            easing: "swing",
            dragHandle: true,
            speed: 300,
            scrollHijack: 500,
            scrollBy: 2,
            scrollTrap: true,
            mouseDragging: 1,
            touchDragging: 1
          };

          if (elementSly.length < 1) {
            return;
          }

          __self__.slyElements[category.code] = elementSly;

          elementSly.sly(options).init();
        });
      }, 200);
    },
    goToCourse(course) {
      let available = false;

      if (
        (course.users_packs && course.users_packs.length > 0) ||
        course.special_course == true
      ) {
        available = true;
      }

      if (available === false) {
        // this._openBuyTraining(course);
        window.location.href = `${this.siteAppURL}courses/${course.url_slug}`;
      } else if (available === true && this.myCoursesPage === false) {
        this.$router.push(`/training/${course.id}`);
      }
    },
    // scrollToAchor(anchorCode) {
    //   let heightElementSubheader = $("#kt_subheader").height();
    //   let heightElementHeader = $("#kt_header").height();
    //   let heightElementAnchorH3 = $(`#anchor-${anchorCode}`).height();

    //   $([document.documentElement, document.body]).animate(
    //     {
    //       scrollTop:
    //         $(`#anchor-${anchorCode}`).offset().top -
    //         heightElementSubheader -
    //         heightElementHeader -
    //         heightElementAnchorH3 -
    //         15
    //     },
    //     2000
    //   );
    // },
    emitQuestionnaireModal() {
      this.$root.$emit("pageLoaded", "loaded");
    },
    // _openBuyTraining(course) {
    //   this.modalUrl = `/courses/${course.url_slug}`;

    //   this.$nextTick(() => {
    //     this.modalBuyTraining.open();
    //   });
    // },
    _setMyCoursesPage() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "content-loading");
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

      if (this.$route.name === "courses-my") {
        this.myCoursesPage = true;

        this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Курсы", route: "/courses" },
          { title: "Мои" }
        ]);
      } else {
        this.myCoursesPage = false;
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Курсы" }]);
      }

      // this.modalBuyTraining = $("[data-remodal-id='buy-training']").remodal({
      //   hashTracking: false
      // });

      this.getCourses();
    }
  },
  mounted() {
    let __self__ = this;

    this._setMyCoursesPage();

    this.$root.$on("reloadCoursesPage", function() {
      __self__._setMyCoursesPage();
    });
  },
  watch: {
    $route() {
      this._setMyCoursesPage();
    },
    // eslint-disable-next-line no-unused-vars
    coursesIsFiltered(to, from) {
      // if (to === false) {
      //   this.setSlyForCourses();
      // } else if (to === true && this.slyElements.length > 0) {
      //   //  this.slyElements.forEach(sly => {
      //   //    sly.destroy();
      //   //  });
      //
      //   this.slyElements = [];
      // }
    }
  }
};
</script>

<style lang="scss">
// .card-footer {
//   .frame {
//     overflow: hidden;

//     ul {
//       list-style: none;
//       margin: 0;
//       padding: 0;
//       height: 100%;
//       zoom: 1;

//       li {
//         float: left;
//         height: 100%;
//         margin: 0 10px;
//         width: 114px;
//         min-width: 114px;
//       }
//     }

//     .slidee {
//       margin: 0 -10px;
//       padding: 0;
//       padding-top: 14px;
//       height: 100%;
//       list-style: none;
//     }
//   }

//   .scrollbar {
//     margin: 0 0 1em 0;
//     height: 2px;
//     background: #ccc;
//     line-height: 0;

//     .handle {
//       width: 51px;
//       height: 100%;
//       background: #292a33;
//       cursor: pointer;

//       .mousearea {
//         position: absolute;
//         top: -5px;
//         left: 0;
//         width: 51px;
//         height: 12px;
//         background: #c4c4c4;
//       }
//     }
//   }
// }
</style>
