<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: Search -->
    <!-- <b-dropdown
      size="sm"
      id="kt_quick_search_toggle"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="media/svg/icons/General/Search.svg" />
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <KTSearchDefault></KTSearchDefault>
      </b-dropdown-text>
    </b-dropdown> -->
    <!--end: Search -->

    <!--begin: Notifications -->
    <!-- <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div
          class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary"
        >
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="media/svg/icons/Code/Compiling.svg" />
          </span>
          <span class="pulse-ring"></span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <form>
          <KTDropdownNotification></KTDropdownNotification>
        </form>
      </b-dropdown-text>
    </b-dropdown> -->
    <!--end: Notifications -->

    <!--begin: Quick Actions -->
    <!-- <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="media/svg/icons/Media/Equalizer.svg" />
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <KTDropdownQuickAction></KTDropdownQuickAction>
      </b-dropdown-text>
    </b-dropdown> -->
    <!--end: Quick Actions -->

    <!--begin: My Cart -->
    <!-- <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="media/svg/icons/Shopping/Cart3.svg" />
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <KTDropdownMyCart></KTDropdownMyCart>
      </b-dropdown-text>
    </b-dropdown> -->
    <!--end: My Cart -->

    <!--begin: Quick panel toggle -->
    <!-- <KTQuickPanel></KTQuickPanel> -->
    <!--end: Quick panel toggle -->

    <!--begin: Language bar -->
    <!-- <div class="topbar-item">
      <b-dropdown
        size="sm"
        variant="link"
        toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
        no-caret
        right
        no-flip
      >
        <template v-slot:button-content>
          <img
            class="h-20px w-20px rounded-sm"
            :src="languageFlag || getLanguageFlag"
            alt=""
          />
        </template>
        <b-dropdown-text tag="div" class="min-w-md-175px">
          <KTDropdownLanguage
            v-on:language-changed="onLanguageChanged"
          ></KTDropdownLanguage>
        </b-dropdown-text>
      </b-dropdown>
    </div> -->
    <!--end: Language bar -->

    <!--begin: Calendar -->
    <div v-if="trainingsCalendar" class="topbar-item training-date calendarr">
      <div
        class="training-date-label btn btn-icon btn-clean btn-dropdown btn-lg mr-1"
        id="dropdownCalendar"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span class="svg-icon svg-icon-primary">
          <i class="flaticon2-calendar"></i>
        </span>
      </div>
      <div
        class="row row-eq-height calendar-box dropdown-menu"
        aria-labelledby="dropdownCalendar"
      >
        <div class="col-lg-12 col-md-12">
          <div class="portlet calendar">
            <div class="portlet-title">
              <div class="clearfix">
                <div class="caption">
                  <span class="caption-subject bold uppercase calendar__header">
                    Календарь тренировок
                  </span>
                </div>
              </div>
              <div class="caption calendar__trainings-count">
                Всего выполнено:
                {{ " " }}
                <span class="calendar__trainings-count__total">
                  {{ trainingCompletedCount }}
                </span>
              </div>
              <div class="actions actions-mobile-left pos-r calendar__month">
                <div
                  class="calendar__mounth col-xs-12 flex flex-ai-c align-items-center"
                  style="white-space: nowrap"
                >
                  <a @click="prevMonth">
                    <i class="fa fa-chevron-left" aria-hidden="true"></i>
                  </a>
                  <h4 class="calendar__mounth__title ml-xl-3 mr-xl-3">
                    {{ trainingsCalendar.month_title }}
                  </h4>
                  <a @click="nextMonth">
                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              :class="{ loader: isTrainingLoading.trainings }"
              class="portlet-body"
            >
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <full-calendar
                    :events="calendar.eventsList"
                    :config="calendar.config"
                    @event-render="calendarEventRender"
                    ref="calendarElement"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--begin: Calendar -->

    <!-- begin new-profile -->
    <div class="topbar-item">
      <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
        <span class="svg-icon svg-icon-xl svg-icon-primary">
          <router-link to="/new-profile" class="menu-link">
            <i class="flaticon2-settings"></i>
          </router-link>
        </span>
      </div>
    </div>
    <!-- end new-profile -->

    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import $ from "jquery";
import "bootstrap/js/src/tooltip";
import { mapGetters } from "vuex";
import { getFormattedDate } from "@/assets/js/helpers";
// import KTSearchDefault from "@/view/layout/extras/dropdown/Search/Default.vue";
// import KTDropdownNotification from "@/view/layout/extras/dropdown/Notification.vue";
// import KTDropdownQuickAction from "@/view/layout/extras/dropdown/QuickAction.vue";
// import KTDropdownMyCart from "@/view/layout/extras/dropdown/MyCart.vue";
// import KTDropdownLanguage from "@/view/layout/extras/dropdown/Language.vue";
import KTQuickUser from "@/components/layout/extras/offcanvas/Quick/User.vue";
// import KTQuickPanel from "@/view/layout/extras/offcanvas/Quick/Panel.vue";
import i18nService from "@/core/services/i18n.service.js";
import { FullCalendar } from "vue-full-calendar";

export default {
  name: "KTTopbar",
  components: {
    // KTSearchDefault,
    // KTDropdownNotification,
    // KTDropdownQuickAction,
    // KTDropdownMyCart,
    // KTDropdownLanguage,
    KTQuickUser,
    // KTQuickPanel
    FullCalendar
  },
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages,
      calendar: {
        openRest: false,
        eventsList: [],
        config: {
          defaultView: "month",
          locale: "ru",
          height: "auto"
        },
        eventElement: "test"
      }
    };
  },
  computed: {
    ...mapGetters([
      "trainingsCalendar",
      "userSubscriptions",
      "trainingCompletedCount",
      "isTrainingLoading"
    ]),
    getLanguageFlag() {
      return this.onLanguageChanged();
    }
  },
  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    prevMonth() {
      let { link_previous: url } = this.trainingsCalendar;
      url = url.substring(1);

      this.$store
        .dispatch("getPrevMonth", { url })
        .then(() => {
          this.calendar.eventsList = [];
          this.calendar.openRest = false;

          this.trainingsCalendar.days.forEach(day => {
            const event = {};
            event.start = day.date;
            event.className = day.status;
            this.calendar.eventsList.push(event);
          });

          this.$refs.calendarElement.fireMethod("prev");
          this.$refs.calendarElement.fireMethod("refetchEvents");
        })
        .catch(() => {
          this.error = "Ошибка";
        });
    },
    nextMonth() {
      let { link_next: url } = this.trainingsCalendar;
      url = url.substring(1);

      this.$store
        .dispatch("getNextMonth", { url })
        .then(() => {
          this.calendar.eventsList = [];
          this.calendar.openRest = false;

          this.trainingsCalendar.days.forEach(day => {
            const event = {};
            event.start = day.date;
            event.className = day.status;
            this.calendar.eventsList.push(event);
          });

          this.$refs.calendarElement.fireMethod("next");
          this.$refs.calendarElement.fireMethod("refetchEvents");
        })
        .catch(() => {
          this.error = "Ошибка";
        });
    },
    updateDayStatus(day) {
      let trainingsString = "";

      if (day.status === "training_done") {
        $(`.fc-day[data-date="${day.date}"]`).addClass(day.status);
        $(`.fc-day-top[data-date="${day.date}"]`).addClass(day.status);
        $(`.fc-day-top[data-date="${day.date}"].training_done`).attr(
          "data-toggle",
          "tooltip"
        );
        $(`.fc-day-top[data-date="${day.date}"].training_done`).attr(
          "data-container",
          "body"
        );
        $(`.fc-day-top[data-date="${day.date}"].training_done`).attr(
          "data-placement",
          "bottom"
        );
      }

      if (day.status === "training_open") {
        $(`.fc-day-top[data-date="${day.date}"].training_done`).removeAttr(
          "data-toggle"
        );
        $(`.fc-day-top[data-date="${day.date}"].training_done`).removeAttr(
          "data-container",
        );
        $(`.fc-day-top[data-date="${day.date}"].training_done`).removeAttr(
          "data-placement",
        );
        $(`.fc-day-top[data-date="${day.date}"].training_done`).removeAttr(
          "title",
        );
        $(`.fc-day[data-date="${day.date}"]`).removeClass("training_done");
        $(`.fc-day-top[data-date="${day.date}"]`).removeClass("training_done");
      }

      if (day.trainings !== null) {
        for (let i = 0; i < 2; i++) {
          if (!day.trainings[i]) {
            break;
          }

          trainingsString += `${day.trainings[i].name}\n`;
        }

        if (day.trainings.length > 2) {
          trainingsString += `и еще ${day.trainings.length - 2}`;
        }

        $(`.fc-day-top[data-date=${day.date}].training_done`).attr(
          "title",
          trainingsString
        );
      }
    },
    calendarEventRender() {
      for (const day of this.trainingsCalendar.days) {
        this.updateDayStatus(day);
      }
    },
    getDaysLabel(days, labels) {
      const cases = [2, 0, 1, 1, 1, 2];
      return labels[
        days % 100 > 4 && days % 100 < 20
          ? 2
          : cases[days % 10 < 5 ? days % 10 : 5]
      ];
    },
    getFormattedDate
  },
  mounted() {
    $(document).on("click", ".calendar-box", e => {
      if ($(e.target).closest(".calendar-box")) {
        e.stopPropagation();
      }
    });
  },
  watch: {
    trainingsCalendar(newCalendar) {
      this.calendar.openRest = false;

      if (newCalendar) {
        newCalendar.days.forEach(day => {
          this.calendar.eventsList.push({
            start: day.date,
            className: day.status
          });
        });
      }
    }
  }
};
</script>
