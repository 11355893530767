import videojs from "video.js";
import router from "@/router/";

const ClickableComponent = videojs.getComponent('ClickableComponent');

class CustomPlayListNext extends ClickableComponent {

  constructor(player, options = {}) {
    super(player, options);

    this.hide();
    if (options.training && options.trainings) {
      let id = options.training.id;
      let length = options.trainings.length;
      let last_id = options.trainings[length-1];
      if (last_id !== id) {
        this.on(player, 'useractive', function() {
          this.show();
        });

        this.on(player, 'userinactive', function() {
          if (!player.paused()) {
            this.hide();
          }
        });

        this.on(player, 'pause', function() {
          setTimeout(() => {
            let current = player.currentTime();
            if (current !== 1 && current !== 0) {
              this.show();
            }
          }, 200)
        });
      }
    }
  }

  createEl() {
    return videojs.dom.createEl('div', {
      className: 'vjs-custom__playlist vjs-custom__playlist--next'
    });
  }

  handleClick() {
    router.push({
      name: "training-pack-pack-training",
      params: {
        pack: this.options_.program.id,
        training: this.options_.next
      }
    });
  }

}

export default CustomPlayListNext;