<template>
  <div class="fs-ptile--programs-my fs-ptile--wrap">
    <div>
      <h5 class="fs-ptile-title d-flex mb-8 mt-8 mr-8 ml-8" style="max-width: 100%">
        <img src="@/assets/img/icon-attention-grey.svg" class="mr-4" />
        Срок действия твоей подписки истек
      </h5>
    </div>
    <div class="fs-ptile-line mb-8"></div>
    <div class="mb-8 ml-8">
      <a :href="`${$APP_URL}${siteSettings.checkout_redirect_from_cabinet}`" class="btn btn-success mr-2">
        Продлить
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoticeSubscriptionRenewal',

  computed: {
    siteSettings() {
      return this.$store.getters.siteSettings;
    },
  },
}
</script>