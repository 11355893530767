<template>
  <div class="fs-ptile profile__frends" style="flex-flow: wrap">
    <h5 class="fs-ptile-title">
      {{ componentTitle }} ({{ socialFollowingListCount }})
    </h5>
    <div class="profile__frends--title fs-ptile-input__wrap">
      <input class="fs-ptile-input" v-model="search" />
      <span class="fs-ptile-input-btn" @click="searchFollowingList">
        поиск
      </span>
    </div>
    <div
      v-if="socialFollowingListCount == 0"
      style="width: 100%; margin-top: 15px"
    >
      <div v-if="socialIsMyProfile === true">
        <p style="padding-bottom: 30px; font-size: 20px">
          У тебя пока нет друзей ☹️
        </p>
        <p style="padding-bottom: 15px">Скопируй ссылку и отправь другу:</p>
        <button style="margin-right: 15px" @click="copyInvitationLink()">
          <img src="@/assets/img/profile/copy-btn.svg" alt="" />
        </button>
      </div>
      <div v-else>
        У этого пользователя<br />
        пока нет друзей ☹️
      </div>
    </div>
    <div v-else style="width: 100%">
      <div
        ref="social-friends-list"
        v-for="(following, index) in socialFollowingList"
        :key="`social-following-${index}`"
        class="js-friends-list fs-ptile-notifications__conteiner"
        :class="{
          'status-on': following.is_receive == 1,
          'status-off': following.is_receive == 0
        }"
      >
        <div class="status-wrap">
          <span
            v-if="socialIsMyProfile === true"
            class="fs-ptile-notifications__push"
            @click="switchShowTapeForUser(following)"
          ></span>
          <span
            v-if="socialIsMyProfile === true"
            class="fs-ptile-unfollow-user"
            @click="unfollow(following)"
          ></span>
        </div>
        <router-link
          :to="`/fitness-profile/${following.url_slug}`"
          class="fs-ptile-notifications__img"
        >
          <img
            v-if="following.avatar"
            class="fs-ptile-img"
            :src="`${following.avatar}`"
            alt=""
          />
          <span v-else class="symbol symbol-35 symbol-light-success">
            <span class="symbol-label font-size-h5 font-weight-bold">
              {{ following.full_name.charAt(0).toUpperCase() }}
            </span>
          </span>
        </router-link>
        <div class="fs-ptile-notifications__user status-user" style="">
          <router-link
            class=""
            style="
              font-size: 16px;
              font-weight: 600;
              text-decoration: underline;
              color: #4b4b4b;
            "
            :to="`/fitness-profile/${following.url_slug}`"
          >
            {{ following.full_name }}
          </router-link>
        </div>
      </div>
      <div v-if="socialFollowingListBtn === true" style="padding-top: 20px">
        <button
          class="btn-comment"
          @click="nextPage"
          style="
            margin: 0 auto;
            display: block;
            background: #fff;
            color: #ef16b0;
            box-shadow: 0px 4px 6px rgb(0 0 0 / 25%);
          "
        >
          Подгрузить еще
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Swal from "sweetalert2";

import settings from "@/settings";
import defaultPhoto from "@/assets/img/noimage.png";
import { copyToClipboard } from "@/assets/js/helpers";

export default {
  name: "FSProfileNotifications",
  props: {
    url_slug: String
  },
  data() {
    return {
      offset: 0,
      limit: 10,
      componentTitle: "",
      search: "",
      nextPageBtn: false,
      defaultSettings: {
        APP_URL: settings.APP_URL,
        defaultPhoto: defaultPhoto
      }
    };
  },
  computed: {
    socialIsMyProfile() {
      return this.$store.getters.socialIsMyProfile;
    },
    socialFollowingListCount() {
      return this.$store.getters.socialFollowingListCount;
    },
    socialFollowingListBtn() {
      return this.$store.getters.socialFollowingListBtn;
    },
    socialFollowingList() {
      return this.$store.getters.socialFollowingList;
    }
  },
  mounted() {
    this.setPageData();
    this.getFollowingListByUrlSlugAsync().then(() => {
      let element = $(document).find("#jsid-profile-share-compotent");

      if (element && element.length > 0) {
        this.$root.$emit(
          "socialFriendsListLoaded",
          $(element[element.length - 1])
        );
      }
    });
  },
  methods: {
    copyToClipboard: copyToClipboard,
    copyInvitationLink() {
      const link = `${this.defaultSettings.APP_URL}invitation/${this.url_slug}`;

      this.copyToClipboard(link);

      Swal.fire({
        title: "Ссылка скопирована!",
        icon: "success",
        confirmButtonColor: "#ef16b0"
      });
    },
    nextPage() {
      this.getFollowingListByUrlSlugAsync();
    },
    getFollowingListByUrlSlugAsync(offset = null, limit = null) {
      if (offset !== null) {
        this.offset = offset;
      }

      if (limit !== null) {
        this.limit = limit;
      }

      let data = {
        url_slug: this.url_slug,
        offset: this.offset,
        limit: this.limit,
        search: this.search
      };

      return this.$store.dispatch("getFollowingListByUrlSlug", data);
    },
    setPageData() {
      switch (this.socialIsMyProfile) {
        case true:
          this.componentTitle = "Мои друзья";
          break;
        case false:
          this.componentTitle = "Друзья";
          break;
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    changeProfile(user) {
      this.$router.push({
        name: "user",
        params: {
          url_slug: user.url_slug
        }
      });
    },
    unfollow(user) {
      let __self__ = this;

      Swal.fire({
        title: "Вы уверены?",
        text: `Что хотите отписаться от ${user.full_name}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Отписаться",
        cancelButtonText: "Отменить",
        reverseButtons: true
      }).then(function(result) {
        if (result.value) {
          let data = {
            id: user.following_id
          };

          __self__.$store.dispatch("unfollow", data).then(() => {
            __self__.$store.dispatch(
              "getSocialProfileByUrlSlug",
              __self__.url_slug
            );

            __self__.getFollowingListByUrlSlugAsync(
              0,
              __self__.socialFollowingList.length
            );

            __self__.$root.$emit("reloadSocialTapeList");
          });
        }
      });
    },
    searchFollowingList() {
      this.getFollowingListByUrlSlugAsync(0, this.limit);
    },
    switchShowTapeForUser(follow) {
      this.$store.dispatch("switchShowTapeForUser", follow).then(() => {
        this.$root.$emit("reloadSocialTapeList");
      });
    }
  },
  watch: {
    socialFollowingList() {
      this.offset = this.socialFollowingList.length;
    },
    socialIsMyProfile() {
      this.setPageData();
    },
    url_slug() {
      this.search = "";
      this.offset = 0;

      this.getFollowingListByUrlSlugAsync();
    }
  }
};
</script>
