import $ from "jquery";
import profileService from "@/core/services/profile.service";

export default {
  state: {
    socialProfile: null,
    socialProfileFollowers: [],
    socialIsMyProfile: null,
    socialIsBlocked: null,
    socialIsMyFollowing: null,
    socialGraphData: {},
    socialStatistics: {
      done_trainings: 0,
      done_hours: 0,
      days_in_website: 0,
      total_calories: 0
    },
    socialFollowingList: [],
    socialFollowingListCount: 0,
    socialFollowingListBtn: false,
    socialFollowingListFull: [],
    socialTapeList: [],
    socialNotificationsList: []
  },
  getters: {
    socialProfile: state => state.socialProfile,
    socialProfileFollowers: state => {
      const length = state.socialProfileFollowers.length;
      let data = {
        list: [],
        length: length,
        counter: 0
      };

      if (length > 5) {
        data.list = state.socialProfileFollowers.slice(0, 5);
        data.counter = length - 5;
      } else if (length <= 5 && length > 0) {
        data.list = state.socialProfileFollowers;
        data.counter = 0;
      }

      return data;
    },
    socialIsMyProfile: state => state.socialIsMyProfile,
    socialIsBlocked: state => state.socialIsBlocked,
    socialIsMyFollowing: state => state.socialIsMyFollowing,
    socialGraphData: state => state.socialGraphData,
    socialStatistics: state => state.socialStatistics,
    socialFollowingList: state => state.socialFollowingList,
    socialFollowingListCount: state => state.socialFollowingListCount,
    socialFollowingListBtn: state => state.socialFollowingListBtn,
    socialTapeList: state => state.socialTapeList,
    socialNotificationsList: state => state.socialNotificationsList
  },
  actions: {
    getSocialNotificationListByUrlSlug({ commit }, urlSlug) {
      return new Promise((resolve, reject) => {
        profileService.getNotificationsListByUrlSlug(
          urlSlug,
          data => {
            commit("set_social_notifications_list", data);
            resolve(data);
          },
          () => {
            reject();
          }
        );
      });
    },
    getSocialTapeListByUrlSlug({ commit }, requestData) {
      return new Promise((resolve, reject) => {
        profileService.getTapeListByUrlSlug(
          requestData,
          data => {
            let response = data;
            response.filter = requestData;

            commit("set_social_tape_list", response);
            resolve(data);
          },
          () => {
            reject();
          }
        );
      });
    },
    getFollowingListByUrlSlug({ commit }, requestData) {
      return new Promise((resolve, reject) => {
        profileService.getFollowingListByUrlSlug(
          requestData,
          data => {
            let response = data;
            response.filter = requestData;

            commit("set_social_following_list", response);
            resolve(data);
          },
          () => {
            reject();
          }
        );
      });
    },
    getSocialProfileByUrlSlug({ commit }, urlSlug) {
      return new Promise((resolve, reject) => {
        profileService.getUserByUrlSlug(
          urlSlug,
          data => {
            commit("set_social_profile", data);
            commit("set_social_profile_followers", data);
            resolve(data);
          },
          () => {
            reject();
          }
        );
      });
    },
    getSocialGraphDataByUrlSlug({ commit }, urlSlug) {
      return new Promise((resolve, reject) => {
        profileService.getGraphDataByUrlSlug(
          urlSlug,
          data => {
            commit("set_social_graph_data", data);
            resolve(data);
          },
          () => {
            reject();
          }
        );
      });
    },
    getSocialStatisticsByUrlSlug({ commit }, urlSlug) {
      return new Promise((resolve, reject) => {
        profileService.getStatisticsByUrlSlug(
          urlSlug,
          data => {
            commit("set_social_statistics", data);
            resolve(data);
          },
          () => {
            reject();
          }
        );
      });
    },
    // eslint-disable-next-line no-unused-vars
    searchInFollowingList({ commit }, search) {
      commit("search_in_following_list", search);
    },
    // eslint-disable-next-line no-unused-vars
    switchShowTapeForUser({ commit }, follow) {
      return new Promise((resolve, reject) => {
        profileService.switchShowTapeForUser(
          follow,
          data => {
            commit("switch_show_tape_for_user", follow);
            resolve(data);
          },
          () => {
            reject();
          }
        );
      });
    },
    // eslint-disable-next-line no-unused-vars
    follow({ commit }, user) {
      return new Promise((resolve, reject) => {
        profileService.follow(
          user,
          data => {
            resolve(data);
          },
          () => {
            reject();
          }
        );
      });
    },
    // eslint-disable-next-line no-unused-vars
    unfollow({ commit }, user) {
      return new Promise((resolve, reject) => {
        profileService.unfollow(
          user,
          data => {
            resolve(data);
          },
          () => {
            reject();
          }
        );
      });
    }
  },
  mutations: {
    set_social_profile(state, data) {
      if (data && data.data) {
        state.socialProfile = data.data;
        state.socialIsMyProfile =
          data.data.is_my_account == true ? true : false;
        state.socialIsMyFollowing =
          data.data.is_my_following == true ? true : false;
        state.socialIsBlocked = data.data.is_blocked == true ? true : false;
      } else {
        state.socialProfile = {};
        state.socialIsMyProfile = false;
        state.socialIsBlocked = false;
        state.socialIsMyFollowing = false;
      }
    },
    set_social_profile_followers(state, data) {
      if (data && data.data && data.data.followers) {
        state.socialProfileFollowers = data.data.followers;
      } else {
        state.socialProfileFollowers = [];
      }
    },
    set_social_graph_data(state, data) {
      if (data && data.data) {
        state.socialGraphData = data.data;
      } else {
        state.socialGraphData = {};
      }
    },
    set_social_statistics(state, data) {
      if (data && data.data) {
        state.socialStatistics = data.data;
      } else {
        state.socialStatistics = {};
      }
    },
    set_social_following_list(state, data) {
      if (!data.data && data.filter.offset === 0) {
        state.socialFollowingListCount = 0;
        state.socialFollowingList = [];
      } else if (data.data && data.filter.offset === 0) {
        state.socialFollowingListCount = data.data.count;
        state.socialFollowingList = data.data.list;
      } else if (data.data && data.filter.offset > 0) {
        state.socialFollowingListCount = data.data.count;
        // eslint-disable-next-line no-unused-vars
        $.each(data.data.list, (key, value) => {
          if (!value) {
            return;
          }
          state.socialFollowingList.push(value);
        });
      }

      if (
        (data.filter.limit &&
          data.data &&
          data.filter.limit > data.data.list.length) ||
        (data.filter.offset && data.filter.offset >= 0 && !data.data) ||
        state.socialFollowingList.length === data.data.count
      ) {
        state.socialFollowingListBtn = false;
      } else {
        state.socialFollowingListBtn = true;
      }
    },
    set_social_tape_list(state, data) {
      if (!data.data && data.filter.offset === 0) {
        state.socialTapeList = [];
      } else if (data.data && data.filter.offset === 0) {
        state.socialTapeList = data.data;
      } else if (data.data && data.filter.offset > 0) {
        // eslint-disable-next-line no-unused-vars
        $.each(data.data, (key, value) => {
          if (!value) {
            return;
          }
          state.socialTapeList.push(value);
        });
      }
    },
    switch_show_tape_for_user(state, followUser) {
      $.each(state.socialFollowingList, (index, following) => {
        if (following.id == followUser.id) {
          state.socialFollowingList[index].is_receive = !state
            .socialFollowingList[index].is_receive;
        }
      });
    },
    search_in_following_list(state, search) {
      if (null == search || "" == search) {
        state.socialFollowingList = state.socialFollowingListFull;
      } else {
        state.socialFollowingList = state.socialFollowingListFull.filter(
          following => {
            const fullName = following.full_name.toLowerCase();
            return fullName.indexOf(search.toLowerCase()) >= 0;
          }
        );
      }
    },
    set_social_notifications_list(state, data) {
      if (data && data.data) {
        state.socialNotificationsList = data.data;
      } else {
        state.socialNotificationsList = [];
      }
    }
  }
};
