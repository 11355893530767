<template>
  <div
    v-if="socialProfile"
    class="fs-ptile profile__header"
    style="flex-flow: wrap;overflow: visible;"
  >
    <div class="fs-ptile-profile-info">
      <div class="fs-ptile-profile-info__img">
        <img
          v-if="socialProfile.avatar"
          class="fs-ptile-img"
          :src="`${socialProfile.avatar}`"
          alt=""
          style="width: 100%;"
        />
        <span
          v-else
          class="symbol symbol-light-success"
          style="width: 100%;height: 100%;"
        >
          <span
            v-if="socialProfile.full_name"
            class="symbol-label font-size-h5 font-weight-bold"
            style="width: 100%;height: 100%;"
          >
            {{ socialProfile.full_name.charAt(0).toUpperCase() }}
          </span>
        </span>
      </div>
      <div class="fs-ptile-profile-info__conteiner">
        <div>
          <h4
            v-if="socialProfile.full_name"
            class="fs-ptile-profile-info__name"
          >
            {{ socialProfile.full_name }}
          </h4>
          <div v-if="socialIsMyProfile === true">
            <div
              v-if="socialProfile.email"
              class="fs-ptile-profile-info__email"
            >
              {{ socialProfile.email }}
            </div>
          </div>
          <a
            v-if="socialProfile.instagram"
            class="fs-ptile-profile-info__instagram"
            :href="`http://instagram.com/${socialProfile.instagram}`"
            target="_blank"
          >
            <img
              class="fs-ptile-img"
              src="@/assets/img/profile/instagram.svg"
              alt="instagram"
            />
            {{ socialProfile.instagram }}
          </a>
        </div>
        <span
          v-if="socialIsMyProfile == false && socialIsMyFollowing == true"
          class="settings-btn"
          @click="unfollow(socialProfile)"
          style="background: #EF16B0;color: #ffffff"
        >
          Отписаться
        </span>
        <span
          v-if="socialIsMyProfile == false && socialIsMyFollowing == false"
          class="settings-btn"
          @click="follow(socialProfile)"
          style="background: #35D205;color: #ffffff"
        >
          Подписаться
        </span>
      </div>
    </div>
    <div class="fs-ptile-profile-achivs">
      <VueSlickCarousel
        v-bind="settingsSlick"
        class="fs-ptile-profile-achivs__achivs"
      >
        <template #nextArrow="arrowOption">
          <div class="profile-right"></div>
        </template>
        <div
          class="achive"
          v-for="(badge, index) in socialProfile.badges"
          :key="`social-profile-badge-${index}`"
        >
          <img
            class="fs-ptile-img"
            :src="getBadgeImage(badge)"
            style="max-width: 60px"
            :id="`social-profile-badge-id-${index}`"
          />
          <b-tooltip
            :custom-class="
              `${
                badge.is_achievement == true
                  ? 'badge-is-achieved'
                  : 'badge-not-achieved'
              }`
            "
            :target="`social-profile-badge-id-${index}`"
            variant="light"
            placement="bottom"
          >
            {{ badge.description }}
          </b-tooltip>
        </div>
        <template #prevArrow="arrowOption">
          <div class="profile-left"></div>
        </template>
      </VueSlickCarousel>
      <div class="fs-ptile-profile-achivs__friends">
        <span class="mr-4">
          <i class="flaticon-network display-4 text-muted font-weight-bold"></i>
        </span>
        <div
          class="symbol-group symbol-hover"
          v-if="socialProfileFollowers.length > 0"
        >
          <router-link
            :to="`/fitness-profile/${follower.url_slug}`"
            class="symbol symbol-30 symbol-circle"
            v-for="(follower, index) in socialProfileFollowers.list"
            :key="`social-profile-follower-${index}`"
          >
            <img
              v-if="follower.avatar"
              :src="`${follower.avatar}`"
              :title="follower.full_name"
              alt=""
            />
            <span
              v-else
              class="symbol symbol-35 symbol-light-success"
              :title="follower.full_name"
            >
              <span class="symbol-label font-size-h5 font-weight-bold">
                {{ follower.full_name.charAt(0).toUpperCase() }}
              </span>
            </span>
          </router-link>
          <div
            class="symbol symbol-35 symbol-circle symbol-light"
            v-if="
              socialProfileFollowers.counter &&
                socialProfileFollowers.counter > 0
            "
          >
            <span
              @click="switchFollowers"
              class="symbol-label symbol-35 font-weight-bold"
            >
              +{{ socialProfileFollowers.counter }}
            </span>
          </div>
          <div
            class="profile-popup"
            :class="{ show: filterIsOpenFollowers }"
            v-if="socialProfile.followers"
          >
            <div class="profile-popup__title" style="position: relative;">
              <p
                v-if="socialIsMyProfile === true"
                class="yf-filters-from__mobile-title--txt"
              >
                Мои подписки ({{ socialProfileFollowers.counter + 5 }})
              </p>
              <p v-else style="padding: 10px"></p>
              <span class="close-filters" @click="switchFollowers"></span>
            </div>
            <div class="profile-popup__wr">
              <div
                class="profile-popup__follower"
                v-for="(follower, index) in socialProfile.followers"
                :key="`social-profile-follower-${index}`"
              >
                <router-link
                  :to="`/fitness-profile/${follower.url_slug}`"
                  class="fs-ptile-notifications__img"
                >
                  <img
                    v-if="follower.avatar"
                    class="fs-ptile-img"
                    :src="`${follower.avatar}`"
                    alt=""
                  />
                  <span v-else class="symbol symbol-35 symbol-light-success">
                    <span class="symbol-label font-size-h5 font-weight-bold">
                      {{ follower.full_name.charAt(0).toUpperCase() }}
                    </span>
                  </span>
                </router-link>
                <router-link
                  :to="`/fitness-profile/${follower.url_slug}`"
                  style="text-decoration: underline; color: #4B4B4B;"
                >
                  <p class="profile-popup__follower--name">
                    {{ follower.full_name }}
                  </p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div v-else style="text-align: center;">
          <div v-if="socialIsMyProfile === true">
            На тебя пока<br />
            никто не подписан ☹️
          </div>
          <div v-else>
            У этого пользователя<br />
            пока нет подписчиков ☹️
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import settings from "@/settings";
import defaultPhoto from "@/assets/img/noimage.png";
import VueSlickCarousel from "vue-slick-carousel";

export default {
  name: "FSProfilehd",
  components: { VueSlickCarousel },
  props: {
    url_slug: String
  },
  data() {
    return {
      settingsSlick: {
        dots: false,
        arrows: true,
        focusOnSelect: false,
        infinite: false,
        slidesToShow: 8,
        slidesToScroll: 1,
        nextArrow: '<div class="profile-right"></div>',
        prevArrow: '<div class="profile-left"></div>',
        responsive: [
          {
            breakpoint: 1200,
            settings: "unslick"
          }
        ]
      },
      filterIsOpenFollowers: false,
      defaultSettings: {
        APP_URL: settings.APP_URL,
        defaultPhoto: defaultPhoto
      }
    };
  },
  computed: {
    socialIsMyProfile() {
      return this.$store.getters.socialIsMyProfile;
    },
    socialIsMyFollowing() {
      return this.$store.getters.socialIsMyFollowing;
    },
    socialProfile() {
      return this.$store.getters.socialProfile;
    },
    socialProfileFollowers() {
      return this.$store.getters.socialProfileFollowers;
    }
  },
  mounted() {
    this.$store.dispatch("getSocialProfileByUrlSlug", this.url_slug);
  },
  methods: {
    getBadgeImage(badge) {
      let url = `${require(`@/assets/img/profile/achive/badge_${badge.id}_${
        badge.is_achievement == true ? "1" : "0"
      }.svg`)}`;

      return url;
    },
    follow(user) {
      this.$store.dispatch("follow", user).then(() => {
        this.$store.dispatch("getSocialProfileByUrlSlug", this.url_slug);
        this.$store.dispatch("getFollowingListByUrlSlug", this.url_slug);
      });
    },
    unfollow(user) {
      this.$store.dispatch("unfollow", user).then(() => {
        this.$store.dispatch("getSocialProfileByUrlSlug", this.url_slug);
        this.$store.dispatch("getFollowingListByUrlSlug", this.url_slug);
      });
    },
    switchFollowers() {
      this.filterIsOpenFollowers = !this.filterIsOpenFollowers;
    }
  },
  watch: {
    url_slug() {
      this.filterIsOpenFollowers = false;

      this.$store.dispatch("getSocialProfileByUrlSlug", this.url_slug);
    }
  }
};
</script>
