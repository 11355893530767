const ls = {
  getItem(name) {
    window.localStorage.getItem(name);
  },
  setItem(name, value) {
    window.localStorage.setItem(name, value);
  },
  removeItem(name) {
    window.localStorage.removeItem(name);
  }
};

export default ls;
