<template>
  <div class="fs-ptile--half">
    <div class="fs-ptile--wrap profile__grafic">
      <h5 class="fs-ptile-title">{{ componentTitle }}</h5>
      <div v-if="socialIsMyProfile === true" class="fs-ptile-links">
        <a href="/progress" class="link"> Добавить замер </a>
        <div class="columne-divider--18"></div>
        <a @click="isVideo = true" class="link" data-toggle="modal" href="#example">
          Как делать замеры?
        </a>
      </div>
      <div class="empty-graph" v-if="Object.keys(graphData).length < 1">
        Графики будут доступны по мере добавления замеров
      </div>
      <div v-else class="profile__grafic--wrap" style="100%">
        <div class="profile__grafic--select-wrap">
          <select v-model="graphView">
            <option
              v-for="graph of graphsTypes"
              v-show="graph.show"
              :value="graph"
              :key="`graph-type-option-${graph.type}`"
            >
              {{ graph.label }}
            </option>
          </select>

          <div class="btn-wrap">
            <span
              v-for="(durationType, index) of durationTypes.list"
              :key="`social-duration-type-${index}`"
              @click="changeGraphDuration(durationType)"
              class="btn-wrap__item"
              :class="{
                active: durationType.type === durationTypes.selectedType,
              }"
            >
              {{ durationType.name }}
            </span>
          </div>
        </div>
        <apexchart
          ref="processGraph"
          :options="options"
          :series="series"
          height="300"
          class="graph"
        ></apexchart>
      </div>
    </div>
    <div
      v-if="isVideo"
      @click.self="isVideo = false"
      aria-hidden="true"
      class="modal fade"
      id="example"
      role="example"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              @click="isVideo = false"
              aria-hidden="true"
              class="close"
              data-dismiss="modal"
              type="button"
            ></button>
            <h4 class="modal-title">
              <strong>Как правильно сделать фотографию</strong>
            </h4>
          </div>
          <div class="modal-body modal-body--nopadding">
            <iframe
              allow="autoplay; encrypted-media"
              allowfullscreen
              frameborder="0"
              height="340"
              src="https://www.youtube.com/embed/NCQbW6M3l9I?rel=0&showinfo=0&enablejsapi=1"
              width="100%"
              id="instructionPhoto"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { chartConfig } from "@/core/config/apexcharts/profile.config.js";

export default {
  name: "FSProfileGraphs",
  props: {
    url_slug: String,
    need_to_load: Boolean,
  },
  data() {
    return {
      isVideo: false,
      componentTitle: "",
      series: [],
      durationTypes: {
        list: [
          // {
          //   name: "День",
          //   days: 1,
          //   type: "day"
          // },
          {
            name: "Неделя",
            days: 7,
            type: "week",
          },
          {
            name: "Месяц",
            days: 30,
            type: "month",
          },
          {
            name: "Год",
            days: 365,
            type: "year",
          },
          {
            name: "За весь период",
            days: null,
            type: "all",
          },
        ],
        selectedType: "all",
        min: null,
        max: null,
      },
      options: chartConfig,
      graphView: {},
      graphViewMaxItem: null,
      graphsTypes: [
        {
          type: "weight",
          label: "Вес",
          show: false,
        },
        {
          type: "waist",
          label: "Обхват талии",
          show: false,
        },
        {
          type: "hip",
          label: "Обхват бедер",
          show: false,
        },
        {
          type: "chest",
          label: "Обхват груди",
          show: false,
        },
        {
          type: "left_hand",
          label: "Левая рука",
          show: false,
        },
        {
          type: "right_hand",
          label: "Правая рука",
          show: false,
        },
        {
          type: "left_leg",
          label: "Левая нога",
          show: false,
        },
        {
          type: "right_leg",
          label: "Правая нога",
          show: false,
        },
      ],
    };
  },

  computed: {
    socialIsMyProfile() {
      return this.$store.getters.socialIsMyProfile;
    },
    graphData() {
      return this.$store.getters.socialGraphData;
    },
  },
  methods: {
    setPageData() {
      switch (this.socialIsMyProfile) {
        case true:
          this.componentTitle = "Мой прогресс";
          break;
        case false:
          this.componentTitle = "Прогресс";
          break;
      }

      this.updateGraph(this.graphView);
    },
    changeGraphDuration(durationType) {
      // const graphName = `processGraph${this.socialIsMyProfile === true ? 'My' : ''}`;
      this.durationTypes.selectedType = durationType.type;

      switch (durationType.type) {
        case "day":
        case "week":
        case "month":
        case "year":
          this.durationTypes.min =
            this.graphViewMaxItem - 1000 * 60 * 60 * 24 * durationType.days;
          this.durationTypes.max = this.graphViewMaxItem;

          this.$refs.processGraph.zoomX(
            this.durationTypes.min,
            this.durationTypes.max
          );
          break;
        case "all":
        default:
          this.durationTypes.min = null;
          this.durationTypes.max = null;

          this.updateGraph(this.graphView);

          break;
      }
    },
    filterGraphs() {
      let isEmpty = true;
      this.graphView = {};

      for (const graphLocal of this.graphsTypes) {
        graphLocal.show = false;
        if (this.graphData[graphLocal.type]) {
          graphLocal.show = true;
        }

        if (graphLocal.show === true && isEmpty === true) {
          isEmpty = false;
          this.graphView = graphLocal;
        }
      }
    },
    updateGraph(selectedGraphType) {
      const graphName = `processGraph`;
      let newGraphName = "";
      let newGraphData = [];

      for (const graph of this.graphsTypes) {
        if (graph.type === selectedGraphType.type) {
          newGraphName = graph.label;
        }
      }

      if (this.graphData[selectedGraphType.type]) {
        this.graphData[selectedGraphType.type].forEach((item) => {
          const xTime = new Date(item.x).getTime();

          if (this.graphViewMaxItem === null || this.graphViewMaxItem < xTime) {
            this.graphViewMaxItem = xTime;
          }
          newGraphData.push({
            x: xTime,
            y: item.y,
          });
        });
      }

      setTimeout(() => {
        if (
          !this.$refs ||
          !this.$refs[graphName] ||
          newGraphData.length == 0 ||
          newGraphName == ""
        ) {
          return;
        }

        let __self__ = this;

        this.$refs[graphName]
          .updateSeries(
            [
              {
                data: newGraphData,
                name: newGraphName,
              },
            ],
            true
          )
          .then(() => {
            return this.$refs[graphName].updateOptions({
              yaxis: {
                decimalsInFloat: 0,
                forceNiceScale: true,
                title: {
                  text: selectedGraphType.label,
                },
              },
            });
          })
          .then(() => {
            switch (__self__.durationTypes.selectedType) {
              case "day":
              case "week":
              case "month":
              case "year":
                this.$refs[graphName].zoomX(
                  __self__.durationTypes.min,
                  __self__.durationTypes.max
                );

                break;
              case "all":
              default:
                break;
            }
          });
      }, 100);
    },
    getGraphDataByUrlSlug() {
      this.$store.dispatch("getSocialGraphDataByUrlSlug", this.url_slug);
    },
  },
  mounted() {
    if (this.need_to_load === true) {
      this.getGraphDataByUrlSlug();
    }
  },
  watch: {
    graphData() {
      this.filterGraphs();
    },
    graphView() {
      if (this.graphView && Object.keys(this.graphView).length > 0) {
        this.graphViewMaxItem = null;

        this.updateGraph(this.graphView);
      }
    },
    socialIsMyProfile() {
      this.setPageData();
    },
    url_slug() {
      this.series = [];

      this.getGraphDataByUrlSlug();
    },
    need_to_load() {
      if (this.need_to_load === true) {
        this.graphViewMaxItem = null;

        this.getGraphDataByUrlSlug();
      }
    },
  },
};
</script>
