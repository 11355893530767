import settings from "../../settings";
import instance from "./instance.service";

import { getAuthHeader } from "./auth.service";

const basePath = `${settings.API_URL}`;

export default {
  getCoursesFilters(successCallback, errorCallback) {
    instance
      .get(`${basePath}user/programs/filters`, { headers: getAuthHeader() })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getCoursesByCategories(filters, successCallback, errorCallback) {
    instance
      .post(`${basePath}user/courses/filter`, filters, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getAuthHeader(type) {
    return {
      Authorization: `Bearer ${window.localStorage.getItem(settings.ID_TOKEN)}`,
      "Content-Type": type
    };
  }
};
