<template>
  <div
    v-if="socialStatistics"
    class="fs-ptile-notifications"
    style="display: flex;"
  >
    <div class="fs-ptile profile__achievements fs-display--flex-wrp">
      <h5 class="fs-ptile-title" style="margin-bottom: 15px;">
        {{ componentTitle }}
      </h5>
      <div class="fs-display--flex-wrp" style="width: 100%;">
        <div class="fs-ptile-achievements">
          <p class="fs-ptile-achievements__icon">
            <img src="@/assets/img/profile/icons/clock.svg" alt="" />
          </p>
          <div>
            <p class="fs-ptile-achievements__title">
              {{ `${socialStatistics.days_in_website}` }}
              {{
                numberToText(socialStatistics.days_in_website, [
                  "день",
                  "дня",
                  "дней"
                ])
              }}
            </p>
            <p class="fs-ptile-achievements__txt">на платформе</p>
          </div>
        </div>
        <div class="fs-ptile-achievements">
          <p class="fs-ptile-achievements__icon">
            <img src="@/assets/img/profile/icons/energy.svg" alt="" />
          </p>
          <div>
            <p class="fs-ptile-achievements__title">
              {{ `${socialStatistics.done_hours.toFixed(0)}` }}
              {{
                numberToText(socialStatistics.done_hours.toFixed(0), [
                  "час",
                  "часа",
                  "часов"
                ])
              }}
            </p>
            <p class="fs-ptile-achievements__txt">энергии потрачено</p>
          </div>
        </div>
        <div class="fs-ptile-achievements">
          <p class="fs-ptile-achievements__icon">
            <img src="@/assets/img/profile/icons/check.svg" alt="" />
          </p>
          <div>
            <p class="fs-ptile-achievements__title">
              {{ `${socialStatistics.done_trainings}` }}
              {{
                numberToText(socialStatistics.done_trainings, [
                  "занятие",
                  "занятия",
                  "занятий"
                ])
              }}
            </p>
            <p class="fs-ptile-achievements__txt">выполнено</p>
          </div>
        </div>
        <div class="fs-ptile-achievements">
          <p class="fs-ptile-achievements__icon">
            <img src="@/assets/img/profile/icons/kkal.svg" alt="" />
          </p>
          <div>
            <p class="fs-ptile-achievements__title">
              {{ `${socialStatistics.total_calories}` }} Ккал
            </p>
            <p class="fs-ptile-achievements__txt">Всего сожжено калорий</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import settings from "@/settings";
import { numberToText } from "@/assets/js/helpers";

export default {
  name: "FSProfileCounters",
  props: {
    url_slug: String
  },
  data() {
    return {
      componentTitle: "",
      defaultSettings: {
        APP_URL: settings.APP_URL
      }
    };
  },
  computed: {
    socialIsMyProfile() {
      return this.$store.getters.socialIsMyProfile;
    },
    socialStatistics() {
      return this.$store.getters.socialStatistics;
    }
  },
  mounted() {
    this.setPageData();
  },
  created() {
    this.$store.dispatch("getSocialStatisticsByUrlSlug", this.url_slug);
  },
  methods: {
    numberToText: numberToText,
    setPageData() {
      switch (this.socialIsMyProfile) {
        case true:
          this.componentTitle = "Мои достижения";
          break;
        case false:
          this.componentTitle = "Достижения";
          break;
      }
    }
  },
  watch: {
    socialIsMyProfile() {
      this.setPageData();
    },
    url_slug() {
      this.$store.dispatch("getSocialStatisticsByUrlSlug", this.url_slug);
    }
  }
};
</script>
