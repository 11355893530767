import $ from "jquery";

import coursesService from "../../services/courses.service";

// action types
export const GET_COURSES_LIST = "getCoursesList";
export const GET_COURSES_FILTERS = "getCoursesFilters";
export const GET_COURSES_LIST_BY_FILTER = "getCoursesByFilter";

// mutator types
export const COURSES_IS_FILTERED = "isCoursesFiltered";
export const COURSES_IS_LOADED = "isCoursesLoaded";
export const COURSES_IS_LOADING = "isCoursesLoading";

export const COURSES_FILTERS_IS_LOADED = "isCoursesFiltersLoaded";
export const COURSES_FILTERS_IS_LOADING = "isCoursesFiltersLoading";

export const SWITCH_FILTER_BUTTON = "switchFilterButton";

export default {
  state: {
    buttons: {},
    isMyCourses: false,
    filters: {
      list: {
        cat_min_level: [],
        goals: [],
        categories: [],
        trainer_name: []
      },
      isLoaded: false,
      isLoading: false
    },
    courses: {
      all: [],
      list: [],
      temp: [],
      isFiltered: false,
      isLoaded: false,
      isLoading: false
    },
    _addCourseToCategoryFunc: (
      coursesList,
      category,
      course,
      reverse = false
    ) => {
      if (!course) {
        return;
      }

      if (!coursesList[category.code]) {
        coursesList[category.code] = {
          name: category.name,
          code: category.code,
          isAnchorShow:
            category.anchorDontShow && category.anchorDontShow === true
              ? false
              : true,
          hasAnchors: category.hasAnchors ? category.hasAnchors : false,
          courses: []
        };
      }

      if (category.field) {
        coursesList[category.code].field = category.field;
      }
      if (category.limit) {
        coursesList[category.code].limit = category.limit;
      }
      if (reverse === true) {
        coursesList[category.code].courses.unshift(course);
      } else {
        coursesList[category.code].courses.push(course);
      }
    },
    _sortCoursesInCategoryFunc: category => {
      if (category.field) {
        category.courses = category.courses.sort((a, b) => {
          return b[category.field] - a[category.field];
        });
      }

      if (category.limit) {
        category.courses.length = category.limit;
      }
    }
  },
  getters: {
    coursesFilters: state => state.filters.list,
    coursesFiltersButtons: state => state.buttons,
    coursesFiltersIsLoaded: state => state.filters.isLoaded,
    coursesByCategories: state => state.courses.list,
    coursesIsLoaded: state => state.courses.isLoading,
    coursesIsLoading: state => state.courses.isLoaded,
    coursesIsFiltered: state => state.courses.isFiltered
  },
  actions: {
    [GET_COURSES_FILTERS]({ commit }) {
      commit(COURSES_FILTERS_IS_LOADING, true);

      return new Promise((resolve, reject) => {
        coursesService.getCoursesFilters(
          response => {
            commit(GET_COURSES_FILTERS, response);

            resolve(response);
          },
          error => {
            commit(COURSES_FILTERS_IS_LOADING, false);
            commit(COURSES_FILTERS_IS_LOADED, false);

            reject(error);
          }
        );
      });
    },
    [GET_COURSES_LIST]({ commit }, filter) {
      commit(COURSES_IS_LOADING, true);

      return new Promise((resolve, reject) => {
        coursesService.getCoursesByCategories(
          filter,
          data => {
            data.filter = filter;
            commit(GET_COURSES_LIST, data);

            resolve(data);
          },
          error => {
            commit(COURSES_IS_LOADED, false);
            commit(COURSES_IS_LOADING, false);

            reject(error);
          }
        );
      });
    },
    [GET_COURSES_LIST_BY_FILTER]({ commit }, filter) {
      commit(GET_COURSES_LIST_BY_FILTER, filter);
    },
    [SWITCH_FILTER_BUTTON]({ commit }, filterName) {
      commit(SWITCH_FILTER_BUTTON, filterName);
    }
  },
  mutations: {
    [GET_COURSES_FILTERS](state, filters) {
      state.filters.list = filters;
      state.filters.isLoaded = true;
      state.filters.isLoading = false;
    },
    [GET_COURSES_LIST](state, data) {
      const customCategory = [
        "popular_programs",
        "new_programs",
        "my_programs"
      ];

      let categoriesList = {};
      let coursesByCategories = [];

      state.isMyCourses = data.filter.my_courses;
      state.courses.isFiltered = data.filter.isFiltered;
      state.courses.all = data.programs;

      if (state.courses.isFiltered === true) {
        $.each(state.courses.all, (index, course) => {
          state._addCourseToCategoryFunc(
            categoriesList,
            {
              name: "none",
              code: "none"
            },
            course,
            state.isMyCourses ? false : true
          );
        });

        // eslint-disable-next-line no-unused-vars
        $.each(categoriesList, (index, category) => {
          coursesByCategories.push(categoriesList[index]);
        });
      } else if (state.isMyCourses === true) {
        $.each(state.courses.all, (index, course) => {
          state._addCourseToCategoryFunc(
            categoriesList,
            {
              name: "Мои программы",
              code: "my_programs"
            },
            course
          );
        });

        $.each(categoriesList, (index, category) => {
          state._sortCoursesInCategoryFunc(category);
          if ($.inArray(category.code, customCategory) < 0) {
            coursesByCategories.push(categoriesList[index]);
          }
        });

        $.each(customCategory, (index, category) => {
          if (categoriesList && categoriesList[category]) {
            coursesByCategories.unshift(categoriesList[category]);
          }
        });

        if (coursesByCategories.length > 0) {
          coursesByCategories[0].courses = coursesByCategories[0].courses.sort(
            function(a, b) {
              return a.course_started_id < b.course_started_id;
            }
          );
        }
      } else {
        $.each(state.courses.all, (index, course) => {
          state._addCourseToCategoryFunc(
            categoriesList,
            {
              name: "Новинки",
              code: "new_programs",
              limit: 4,
              field: "id",
              courses: [],
              anchorDontShow: true
            },
            course,
            true
          );
          state._addCourseToCategoryFunc(
            categoriesList,
            {
              name: "Популярное в этом месяце",
              code: "popular_programs",
              limit: 4,
              field: "count_last_30_days_done_trainings",
              courses: [],
              anchorDontShow: true,
              hasAnchors: true
            },
            course
          );

          $.each(course.categories, (index, category) => {
            state._addCourseToCategoryFunc(categoriesList, category, course);
          });
        });

        $.each(categoriesList, (index, category) => {
          state._sortCoursesInCategoryFunc(category);
          if ($.inArray(category.code, customCategory) < 0) {
            coursesByCategories.push(categoriesList[index]);
          }
        });

        $.each(customCategory, (index, category) => {
          if (categoriesList && categoriesList[category]) {
            coursesByCategories.unshift(categoriesList[category]);
          }
        });
      }

      state.courses.list = coursesByCategories;
      state.courses.temp = coursesByCategories;
    },
    [GET_COURSES_LIST_BY_FILTER](state, filter) {
      let coursesByCategories = JSON.parse(JSON.stringify(state.courses.all));
      let coursesByCategoriesArray = [];
      let categoriesList = {};
      let isEmptyFilters = true;

      let courses = coursesByCategories.filter(course => {
        let isNeedToAdd = true;

        $.each(filter, (filterName, filterList) => {
          let isNeedToAddItem = false;
          let isEmptyFilter = filterList.length < 1 ? true : false;

          isEmptyFilters =
            isEmptyFilters !== false && filterList.length < 1 ? true : false;

          if (isEmptyFilter === true) {
            return;
          }

          switch (filterName) {
            case "goals":
            case "categories":
              $.each(course[filterName], (indexP, pItem) => {
                $.each(filterList, (indexF, fId) => {
                  if (fId == pItem.id) {
                    isNeedToAddItem = true;
                  }
                });
              });

              break;
            case "cat_min_level":
            case "trainer_name":
              $.each(filterList, (indexF, fItem) => {
                if (fItem == course[filterName]) {
                  isNeedToAddItem = true;
                }
              });

              break;
            default:
              break;
          }

          if (
            isEmptyFilter === false &&
            isNeedToAddItem === false &&
            isNeedToAdd !== false
          ) {
            isNeedToAdd = false;
          }
        });

        if (isNeedToAdd === true) {
          return true;
        }

        return false;
      });

      if (isEmptyFilters === true) {
        state.courses.isFiltered = false;
        state.courses.list = state.courses.temp;

        return;
      }

      $.each(courses, (index, course) => {
        state._addCourseToCategoryFunc(
          categoriesList,
          {
            name: "none",
            code: "none"
          },
          course
        );
      });

      // eslint-disable-next-line no-unused-vars
      $.each(categoriesList, (index, category) => {
        coursesByCategoriesArray.push(category);
      });

      state.courses.list = coursesByCategoriesArray;
      state.courses.isFiltered = true;
    },
    [COURSES_FILTERS_IS_LOADED](state, isLoaded) {
      state.filters.isLoaded = isLoaded;
    },
    [COURSES_FILTERS_IS_LOADING](state, isLoading) {
      state.filters.isLoading = isLoading;
    },
    [COURSES_IS_LOADED](state, isLoaded) {
      state.courses.isLoaded = isLoaded;
    },
    [COURSES_IS_LOADING](state, isLoading) {
      state.courses.isLoading = isLoading;
    },
    [SWITCH_FILTER_BUTTON](state, filterName) {
      let buttons = JSON.parse(JSON.stringify(state.buttons));

      if (
        filterName &&
        (!buttons[filterName] || buttons[filterName] == false)
      ) {
        buttons[filterName] = true;
      } else if (filterName && buttons[filterName] == true) {
        buttons[filterName] = false;
      }

      // eslint-disable-next-line no-unused-vars
      $.each(buttons, (index, value) => {
        if (index != filterName || !filterName) {
          buttons[index] = false;
        }
      });

      state.buttons = buttons;
    }
  }
};
