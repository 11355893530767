import settings from "../../settings";
import instance from "./instance.service";
import { getAuthHeader } from "./auth.service";

const basePath = `${settings.API_URL}`;

export default {
  getUserData(successCallback, errorCallback) {
    instance
      .get(`${basePath}user/meal/data`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getAccess(successCallback, errorCallback) {
    instance
      .get(`${basePath}user/meal/access`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  updateUserDataModal(userData, successCallback, errorCallback) {
    instance
      .post(`${basePath}user/meal/data-modal`, userData, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  updateUserData(userData, successCallback, errorCallback) {
    instance
      .post(`${basePath}user/meal/data`, userData, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getRecipes(successCallback, errorCallback) {
    instance
      .get(`${basePath}user/meal/recipes`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  updateRecipes(recipes, successCallback, errorCallback) {
    instance
      .post(
        `${basePath}user/meal/recipes`,
        {
          recipes
        },
        {
          headers: getAuthHeader()
        }
      )
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getRecipe(id, successCallback, errorCallback) {
    instance
      .get(`${basePath}user/meal/recipe/${id}`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getAvailableRecipes(dish, successCallback, errorCallback) {
    instance
      .get(`${basePath}user/meal/recipes/available/${dish}`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getAvailableFilterRecipes(filters, successCallback, errorCallback) {
    instance
      .post(`${basePath}user/meal/recipes/available-filter`, filters, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getBMR(successCallback, errorCallback) {
    instance
      .get(`${basePath}user/meal/bmr`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getBJU(successCallback, errorCallback) {
    instance
      .get(`${basePath}user/meal/bju`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  }
};
