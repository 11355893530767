import { chartLanguageConfig } from "@/core/config/apexcharts/lang/ru.js";

// Настройки для графика на странице user/{url_slug}
export const chartConfig = {
  chart: {
    defaultLocale: chartLanguageConfig.defaultLocale,
    locales: chartLanguageConfig.locales,
    height: 350,
    type: "area",
    // dropShadow: {
    //   enabled: true,
    //   color: "#000",
    //   top: 18,
    //   left: 7,
    //   blur: 10,
    //   opacity: 0.2
    // },
    toolbar: {
      show: false
    }
  },
  colors: ["#FF9BE2", "#FFF1FB"],
  dataLabels: {
    enabled: true
  },
  stroke: {
    curve: "smooth"
  },
  grid: {
    borderColor: "#e7e7e7",
    row: {
      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.5
    }
  },
  markers: {
    size: 1
  },
  fill: {
    type: "gradient",
    // colors: ["#FF9BE2", "#FFF1FB"],
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 1,
      stops: [0, 100]
    }
  },
  xaxis: {
    type: "datetime",
    axisBorder: {
      show: true,
      color: "#78909C",
      height: 1,
      // width: "100%",
      offsetX: 0,
      offsetY: 0
    },
    axisTicks: {
      show: true,
      borderType: "solid",
      color: "#78909C",
      height: 6,
      offsetX: 0,
      offsetY: 0
    }
  },
  yaxis: {
    decimalsInFloat: 0,
    forceNiceScale: true,
    axisBorder: {
      show: true,
      height: "100%",
      color: "#78909C",
      offsetX: 0,
      offsetY: 0
    },
    axisTicks: {
      show: true,
      borderType: "solid",
      color: "#78909C",
      width: 6,
      offsetX: 0,
      offsetY: 0
    }
  },
  legend: {
    position: "top",
    horizontalAlign: "right",
    floating: true,
    offsetY: -25,
    offsetX: -5
  }
};
