<template>
  <div class="invite-friends">
    <NoticeSubscriptionRenewal v-if="!userSubscriptions"/>

    <div class="portlet-body" style="padding-bottom: 30px;">
      <div class="invite-friends__color-wr">
        <p class="yf-medium-title">
          Пригласи друга и получи <span>месяц тренировок бесплатно!</span>
        </p>
        <h2 class="yf-header-title invite-friends__title">
          Как это работает?
        </h2>
        <div class="invite-friends__wrap">
          <div class="invite-friends__steps">
            <p class="step-number">1<span class="message"></span></p>
            <p class="title">Расскажи друзьям</p>
            <p class="txt">
              Отправь друзьям свою уникальную ссылку или поделись ею в
              социальных сетях
            </p>
          </div>
          <div class="invite-friends__steps">
            <p class="step-number">2<span class="time"></span></p>
            <p class="title">Дождись оплаты</p>
            <p class="txt">
              Дождитесь, пока друзья, которым ты отправила ссылку совершат
              оплату любого тарифа
            </p>
          </div>
          <div class="invite-friends__steps">
            <p class="step-number">3<span class="training"></span></p>
            <p class="title">Тренируйся бесплатно!</p>
            <p class="txt">
              Как только 2 друга оплатят подписку, ты автоматически получишь
              месяц тренировок бесплатно!
            </p>
          </div>
        </div>
        <div class="invite-friends__link-block">
          <div class="col">
            <p>Копировать ссылку:</p>
            <div
              class="input"
              @click="copyInviteLink"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Нажми, чтобы скопировать"
            >
              <input
                type="text"
                class="form-control cursor-pointer"
                v-model="inviteLink"
                disabled
              />
              <span class="input-group-addon cursor-pointer"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white bg-white--transparent">
        <h2 class="yf-header-title invite-friends__title">
          Мои бесплатные продления
        </h2>
        <div class="invite-friends__statistic">
          <div class="invite-friends__statistic--block">
            <p class="title">Зарегистрировалось друзей</p>
            <p class="counter">{{ userInviteCounters.purchases_count }}</p>
          </div>
          <div class="invite-friends__statistic--block">
            <p class="title">Количество продлений</p>
            <p class="counter">
              {{ userInviteCounters.users_registered_count }}
            </p>
          </div>
        </div>
      </div>
      <div style="padding: 20px 0; background: #fff;">
        <div class="promocode-list-conteiner">
          <div
            v-for="promocode of user.promocode_list"
            :key="`promocode-id-${promocode.id}`"
            class="promocode"
            @click="copyPromocode(promocode)"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Нажми, чтобы скопировать"
          >
            <div class="promocode__code">{{ promocode.code }}</div>
            <div class="promocode__amount">{{ promocode.value }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from "jquery";
// import "bootstrap/js/tooltip";
import settings from "@/settings";
import { copyToClipboard } from "@/assets/js/helpers";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";

import NoticeSubscriptionRenewal from '@/components/content/NoticeSubscriptionRenewal.vue';

export default {
  name: "PageComments",

  components: {
    NoticeSubscriptionRenewal
  },

  data() {
    return {
      inviteLink: "",
      promocode: {
        code: "FJ2F4KEK",
        amount: "500"
      }
    };
  },
  computed: {
    userInviteCounters() {
      return this.$store.getters.userInviteCounters;
    },
    user() {
      return this.$store.getters.userData;
    },
    userSubscriptions() {
      return this.$store.getters.userSubscriptions;
    },
  },
  watch: {
    user: {
      deep: true,
      handler(newVal) {
        if (newVal.referal_code) {
          this.inviteLink = `${settings.APP_URL}invite/${newVal.referal_code}`;
        }
      }
    }
  },
  methods: {
    copyInviteLink() {
      this.copyToClipboard(this.inviteLink);

      this.$notify({
        type: "success",
        title: "Ссылка скопирована в буфер обмена"
      });
    },
    copyPromocode(promocode) {
      this.copyToClipboard(promocode.code);

      this.$notify({
        type: "success",
        title: "Промокод скопирована в буфер обмена"
      });
    },
    sendInvite() {
      const user = `${this.user.name} ${this.user.surname}`;
      const subject = `${user} подарил(-а) тебе скидку 70%`;
      const body = `${user} подарил(-а) тебе скидку 70% на программы тренировок или подписку FitStars!\n Чтобы получить скидку, просто зарегистрируйся по этой ссылке: ${settings.APP_URL}invite/${this.user.referal_code}`;

      window.location.href = `mailto:?subject=${subject}&body=${body}`;
    },
    copyToClipboard
  },
  mounted() {
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "content-loading");
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Пригласи друга" }]);

    this.$store.dispatch("getUser");
    this.$store.dispatch("getUserInviteCounters");
    // this.$nextTick(() => {
    //   $('[data-toggle="tooltip"]').tooltip();
    // });
  },
  setPageData() {
    switch (this.socialIsMyProfile) {
      case true:
        this.pageTitle = "Пригласи друга - FitStars.ru";
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Пригласи друга" }]);

        break;
      case false:
        this.pageTitle = "Пригласи друга - FitStars.ru";
        this.$store.dispatch(SET_BREADCRUMB, [
          {
            title: "Пригласи друга - FitStars.ru"
          }
        ]);

        break;
    }
  }
};
</script>
