<template>
  <nav>
    <show-at breakpoint="large">
      <div class="">
        <div class="profile">
          <div class="page-head" style="display: none">
            <div class="page-title">
              <h2>{{ pageTitle }}</h2>
            </div>
          </div>

          <NoticeSubscriptionRenewal v-if="!userSubscriptions"/>

          <div v-if="socialIsBlocked === true && socialIsMyProfile === false">
            <FSProfilehd :url_slug="url_slug"></FSProfilehd>
            <div class="text-center">
              <div
                class="swal2-icon swal2-warning swal2-icon-show"
                style="display: flex;"
              >
                <div class="swal2-icon-content">!</div>
              </div>
              <h4>Пользователь заблокировал доступ к данным!!!</h4>
            </div>
          </div>
          <div v-else>
            <FSProfilehd :url_slug="url_slug"></FSProfilehd>

            <div class="profile__statistick fs-display--flex">
              <FSProfileGraphs
                :need_to_load="true"
                :url_slug="url_slug"
              ></FSProfileGraphs>
              <div class="divider-25 hide-on--tablet"></div>
              <FSProfileCounters :url_slug="url_slug"></FSProfileCounters>
            </div>

            <div class="fs-display--flex-wrp">
              <FSProfileTape
                :need_to_load="true"
                :url_slug="url_slug"
              ></FSProfileTape>

              <div class="divider-25 hide-on--tablet"></div>

              <div class="fs-ptile-notifications">
                <FSProfileFriends :url_slug="url_slug"></FSProfileFriends>
                <FSProfileShare :url_slug="url_slug"></FSProfileShare>
              </div>
            </div>
          </div>
        </div>
      </div>
    </show-at>
    <hide-at breakpoint="large">
      <div class="profile mob">
        <div class="page-head" style="display: none">
          <div class="page-title">
            <h2>{{ pageTitle }}</h2>
          </div>
        </div>

        <NoticeSubscriptionRenewal v-if="!userSubscriptions"/>

        <div v-if="socialIsBlocked === true && socialIsMyProfile === false">
          <FSProfilehd :url_slug="url_slug"></FSProfilehd>
          <div class="text-center">
            <div
              class="swal2-icon swal2-warning swal2-icon-show"
              style="display: flex;"
            >
              <div class="swal2-icon-content">!</div>
            </div>
            <h4>Пользователь заблокировал доступ к данным!!!!</h4>
          </div>
        </div>
        <div v-else>
          <FSProfilehd :url_slug="url_slug"></FSProfilehd>
          <ul class="profile-tab nav nav-tabs-line">
            <li class="profile-tab__item">
              <a
                class="profile-tab__link active"
                data-toggle="tab"
                href="#kt_tab_pane_1"
                @click="checkTape()"
              >
                <i class="flaticon2-list-2"></i>Лента
              </a>
            </li>
            <div class="columne-divider--15"></div>
            <li class="profile-tab__item">
              <a
                class="profile-tab__link"
                data-toggle="tab"
                href="#kt_tab_pane_2"
                @click="checkFriends()"
              >
                <i class="flaticon2-group"></i>Друзья
              </a>
            </li>
            <div class="columne-divider--15"></div>
            <li class="profile-tab__item">
              <a
                class="profile-tab__link"
                data-toggle="tab"
                href="#kt_tab_pane_3"
                @click="checkGraphs()"
              >
                <i class="flaticon2-graphic"></i>Прогресс
              </a>
            </li>
          </ul>
          <div class="tab-content mt-5" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="kt_tab_pane_1"
              role="tabpanel"
              aria-labelledby="kt_tab_pane_2"
            >
              <FSProfileTapeMobile
                :need_to_load="eventNeedToBeStarted"
                :url_slug="url_slug"
              ></FSProfileTapeMobile>
            </div>
            <div
              class="tab-pane fade"
              id="kt_tab_pane_2"
              role="tabpanel"
              aria-labelledby="kt_tab_pane_2"
            >
              <FSProfileShare :url_slug="url_slug"></FSProfileShare>
              <FSProfileFriends :url_slug="url_slug"></FSProfileFriends>
            </div>
            <div
              class="tab-pane fade"
              id="kt_tab_pane_3"
              role="tabpanel"
              aria-labelledby="kt_tab_pane_3"
            >
              <FSProfileCounters :url_slug="url_slug"></FSProfileCounters>
              <FSProfileGraphs
                :need_to_load="graphNeedToBeLoaded"
                :url_slug="url_slug"
              ></FSProfileGraphs>
            </div>
          </div>
        </div>
      </div>
    </hide-at>
  </nav>
</template>

<script>
import FSProfilehd from "@/components/PagePartials/profile/Header.vue";
import FSProfileGraphs from "@/components/PagePartials/profile/Graphs.vue";
import FSProfileCounters from "@/components/PagePartials/profile/Counters.vue";
import FSProfileTape from "@/components/PagePartials/profile/Tape/index.vue";
import FSProfileTapeMobile from "@/components/PagePartials/profile/Tape/Mobile.vue";
import FSProfileShare from "@/components/PagePartials/profile/Share.vue";
import FSProfileFriends from "@/components/PagePartials/profile/Friends.vue";
import NoticeSubscriptionRenewal from '@/components/content/NoticeSubscriptionRenewal.vue';

import { showAt, hideAt } from "vue-breakpoints";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "PageProgramsNew",
  components: {
    FSProfilehd,
    FSProfileGraphs,
    FSProfileCounters,
    FSProfileTape,
    FSProfileTapeMobile,
    FSProfileFriends,
    FSProfileShare,
    hideAt,
    showAt,
    NoticeSubscriptionRenewal
  },
  props: {
    url_slug: String
  },
  data() {
    return {
      pageTitle: "",
      graphNeedToBeLoaded: false,
      eventNeedToBeStarted: true
    };
  },
  computed: {
    socialIsMyProfile() {
      return this.$store.getters.socialIsMyProfile;
    },
    socialIsBlocked() {
      return this.$store.getters.socialIsBlocked;
    },
    userSubscriptions() {
      return this.$store.getters.userSubscriptions;
    },
  },
  mounted() {
    this.setPageData();
  },
  methods: {
    checkTape() {
      this.eventNeedToBeStarted = true;
      this.graphNeedToBeLoaded = false;
    },
    checkFriends() {
      this.eventNeedToBeStarted = false;
      this.graphNeedToBeLoaded = false;
    },
    checkGraphs() {
      this.graphNeedToBeLoaded = true;
      this.eventNeedToBeStarted = false;
    },
    setPageData() {
      switch (this.socialIsMyProfile) {
        case true:
          this.pageTitle = "Мой профиль - FitStars.ru";
          this.$store.dispatch(SET_BREADCRUMB, [{ title: "Главная" }]);

          break;
        case false:
          this.pageTitle = "Профиль пользователя";
          this.$store.dispatch(SET_BREADCRUMB, [
            {
              title: "Профиль пользователя"
            }
          ]);

          break;
      }
    }
  },
  watch: {
    socialIsMyProfile() {
      this.setPageData();
    }
  }
};
</script>
