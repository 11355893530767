import settings from "../../settings";
import instance from "./instance.service";
import { getAuthHeader } from "./auth.service";

const basePath = `${settings.API_URL}`;

export default {
  getTapeListByUrlSlug(data, successCallback, errorCallback) {
    const request = {
      url_slug: data.url_slug,
      only_my: data.onlyMyTape === true ? 1 : 0,
      offset: data.offset,
      limit: data.limit
    };

    instance
      .post(`${basePath}user/social/news-list/`, request, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getNotificationsListByUrlSlug(urlSlug, successCallback, errorCallback) {
    instance
      .get(`${basePath}user/social/notifications-list/${urlSlug}`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getFollowingListByUrlSlug(data, successCallback, errorCallback) {
    instance
      .post(`${basePath}user/social/following-list`, data, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getUserByUrlSlug(urlSlug, successCallback, errorCallback) {
    instance
      .get(`${basePath}user/social/profile/${urlSlug}`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getGraphDataByUrlSlug(urlSlug, successCallback, errorCallback) {
    instance
      .get(`${basePath}user/social/graph-data/${urlSlug}`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getStatisticsByUrlSlug(urlSlug, successCallback, errorCallback) {
    instance
      .get(`${basePath}user/social/counters/${urlSlug}`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  switchShowTapeForUser(user, successCallback, errorCallback) {
    instance
      .put(
        `${basePath}user/social/switch-notification/${user.id}`,
        {},
        {
          headers: getAuthHeader()
        }
      )
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  follow(follow, successCallback, errorCallback) {
    instance
      .post(
        `${basePath}user/social/follow/${follow.id}`,
        {},
        {
          headers: getAuthHeader()
        }
      )
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  unfollow(user, successCallback, errorCallback) {
    instance
      .put(
        `${basePath}user/social/unfollow/${user.id}`,
        {},
        {
          headers: getAuthHeader()
        }
      )
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  }
};
