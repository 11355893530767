<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
    <div
      class="d-flex align-items-center justify-content-between"
      v-bind:class="{
        'container-fluid': widthFluid,
        container: !widthFluid,
      }"
    >
      <div class="text-dark">
        <span class="text-muted font-weight-bold mr-2">
          © FitStars | Поддержка:
        </span>
        <a
          href="mailto:support@fitstars.ru"
          target="_blank"
          class="text-dark-75 text-hover-primary mr-3"
        >
          support@fitstars.ru
        </a>
        <span hidden class="white-space--nowrap">
          <span class="text-muted">
            Если вы нашли ошибку —
          </span>
          <a
            href="https://forms.monday.com/forms/8573ea49940259b8de82fd7677792f96?r=use1"
            target="_blank"
            class="text-dark-75 text-hover-primary"
          >
            <strong><u>напишите нам об этом!</u></strong>
          </a>
        </span>
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
  },
};
</script>

<style>
.white-space--nowrap {
  white-space: nowrap;
}
</style>
