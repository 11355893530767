import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import AuthService from "@/core/services/auth.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser1";
export const SET_TOKEN = "setToken";
export const UPDATE_PASSWORD = "updateUser1";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user1: {},
  isAuthenticated: !!JwtService.getToken(),
  token: localStorage.getItem("token") || ""
};

const getters = {
  currentUser(state) {
    return state.user1;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [SET_TOKEN](context, credentials) {
    return new Promise((resolve, reject) => {
      AuthService.loginToken(
        credentials,
        () => {
          context.commit(SET_TOKEN, credentials.token);
          resolve();
        },
        () => {
          reject();
        }
      );
    });
    // return new Promise(resolve => {
    //   ApiService.post("login", credentials)
    //     .then(({ data }) => {
    //       context.commit(SET_AUTH, data);
    //       resolve(data);
    //     })
    //     .catch(({ response }) => {
    //       context.commit(SET_ERROR, response.data.errors);
    //     });
    // });
  },
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise((resolve, reject) => {
      AuthService.logoutSite(
        () => {
          context.commit(PURGE_AUTH);
          resolve();
        },
        () => {
          reject();
        }
      );
    });
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          // reject(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      // AuthService.loginToken(
      //   JwtService.getToken(),
      //   () => {
      //     context.commit(SET_TOKEN, JwtService.getToken());
      //   },
      //   response => {
      //     context.commit(SET_ERROR, response);
      //   }
      // );
    } else {
      context.commit(PURGE_AUTH);
    }

    // if (JwtService.getToken()) {
    //   ApiService.setHeader();
    //   ApiService.get("verify")
    //     .then(({ data }) => {
    //        context.commit(SET_AUTH, data);
    //     })
    //     .catch(({ response }) => {
    //       console.log(response)
    //       context.commit(SET_ERROR, response.data.errors);
    //     });
    // } else {
    //   context.commit(PURGE_AUTH);
    // }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  }
};

const mutations = {
  [SET_TOKEN](state, token) {
    state.isAuthenticated = true;
    state.errors = {};
    JwtService.saveToken(token);
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
