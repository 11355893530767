<template>
  <div class="fs-ptile-tape">
    <div v-if="socialIsMyProfile === true" class="fs-ptile fs-ptile__news">
      <h5 class="fs-ptile-title">Лента</h5>
      <div class="newsline">
        <label
          class="newsline-wrap"
          for="newsline-status"
          style="cursor: pointer;"
        >
          <input
            name="newsline-status"
            type="checkbox"
            id="newsline-status"
            v-model="onlyMyTape"
            class="newsline__input"
          />
          <label class="newsline__label" for="newsline-status"></label>
          <p class="newsline__txt">Только моя лента</p>
        </label>
      </div>
    </div>
    <div v-if="socialTapeList.length < 1" class="fs-ptile">
      Лента новостей пуста
    </div>
    <div
      v-else
      ref="social-news-list"
      class="fs-ptile"
      v-for="(tapeItem, index) of socialTapeList"
      :key="`social-tape-item-${index}`"
    >
      <div class="newsline-tile">
        <router-link
          :to="`/fitness-profile/${tapeItem.author.url_slug}`"
          class="newsline-tile__avatare"
        >
          <img
            v-if="tapeItem.author.avatar"
            class="fs-ptile-img"
            :src="`${tapeItem.author.avatar}`"
            alt=""
          />
          <span v-else class="symbol symbol-35 symbol-light-success">
            <span class="symbol-label font-size-h5 font-weight-bold">
              {{ tapeItem.author.full_name.charAt(0).toUpperCase() }}
            </span>
          </span>
        </router-link>
        <div class="newsline-tile__content">
          <router-link
            :to="`/fitness-profile/${tapeItem.author.url_slug}`"
            class="newsline-tile__name"
            style="text-decoration: underline; color: #4B4B4B;"
          >
            {{ tapeItem.author.full_name }}
          </router-link>
          <p class="newsline-tile__time">
            <BackTimer :date="tapeItem.created_at"></BackTimer>
          </p>
        </div>
        <p class="newsline-tile__about" v-html="tapeItem.description"></p>
      </div>
      <div class="img-conteiner">
        <img
          v-if="tapeItem.class_str.toLowerCase().indexOf('badge') >= 0"
          class="fs-ptile-img__badge"
          :src="getBadgeImage(tapeItem.object_id)"
          alt=""
        />
        <img
          v-else
          class="fs-ptile-img"
          :src="`${tapeItem.image}`"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import BackTimer from "@/components/content/BackTimer.vue";
import settings from "@/settings";
import defaultPhoto from "@/assets/img/noimage.png";

export default {
  name: "FSProfileTapeMobile",
  components: {
    BackTimer
  },
  props: {
    url_slug: String,
    need_to_load: Boolean
  },
  data() {
    return {
      elementLastFriend: null,
      onlyMyTape: false,
      defaultSettings: {
        APP_URL: settings.APP_URL,
        defaultPhoto: defaultPhoto
      },
      offset: 0,
      limit: 5
    };
  },
  computed: {
    socialIsMyProfile() {
      return this.$store.getters.socialIsMyProfile;
    },
    socialTapeList() {
      return this.$store.getters.socialTapeList;
    }
  },
  mounted() {
    let __self__ = this;

    this.getTapeList(0, this.limit);

    this.$root.$on("reloadSocialTapeList", function() {
      __self__.reloadSocialTapeList();
    });

    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    getBadgeImage(badgeId) {
      let url = `${require(`@/assets/img/profile/achive/badge_${badgeId}_1.svg`)}`;

      return url;
    },
    reloadSocialTapeList() {
      const limit = this.socialTapeList.length;

      this.getTapeList(0, limit);
    },
    handleScroll() {
      if (this.need_to_load === false) {
        return;
      }

      let documentHeight = $(document).height();
      let windowHeight = Math.max(
        window.innerHeight,
        document.documentElement.clientHeight,
        document.body.clientHeight
      );
      let windowScrollTop = Math.max($(window).scrollTop(), window.scrollY);

      windowScrollTop =
        parseInt(windowScrollTop) >= windowScrollTop
          ? parseInt(windowScrollTop)
          : parseInt(windowScrollTop) + 1;

      if (windowScrollTop >= documentHeight - windowHeight) {
        this.nextPage();
      }
    },
    nextPage() {
      this.getTapeList();
    },
    revertNextPage() {
      if (this.offset !== 0) {
        this.offset = this.offset - this.limit;
      }
    },
    getTapeList(offset = null, limit = null) {
      let data = {
        onlyMyTape: this.onlyMyTape,
        url_slug: this.url_slug,
        offset: offset !== null ? offset : this.offset,
        limit: limit !== null ? limit : this.limit
      };

      this.$store
        .dispatch("getSocialTapeListByUrlSlug", data)
        .then(() => {
          this._setOffset();
        })
        .catch(() => {
          this._setOffset();
        });
    },
    _setOffset() {
      let length = this.socialTapeList ? this.socialTapeList.length : 0;
      this.offset = length;
    },
    _getTapeListOnLoad() {
      setTimeout(() => {
        let elementNewsList = this.$refs["social-news-list"];
        let elementLastNewsOffset = null;
        let elementLastFriendOffset = null;

        if (
          !elementNewsList ||
          elementNewsList.length < 1 ||
          !this.elementLastFriend
        ) {
          return;
        }

        elementLastNewsOffset = $(
          elementNewsList[elementNewsList.length - 1]
        ).offset();
        elementLastFriendOffset = $(this.elementLastFriend).offset();

        if (elementLastFriendOffset.top > elementLastNewsOffset.top) {
          this.getTapeList();
        }
      }, 100);
    }
  },
  watch: {
    onlyMyTape() {
      this.offset = 0;

      this.getTapeList();
    },
    url_slug() {
      this.offset = 0;
      this.onlyMyTape = false;

      this.getTapeList();
    }
  }
};
</script>
