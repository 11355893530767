import $ from "jquery";
import liveService from "../../services/live.service";

export default {
  state: {
    liveList: {},
    isLoading: {
      liveList: false
    }
  },
  getters: {
    liveList: state => state.liveList,
    liveNextTime: state => {
      let data = {};

      if (!state.liveList) {
        return data;
      }

      $.each(state.liveList, (day, liveList) => {
        if (!liveList) {
          return;
        }

        $.each(liveList, (index, live) => {
          if (live.next_live) {
            data = {
              id: state.liveList[day][index].id,
              time: state.liveList[day][index].next_live,
              timer: null
            };
          }
        });
      });

      return data;
    }
  },
  actions: {
    getLiveList({ commit }, filterData) {
      commit("live_list_loading_start");

      return new Promise((resolve, reject) => {
        liveService.getLiveList(
          filterData,
          data => {
            commit("set_live_list", data);
            commit("live_list_loading_finish");
            resolve(data);
          },
          error => {
            commit("live_list_loading_finish");
            reject(error);
          }
        );
      });
    }
  },
  mutations: {
    set_live_list(state, liveList) {
      state.liveList = liveList;
    },
    live_list_loading_start(state) {
      state.isLoading.liveList = true;
    },
    live_list_loading_finish(state) {
      state.isLoading.liveList = false;
    }
  }
};
