/* eslint-disable import/prefer-default-export */
import $ from 'jquery';
import moment from 'moment';
import { detect } from 'detect-browser';
import TimeAgo from 'javascript-time-ago';

import ru from 'javascript-time-ago/locale/ru';
import 'moment/locale/ru';

TimeAgo.addLocale(ru);

const getYoutubeId = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;

  if (url) {
    const match = url.match(regExp);

    if (match && match[7].length === 11) {
      return match[7];
    }
  }

  return undefined;
};

const generateID = () => {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let text = '';

  for (let i = 0; i < 5; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

const toggleMenu = () => {
  if ($(window).width() < 992) {
    const sidebar = $('.page-sidebar');

    if (sidebar) {
      setTimeout(() => {
        $('.nav-toggle.active').addClass('open');
        sidebar.removeClass('in');
      }, 10);
    }
  }
};

const checkOldBrowser = () => {
  const browser = detect();

  const versionArr = browser.version.split('.');
  const browserName = browser.name;
  const browserVersion = {
    major: versionArr[0],
    minor: versionArr[1],
    patch: versionArr[2],
  };

  if (browserName === 'ie' && parseInt(browserVersion.major, 10) < 10) {
    $('#updateBrowser').show();
    throw new Error('Для работы сайта необходимо обновить браузер');
  }
};

const getTimeAgo = (dateString, format) => {
  const timeAgo = new TimeAgo('ru-RU');
  const date = +moment(dateString, format).toDate();

  return (timeAgo.format(date));
};


/**
 *
 * @param dateString - строка даты, которая приходит с сервера (используется стандартная, вида 2019-01-15 07:32:43)
 * @param format - moment формат, в который будет преобразована дата (https://momentjs.com/docs/#/displaying/format/)
 * @param dateStringFormat - Входной формат даты, если не совпадает с стандартным
 * @returns {string} - отформатированная строка даты
 */

const getFormattedDate = (dateString, format, dateStringFormat) => {
  const date = moment(dateString, !dateStringFormat ? 'YYYY-MM-DD H:mm:ss' : dateStringFormat);

  return date.format(format);
};

/**
 *
 * @param string - Строка для копирования
 */

const copyToClipboard = (string) => {
  const el = document.createElement('textarea');

  el.value = string;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

/**
 *
 * @param number - Число для которого нужно склонение
 * @param textArray - Массив склонений
 */
const numberToText = (number, textArray) => {
    const dozens = Math.abs(number) % 100;
    const units = dozens % 10;

    if (dozens > 10 && dozens < 20) {
        return textArray[2];
    } else if (units > 1 && units < 5) {
        return textArray[1];
    } else if (units === 1) {
        return textArray[0];
    }

    return textArray[2];
};

export {
  getYoutubeId,
  generateID,
  toggleMenu,
  checkOldBrowser,
  getTimeAgo,
  getFormattedDate,
  copyToClipboard,
  numberToText,
};
