<template>
  <div class="filters">
    <p class="filters--title">Фильтры</p>

    <div class="filters--wr">
      <div
        :class="`filter--item ${filterName}`"
        v-for="(filterList, filterName) of filters"
        :key="`programs-filters-${filterName}`"
      >
        <input class="tagify--outside" :name="`${filterName}`" value="" />
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Tagify from "@yaireo/tagify";
import "@yaireo/tagify/dist/tagify.css";
import {
  GET_PROGRAMS_FILTERS,
  GET_PROGRAMS_LIST_BY_FILTER
} from "@/core/services/store/programs.module";

export default {
  name: "FSProgramFilter",
  data() {
    return {
      tags: [],
      filter: {},
      filterIsOpenFilter: false,
      filterIsOpenSearch: false,
      filterIsActiveOnMobile: false
    };
  },
  computed: {
    filters() {
      return this.$store.getters.programsFilters;
    },
    buttons() {
      return this.$store.getters.programsFiltersButtons;
    },
    isFiltersLoaded() {
      return this.$store.getters.programsFiltersIsLoaded;
    }
  },
  mounted() {
    this.getProgramsFilters();
  },
  methods: {
    getProgramsFilters() {
      let __self__ = this;

      this.$store.dispatch(GET_PROGRAMS_FILTERS).then(() => {
        $.each(this.filters, (filterName, filter) => {
          let input = document.querySelector(`input[name=${filterName}]`);

          if (!__self__.filter[filterName]) {
            __self__.filter[filterName] = [];
          }

          $.each(filter, (index, item) => {
            item.filter = filterName;
          });

          // eslint-disable-next-line no-unused-vars
          let elementTagify = new Tagify(input, {
            whitelist: filter,
            enforceWhitelist: true,
            dropdown: {
              position: "input",
              maxItems: 100,
              enabled: 0
            },
            callbacks: {
              add: e => __self__.toggleFilter(e),
              remove: e => __self__.toggleFilter(e)
            }
          });
        });
      });
    },
    toggleFilter(e) {
      let filterName = e.detail.data.filter;
      let filterDataId = e.detail.data.id;
      let filterDataValue = e.detail.data.value;
      let needToFind = "";
      switch (filterName) {
        case "trainer_name":
          needToFind = filterDataValue;
          break;
        default:
          needToFind = filterDataId;
          break;
      }

      if (!this.filter[filterName]) {
        this.filter[filterName] = [];
      }

      let inFilterIndex = $.inArray(needToFind, this.filter[filterName]);

      if (inFilterIndex < 0) {
        this.filter[filterName].push(needToFind);
      } else {
        this.filter[filterName].splice(inFilterIndex, 1);
      }

      this.getProgramsByFilter();
    },
    getProgramsByFilter() {
      this.$store.dispatch(GET_PROGRAMS_LIST_BY_FILTER, this.filter);
    }
  }
};
</script>
