import $ from "jquery";

import settings from "../../settings";
import instance from "./instance.service";
import { getAuthHeader } from "./auth.service";

const basePathV2 = `${settings.API_URL_V2}`;

export default {
  getRecipesData(requestData, successCallback, errorCallback) {
    let request = {
      headers: getAuthHeader(),
      params: requestData
    };

    instance
      .get(`${basePathV2}recipes/food/data`, request)
      .then(response => {
        if (response && response.data) {
          successCallback(response.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getRecipesFoodFilterByType(requestData, successCallback, errorCallback) {
    let request = {
      headers: getAuthHeader(),
      params: requestData
    };

    instance
      .get(
        `${basePathV2}recipes/food/filter/${requestData.recipe_type}`,
        request
      )
      .then(response => {
        if (response && response.data) {
          successCallback(response.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getRecipesFoodFilters(successCallback, errorCallback) {
    instance
      .get(`${basePathV2}recipes/food/filters/`, { headers: getAuthHeader() })
      .then(response => {
        if (response && response.data) {
          successCallback(response.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  updateRecipesDataFavorites(data, successCallback, errorCallback) {
    let requestData = [];

    $.each(data, (eatingType, recipes) => {
      $.each(recipes, (recipeIndex, recipe) => {
        const data = {
          eating_type: eatingType,
          recipe_id: recipe.id ? recipe.id : null,
          kkal: recipe.kkal ? recipe.kkal : 0
        };

        requestData.push(data);
      });
    });

    instance
      .post(`${basePathV2}recipes/food/data`, requestData, {
        headers: getAuthHeader()
      })
      .then(response => {
        if (response && response.data) {
          successCallback(response.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  deleteRecipesData(id, successCallback, errorCallback) {
    instance
      .delete(`${basePathV2}recipes/food/data/${id}`, {
        headers: getAuthHeader()
      })
      .then(response => {
        if (response && response.data) {
          successCallback(response.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  }
};
