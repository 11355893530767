import settings from "../../settings";
import instance from "./instance.service";
import { getAuthHeader } from "../services/auth.service";

const basePath = `${settings.API_URL}`;

export default {
  getCounters(successCallback, errorCallback) {
    instance
      .get(`${basePath}public/get-counters`, { headers: getAuthHeader() })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
};
