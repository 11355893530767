import videojs from "video.js";

const Component = videojs.getComponent('Component');

class CustomOverlay extends Component {

  constructor(player, options = {}) {
    super(player, options);

    this.hide();

    this.on(player, 'useractive', function() {
      this.show();
    });

    this.on(player, 'userinactive', function() {
      if (!player.paused()) {
        this.hide();
      }
    });

    this.on(player, 'pause', function() {
      setTimeout(() => {
        let current = player.currentTime();
        if (current !== 1 && current !== 0) {
          this.show();
        }
      }, 200)
    });

    this.on('click', function() {
      player.pause();
    });
  }

  createEl() {
    return videojs.dom.createEl('div', {
      className: 'vjs-custom__overlay'
    });
  }
}

export default CustomOverlay;