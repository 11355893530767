var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"programs"},[_c('div',{staticClass:"class"},[(!_vm.myCoursesPage && !_vm.userSubscriptions)?_c('NoticeSubscriptionRenewal'):_vm._e(),(_vm.myCoursesPage === false)?_c('div',{staticClass:"page-head"},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"page-body"},[(_vm.myCoursesPage && !_vm.userSubscriptions)?_c('NoticeSubscriptionRenewal'):_vm._e(),(_vm.coursesByCategories.length === 0 && _vm.myCoursesPage === true)?_c('div',{staticClass:"fs-ptile--programs-my fs-ptile--wrap"},[_vm._m(1),_c('div',{staticClass:"fs-ptile-line"}),_c('div',{staticClass:"mb-8 mt-8 mr-8 ml-8",staticStyle:{"width":"100%"}},[_vm._v(" Курсы, которые ты купил появляются в этом разделе ")]),_c('div',{staticClass:"mb-8 ml-8"},[_c('router-link',{attrs:{"to":"/courses"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{staticClass:"btn btn-success mr-2",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Выбрать курс ")])]}}],null,false,932682774)})],1)]):_vm._e(),(_vm.coursesByCategories.length === 0 && _vm.myCoursesPage === false)?_c('div',{staticClass:"col-xs-12 mt-11"},[_vm._v(" На данный момент доступных курсов нет. ")]):(_vm.coursesByCategories.length > 0)?_vm._l((_vm.coursesByCategories),function(category,indexCategory){return _c('div',{key:`programs-categories-${
            _vm.myCoursesPage === true ? 'my' : ''
          }-${indexCategory}`,class:{ 'mt-0': indexCategory === 0 }},[(_vm.myCoursesPage === true)?_c('div',[(category)?_c('div',{staticClass:"row"},_vm._l((category.courses),function(course,indexCourse){return _c('div',{key:`program-item-key-${indexCategory}-${indexCourse}-${
                  course && course.id ? course.id : 'nill'
                }`,staticClass:"col-xl-6",on:{"click":function($event){return _vm.goToCourse(course)}}},[(course && course.id)?_c('FSCourseItemMy',{key:`program-item-key-component-${indexCategory}-${indexCourse}-${
                    course && course.id ? course.id : 'nill'
                  }`,attrs:{"indexCourse":indexCourse,"course":course}}):_vm._e()],1)}),0):_vm._e()]):_c('div',{staticClass:"catalog"},[_c('ul',{staticClass:"flex flex-wrap catalog--on-filter courses-list"},_vm._l((category.courses),function(course,indexCourse){return _c('li',{key:`program-item-key-${indexCategory}-${indexCourse}-${
                  course && course.id ? course.id : 'nill'
                }`,staticClass:"courses-list__item",on:{"click":function($event){return _vm.goToCourse(course)}}},[_c('FSCourseItem',{key:`program-item-key-component-${indexCategory}-${indexCourse}-${
                    course && course.id ? course.id : 'nill'
                  }`,attrs:{"indexCourse":indexCourse,"indexCategory":indexCategory,"course":course}})],1)}),0)])])}):_vm._e()],2)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title"},[_c('h2',[_vm._v("Курсы")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h5',{staticClass:"fs-ptile-title mb-8 mt-8 mr-8 ml-8",staticStyle:{"width":"100%"}},[_vm._v(" Тут будут твои курсы ")])])
}]

export { render, staticRenderFns }