import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
// import profile from "./profile.module";

import authYougifted from "./auth_yougifted.module";
import user from "./user.module";
import programs from "./programs.module";
import courses from "./courses.module";
import training from "./training.module";
import trainings from "./trainings.module";
import rating from "./rating.module";
import measurements from "./measurements.module";
import food from "./food.module";
import comments from "./comments.module";
import orders from "./orders.module";
import live from "./live.module";
import profile from "./profile.module";
import recipes from "./recipes.module";
import recipe from "./recipe.module";
import recipesFood from "./recipesFood.module";
import dict from "./dict.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,

    authYougifted,
    user,
    programs,
    courses,
    training,
    trainings,
    rating,
    measurements,
    food,
    comments,
    orders,
    live,

    profile,
    recipes,
    recipe,
    recipesFood,
    dict,
  }
});
