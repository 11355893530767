// import { is } from "core-js/core/object";
import $ from "jquery";
import recipesService from "../../services/recipes.service";

// action types
export const GET_RECIPES_LIST = "getRecipesList";
export const GET_RECIPES_FILTERS = "getRecipesFilters";
export const GET_RECIPES_LIST_BY_FILTER = "getRecipesByFilter";
export const GET_RECIPE_COMMENTS = "getRecipeComments";

// mutator types
export const RECIPES_IS_FILTERED = "isRecipesFiltered";
export const RECIPES_IS_LOADED = "isRecipesLoaded";
export const RECIPES_IS_LOADING = "isRecipesLoading";

export const RECIPES_FILTERS_IS_LOADED = "isRecipesFiltersLoaded";
export const RECIPES_FILTERS_IS_LOADING = "isRecipesFiltersLoading";

// export const SWITCH_FILTER_BUTTON = "switchFilterButton";

export default {
  state: {
    buttons: {},
    filters: {
      list: {},
      isLoaded: false,
      isLoading: false
    },
    recipes: {
      all: [],
      list: [],
      temp: [],
      isFiltered: false,
      isLoaded: false,
      isLoading: false
    },
    _addRecipeToCategoryFunc: (
      recipesList,
      category,
      recipe,
      reverse = false
    ) => {
      if (!recipesList[category.code]) {
        recipesList[category.code] = {
          name: category.name,
          code: category.code,
          isAnchorShow:
            category.anchorDontShow && category.anchorDontShow === true
              ? false
              : true,
          hasAnchors: category.hasAnchors ? category.hasAnchors : false,
          recipes: []
        };
      }

      if (category.field) {
        recipesList[category.code].field = category.field;
      }
      if (category.limit) {
        recipesList[category.code].limit = category.limit;
      }

      if (reverse === true) {
        recipesList[category.code].recipes.unshift(recipe);
      } else {
        recipesList[category.code].recipes.push(recipe);
      }
    },
    _sortRecipesInCategoryFunc: category => {
      if (category.field) {
        category.recipes = category.recipes.sort((a, b) => {
          return b[category.field] - a[category.field];
        });
      }

      if (category.limit) {
        category.recipes.length = category.limit;
      }
    }
  },
  getters: {
    recipesList: state => state.filters.list,
    recipesFilters: state => state.filters.list,
    recipesFiltersButtons: state => state.buttons,
    recipesFiltersIsLoaded: state => state.filters.isLoaded,
    recipesByCategories: state => state.recipes.list,
    recipesIsLoaded: state => state.recipes.isLoading,
    recipesIsLoading: state => state.recipes.isLoaded,
    recipesIsFiltered: state => state.recipes.isFiltered
  },
  actions: {
    [GET_RECIPE_COMMENTS]({ commit }, comments) {
      return new Promise((resolve, reject) => {
        recipesService.getRecipeComments(
          comments.page,
          comments.trainingId,
          data => {
            commit(GET_RECIPE_COMMENTS, data);

            resolve(data);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    [GET_RECIPES_FILTERS]({ commit }) {
      commit(RECIPES_FILTERS_IS_LOADING, true);

      return new Promise((resolve, reject) => {
        recipesService.getRecipesFilters(
          response => {
            commit(GET_RECIPES_FILTERS, response);

            resolve(response);
          },
          error => {
            commit(RECIPES_FILTERS_IS_LOADING, false);
            commit(RECIPES_FILTERS_IS_LOADED, false);

            reject(error);
          }
        );
      });
    },
    [GET_RECIPES_LIST]({ commit }) {
      commit(RECIPES_IS_LOADING, true);

      return new Promise((resolve, reject) => {
        recipesService.getRecipesList(
          data => {
            commit(GET_RECIPES_LIST, data);

            resolve(data);
          },
          error => {
            commit(RECIPES_IS_LOADED, false);
            commit(RECIPES_IS_LOADING, false);

            reject(error);
          }
        );
      });
    },
    [GET_RECIPES_LIST_BY_FILTER]({ commit }, filter) {
      commit(GET_RECIPES_LIST_BY_FILTER, filter);
    }
    // ,
    // [SWITCH_FILTER_BUTTON]({ commit }, filterName) {
    //   commit(SWITCH_FILTER_BUTTON, filterName);
    // }
  },
  mutations: {
    [GET_RECIPES_FILTERS](state, filters) {
      state.filters.list = filters;
      state.filters.isLoaded = true;
      state.filters.isLoading = false;
    },
    [GET_RECIPES_LIST](state, data) {
      const customCategory = [
        // "popular_recipes",
        // "new_recipes",
        // "my_recipes"
        "new_recipes"
      ];

      let categoriesList = {};
      let recipesByCategories = [];

      // state.recipes.isFiltered = data.isFiltered;
      state.recipes.all = data;

      if (state.recipes.isFiltered === true) {
        $.each(state.recipes.all, (index, recipe) => {
          state._addRecipeToCategoryFunc(
            categoriesList,
            {
              name: "none",
              code: "none"
            },
            recipe
          );
        });

        // eslint-disable-next-line no-unused-vars
        $.each(categoriesList, (index, category) => {
          recipesByCategories.push(categoriesList[index]);
        });
      } else {
        $.each(state.recipes.all, (index, recipe) => {
          if (recipe.type && recipe.type.code && recipe.type.code !== "snack") {
            state._addRecipeToCategoryFunc(
              categoriesList,
              {
                name: "Новинки",
                code: "new_recipes",
                limit: 4,
                field: "id",
                anchorDontShow: true,
                hasAnchors: true
              },
              recipe,
              true
            );

            state._addRecipeToCategoryFunc(categoriesList, recipe.type, recipe);
          }
        });

        $.each(categoriesList, (index, category) => {
          state._sortRecipesInCategoryFunc(category);
          if ($.inArray(category.code, customCategory) < 0) {
            recipesByCategories.push(categoriesList[index]);
          }
        });

        $.each(customCategory, (index, category) => {
          if (categoriesList && categoriesList[category]) {
            recipesByCategories.unshift(categoriesList[category]);
          }
        });
      }

      state.recipes.list = recipesByCategories;
      state.recipes.temp = recipesByCategories;
    },
    [GET_RECIPES_LIST_BY_FILTER](state, filter) {
      let recipesByCategories = JSON.parse(JSON.stringify(state.recipes.all));
      let recipesByCategoriesArray = [];
      let categoriesList = {};
      let isEmptyFilters = true;

      let recipes = recipesByCategories.filter(recipe => {
        let isNeedToAdd = true;

        $.each(filter, (filterName, filterList) => {
          let isNeedToAddItem = false;
          let isEmptyFilter = filterList.length < 1 ? true : false;

          isEmptyFilters =
            isEmptyFilters !== false && filterList.length < 1 ? true : false;

          if (isEmptyFilter === true) {
            return;
          }

          switch (filterName) {
            case "allergies":
              $.each(recipe[filterName], (indexP, pItem) => {
                $.each(filterList, (indexF, fId) => {
                  if (fId == pItem.id) {
                    isNeedToAddItem = true;
                  }
                });
              });

              break;
            case "type":
              $.each(filterList, (indexF, fItem) => {
                if (
                  recipe[filterName] &&
                  recipe[filterName].id &&
                  fItem == recipe[filterName].id
                ) {
                  isNeedToAddItem = true;
                }
              });

              break;
            default:
              break;
          }

          if (
            isEmptyFilter === false &&
            isNeedToAddItem === false &&
            isNeedToAdd !== false
          ) {
            isNeedToAdd = false;
          }
        });

        if (isNeedToAdd === true) {
          return true;
        }

        return false;
      });

      if (isEmptyFilters === true) {
        state.recipes.isFiltered = false;
        state.recipes.list = state.recipes.temp;

        return;
      }

      $.each(recipes, (index, recipe) => {
        state._addRecipeToCategoryFunc(
          categoriesList,
          {
            name: "none",
            code: "none"
          },
          recipe
        );
      });

      // eslint-disable-next-line no-unused-vars
      $.each(categoriesList, (index, category) => {
        recipesByCategoriesArray.push(category);
      });

      state.recipes.list = recipesByCategoriesArray;
      state.recipes.isFiltered = true;
    },
    [RECIPES_FILTERS_IS_LOADED](state, isLoaded) {
      state.filters.isLoaded = isLoaded;
    },
    [RECIPES_FILTERS_IS_LOADING](state, isLoading) {
      state.filters.isLoading = isLoading;
    },
    [RECIPES_IS_LOADED](state, isLoaded) {
      state.recipes.isLoaded = isLoaded;
    },
    [RECIPES_IS_LOADING](state, isLoading) {
      state.recipes.isLoading = isLoading;
    }
    // ,
    // [SWITCH_FILTER_BUTTON](state, filterName) {
    //   let buttons = JSON.parse(JSON.stringify(state.buttons));

    //   if (
    //     filterName &&
    //     (!buttons[filterName] || buttons[filterName] == false)
    //   ) {
    //     buttons[filterName] = true;
    //   } else if (filterName && buttons[filterName] == true) {
    //     buttons[filterName] = false;
    //   }

    //   // eslint-disable-next-line no-unused-vars
    //   $.each(buttons, (index, value) => {
    //     if (index != filterName || !filterName) {
    //       buttons[index] = false;
    //     }
    //   });

    //   state.buttons = buttons;
    // }
  }
};
