import videojs from "video.js";
import ProgressBar from 'progressbar.js';

const Component = videojs.getComponent('Component');

class CustomProgress extends Component {

  constructor(player, options = {}) {
    super(player, options);

    this.on(player, 'playing', function() {
      if (!this.fullTime) {
        this.fullTime = player.duration();
      }

      if (!this.step) {
        this.step = 1;
      }
    });

    this.on(player, 'pause', function() {
      this.hide();
    });

    this.on(player, 'timeupdate', function() {
      let array = [];
      options.time_codes.forEach((timecode) => {
        array.push(...timecode);
        let current = player.currentTime();
        let activeItem = Object.entries(timecode).filter(circle => parseInt(current) > parseInt(circle[1].time_start) && parseInt(current) < parseInt(circle[1].time_end));
        let activeStep = Object.entries(timecode).findIndex(circle => parseInt(current) > parseInt(circle[1].time_start) && parseInt(current) < parseInt(circle[1].time_end));
        if (activeItem && activeItem.length > 0) {
          this.step = activeStep+1;
          let circles = Object.entries(timecode).filter(c => c[1].type == 'work').length;
          let start = activeItem[0][1].time_start;
          let end = activeItem[0][1].time_end;
          let title = activeItem[0][1].title;
          let circleNumber = activeItem[0][1].circle;
          let progress = ((current-start)/(end-start))*100;
          if (circleNumber !== 0) {
            this.circle.setText(`${circleNumber}/${circles}`);
          } else {
            this.circle.setText('');
          }
          this.circle.animate(progress/100)

          videojs.dom.emptyEl(this.el().querySelector("#vjs-custom__progress-title"));
          videojs.dom.appendContent(this.el().querySelector("#vjs-custom__progress-title"), title);
        }
      })

      let current = player.currentTime();
      let acitveItems = array.filter(circle => parseInt(current) > parseInt(circle.time_start) && parseInt(current) < parseInt(circle.time_end));

      if (acitveItems && acitveItems.length > 0) {
        this.show();
      } else {
        this.hide();
      }

    });

    /*this.on(player, 'pause', function() {
      this.hide();
    });*/
    this.on(player, 'play', function() {
      this.show();
    });
    /*this.on(player, 'useractive', function() {
      this.hide();
    });*/

    this.on(player, 'userinactive', function() {
      if (!player.paused()) {
        this.show();
      }
    });
    this.hide();
  }

  createEl() {
    setTimeout(() => {
      this.circle = new ProgressBar.Circle('#vjs-custom__progress', {
        color: '#EF16B0',
        duration: 55,
        easing: 'linear',
        strokeWidth: 10,
        trailColor: '#F4F7F9',
        text: {
          value: ``,
          className: 'vjs-custom__progress-label',
          style: null,
        }
      });
    }, 10);

    let circle_parent = document.createElement("div");
    let circle_round = document.createElement("div");
    let circle_title = document.createElement("div");
    circle_parent.id = "vjs-custom__progress-wrapper";
    circle_parent.classList.add("vjs-custom__progress-wrapper");
    circle_round.id = "vjs-custom__progress";
    circle_round.classList.add("vjs-custom__progress");
    circle_title.id = "vjs-custom__progress-title";
    circle_title.classList.add("vjs-custom__progress-title");
    circle_parent.appendChild(circle_round);
    circle_parent.appendChild(circle_title);

    return videojs.dom.createEl('div', {}, {}, circle_parent);
  }
}

export default CustomProgress;