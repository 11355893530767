import videojs from "video.js";

const Component = videojs.getComponent('Component');

class CustomProgress extends Component {

  constructor(player, options = {}) {
    super(player, options);

    this.hide();

    this.on(player, 'useractive', function() {
      this.show();
    });

    this.on(player, 'userinactive', function() {
      if (!player.paused()) {
        this.hide();
      }
    });

    this.on(player, 'pause', function() {
      setTimeout(() => {
        let current = player.currentTime();
        if (current !== 1 && current !== 0) {
          this.show();
        }
      }, 200)
    });

    if (options.titles) {
      this.updateTextContent(options.titles);
    }
  }

  createEl() {
    return videojs.dom.createEl('div', {
      className: 'vjs-custom__titles',
    });
  }

  updateTextContent(titles) {
    videojs.dom.emptyEl(this.el());

    const p1 = videojs.dom.createEl('p', {
      className: 'vjs-custom__titles-1',
    });
    videojs.dom.appendContent(p1, titles.title1);

    videojs.dom.appendContent(this.el(), p1);

    const p2 = videojs.dom.createEl('p', {
      className: 'vjs-custom__titles-2',
    });
    videojs.dom.appendContent(p2, titles.title2);

    videojs.dom.appendContent(this.el(), p2);
  }
}

export default CustomProgress;