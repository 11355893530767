<template>
  <div v-if="photosLoaded">
    <div class="row">
      <div class="col-sm-6" v-if="photos.all_photos_before_last.length">
        <p class="mb-1 font-blue-hoki">Было</p>
        <Carousel
          :perPage="1"
          :navigationEnabled="true"
          :paginationEnabled="false"
          navigationNextLabel="<i class='fa fa-angle-right'></i>"
          navigationPrevLabel="<i class='fa fa-angle-left'></i>"
          :navigate-to="currentPhotoIndex"
          @page-change="setCurrentPhotoIndex"
        >
          <Slide
            v-for="photo of photos.all_photos_before_last"
            :key="photo.date"
          >
            <img :src="photo.url" class="img-responsive" />
            <p class="font-blue-hoki mt-1 measurements-carousel__footer">
              <small>{{ photo.date }}</small>
              <small
                @click="confirmRemovePhoto(photo.date)"
                class="measurements-carousel__remove"
              >
                Удалить
              </small>
            </p>
          </Slide>
        </Carousel>
      </div>
      <div class="col-sm-6" v-if="photos.last_photo">
        <p
          class="mb-1 font-blue-hoki"
          v-if="photos.all_photos_before_last.length"
        >
          Стало
        </p>
        <img :src="photos.last_photo.url" class="img-responsive" />
        <p class="font-blue-hoki mt-1 measurements-carousel__footer">
          <small>{{ photos.last_photo.date }}</small>
          <small
            @click="confirmRemovePhoto(photos.last_photo.date)"
            class="measurements-carousel__remove"
          >
            Удалить
          </small>
        </p>
      </div>
    </div>
    <label :for="`${angle}-photo`" class="upload-file btn amrest-orange">
      Загрузить фото
      <input :id="`${angle}-photo`" @change="providePhoto" type="file" />
    </label>
    <span class="help-block" id="helpBlock">
      *Загруженные фотографии доступны только тебе.
    </span>

    <!--MODAL-->
    <div
      aria-hidden="true"
      class="modal fade"
      :id="`delete-photo-confirm-${angle}`"
      tabindex="-1"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-hidden="true"
              class="close"
              data-dismiss="modal"
              type="button"
            ></button>
            <h4 class="modal-title"><strong>Удалить фото?</strong></h4>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn dark btn-outline"
              data-dismiss="modal"
            >
              Отмена
            </button>
            <button
              type="button"
              class="btn red"
              data-dismiss="modal"
              @click="removePhoto(removedPhotoDate)"
            >
              Удалить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "MeasurementsPhotoTab",
  props: {
    angle: {
      type: String,
      required: true
    },
    photos: {
      type: Object,
      required: true
    }
  },
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      photosLoaded: false,
      errors: [],
      currentPhotoIndex: [],
      removedPhotoDate: null
    };
  },
  methods: {
    getPhotos(selectLastSlide = true) {
      this.$store.dispatch("getPhotos", this.angle).then(() => {
        this.photosLoaded = true;

        if (selectLastSlide) {
          this.goToLastSlide();
        } else {
          this.currentPhotoIndex =
            this.currentPhotoIndex === 0 ? 0 : [this.currentPhotoIndex, true];
        }
      });
    },
    providePhoto(event) {
      const image = event.target.files[0];

      if (image) {
        if (!image.type.includes("image/")) {
          this.errors.push("Выбери изображение");
          return;
        }

        this.$emit("crop-photo", image, this.angle);
      }
    },
    goToLastSlide() {
      this.currentPhotoIndex = [
        this.photos.all_photos_before_last.length - 1,
        false
      ];
    },
    setCurrentPhotoIndex(index) {
      this.currentPhotoIndex = [index, true];
    },
    confirmRemovePhoto(date) {
      this.removedPhotoDate = date;

      $(`#delete-photo-confirm-${this.angle}`).modal("show");
    },
    removePhoto(date) {
      this.$store
        .dispatch("removePhoto", {
          date,
          angle: this.angle
        })
        .then(() => {
          $(`#delete-photo-confirm-${this.angle}`).modal("hide");

          this.$notify({
            type: "success",
            title: "Ваше фото успешно удалено"
          });

          this.getPhotos(false);
        });
    }
  },
  created() {
    this.getPhotos();
  }
};
</script>
