import foodService from "../../services/food.service";

const recipesMap = [
  "breakfast.breakfast",
  "snack.maindish",
  "snack.sidedish",
  "snack.salad",
  "lunch.maindish",
  "lunch.sidedish",
  "lunch.salad",
  "dinner.maindish",
  "dinner.salad",
  "supper.maindish",
  "supper.salad"
];

export default {
  state: {
    isHaveDataForCalculate: null,
    currentRecipes: [],
    userData: {},
    recipes: {},
    recipe: {},
    availableRecipes: [],
    BMR: 0,
    BJU: {},
    recomendedProgram: null,
    isLoading: {
      userData: false,
      recipes: false,
      availableRecipes: false,
      recipe: false
    }
  },
  actions: {
    getFoodUserData({ commit }) {
      commit("user_data_loading_start");

      return new Promise((resolve, reject) => {
        foodService.getUserData(
          data => {
            commit("set_user_data", data);
            commit("user_data_loading_finish");
            resolve(data);
          },
          error => {
            commit("user_data_loading_finish");
            reject(error);
          }
        );
      });
    },
    updateFoodUserData(context, userData) {
      const formattedUserData = Object.assign({}, userData);

      if (formattedUserData.dob) {
        formattedUserData.dob = formattedUserData.dob
          .split(".")
          .reverse()
          .join("-");
      }
      formattedUserData.weight = formattedUserData.weight
        .toString()
        .replace(",", ".");

      return new Promise((resolve, reject) => {
        foodService.updateUserData(
          formattedUserData,
          data => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    updateFoodUserDataModal({ commit }, userData) {
      const formattedUserData = Object.assign({}, userData);

      if (formattedUserData.dob) {
        formattedUserData.dob = formattedUserData.dob
          .split(".")
          .reverse()
          .join("-");
      }
      if (formattedUserData.weight) {
        formattedUserData.weight = formattedUserData.weight
          .toString()
          .replace(",", ".");
      }

      return new Promise((resolve, reject) => {
        foodService.updateUserDataModal(
          formattedUserData,
          data => {
            commit("set_recomended_program", data.program);
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    getFoodRecipes({ commit }, loading) {
      if (loading) {
        commit("recipes_loading_start");
      }

      return new Promise((resolve, reject) => {
        foodService.getRecipes(
          data => {
            commit("set_recipes", data);
            commit("recipes_loading_finish");
            resolve(data);
          },
          error => {
            commit("recipes_loading_finish");
            reject(error);
          }
        );
      });
    },
    updateFoodRecipe({ commit, state }, params) {
      commit("update_current_recipes", {
        action: "update",
        params
      });

      return new Promise((resolve, reject) => {
        foodService.updateRecipes(
          state.currentRecipes,
          data => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    removeFoodRecipe({ commit, state }, params) {
      commit("update_current_recipes", {
        action: "delete",
        params
      });

      return new Promise((resolve, reject) => {
        foodService.updateRecipes(
          state.currentRecipes,
          data => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    getAvailableFoodRecipes({ commit }, dish) {
      commit("available_recipes_loading_start");

      return new Promise((resolve, reject) => {
        foodService.getAvailableRecipes(
          dish,
          data => {
            commit("set_available_recipes", data);
            commit("available_recipes_loading_finish");
            resolve(data);
          },
          error => {
            commit("available_recipes_loading_finish");
            reject(error);
          }
        );
      });
    },
    getAvailableFilterFoodRecipes({ commit }, filter) {
      commit("available_recipes_loading_start");

      return new Promise((resolve, reject) => {
        foodService.getAvailableFilterRecipes(
          filter,
          data => {
            commit("set_available_recipes", data);
            commit("available_recipes_loading_finish");
            resolve(data);
          },
          error => {
            commit("available_recipes_loading_finish");
            reject(error);
          }
        );
      });
    },
    getFoodAccess({ commit }) {
      return new Promise((resolve, reject) => {
        foodService.getAccess(
          data => {
            if (data.status === "false") {
              reject();
              commit("set_food_access", false);
              return;
            }

            commit("set_food_access", true);
            resolve(data);
          },
          error => {
            commit("set_food_access", false);
            reject(error);
          }
        );
      });
    },
    getFoodRecipe({ commit }, id) {
      commit("recipe_loading_start");
      commit("clear_recipe");

      return new Promise((resolve, reject) => {
        foodService.getRecipe(
          id,
          data => {
            commit("set_recipe", data);
            commit("recipe_loading_finish");
            resolve(data);
          },
          error => {
            commit("recipe_loading_finish");
            reject(error);
          }
        );
      });
    },
    getBMR({ commit }) {
      return new Promise((resolve, reject) => {
        foodService.getBMR(
          data => {
            commit("set_BMR", data.bmr);
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    getBJU({ commit }) {
      return new Promise((resolve, reject) => {
        foodService.getBJU(
          data => {
            commit("set_BJU", data);
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
      });
    }
  },
  mutations: {
    set_user_data(state, userData) {
      state.userData = userData;
    },
    set_BMR(state, BMR) {
      state.BMR = BMR;
    },
    set_BJU(state, BJU) {
      state.BJU = BJU;
    },
    set_recomended_program(state, program) {
      state.recomendedProgram = program;
    },
    set_recipes(state, recipes) {
      state.recipes = recipes;
      state.currentRecipes = [];

      for (const eating in state.recipes) {
        for (const recipe of state.recipes[eating]) {
          state.currentRecipes.push(parseInt(recipe.id, 10));
        }
      }

      state.currentRecipes = state.currentRecipes.slice(0, 11);
    },
    clear_recipe(state) {
      state.recipe = {};
    },
    set_recipe(state, recipe) {
      state.recipe = recipe;
    },
    update_current_recipes(state, params) {
      const { eating } = params.params;
      const { dish } = params.params;
      const { id } = params.params || null;
      const editedRecipeIndex = recipesMap.indexOf(`${eating}.${dish}`);

      switch (params.action) {
        case "delete": {
          state.currentRecipes[editedRecipeIndex] = 0;
          break;
        }
        case "update": {
          state.currentRecipes[editedRecipeIndex] = parseInt(id, 10);
          break;
        }
        default: {
          break;
        }
      }
    },
    set_available_recipes(state, recipes) {
      state.availableRecipes = recipes;
    },
    set_food_access(state, access) {
      state.isHaveDataForCalculate = access;
    },
    user_data_loading_start(state) {
      state.isLoading.userData = true;
    },
    user_data_loading_finish(state) {
      state.isLoading.userData = false;
    },
    recipes_loading_start(state) {
      state.isLoading.recipes = true;
    },
    recipes_loading_finish(state) {
      state.isLoading.recipes = false;
    },
    recipe_loading_start(state) {
      state.isLoading.recipe = true;
    },
    recipe_loading_finish(state) {
      state.isLoading.recipe = false;
    },
    available_recipes_loading_start(state) {
      state.isLoading.availableRecipes = true;
    },
    available_recipes_loading_finish(state) {
      state.isLoading.availableRecipes = false;
    }
  },
  getters: {
    foodUserData: state => state.userData,
    foodRecomendedProgram: state => state.recomendedProgram,
    foodRecipes: state => state.recipes,
    foodRecipe: state => state.recipe,
    foodAvailableRecipes: state => state.availableRecipes,
    foodBMR: state => state.BMR,
    foodBJU: state => state.BJU,
    foodLoaders: state => state.isLoading,
    foodIsHaveDataForCalculate: state => state.isHaveDataForCalculate
  }
};
