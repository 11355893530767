import videojs from "video.js";

const Component = videojs.getComponent('Component');

class CustomTime extends Component {

  constructor(player, options = {}) {
    super(player, options);

    if (options.start && options.end) {
      this.updateTextContent(`${Math.floor((options.start)/60)}`);
    }
    this.hide();
    this.on(player, 'timeupdate', function() {
      let current = player.currentTime();
      let start = options.start;
      let end = options.end;
      if (current > start && current < end) {
        this.show();
        let time = end-(current-start);

        let timeFormated = new Date(time * 1000).toISOString().substring(14, 19);
        this.updateTextContent(`${timeFormated}`);
      } else {
        this.hide();
      }

    });
  }

  createEl() {
    return videojs.dom.createEl('div', {
      className: 'vjs-custom__time',
    });
  }

  updateTextContent(text) {
    videojs.dom.emptyEl(this.el());
    videojs.dom.appendContent(this.el(), text);
  }
}

export default CustomTime;