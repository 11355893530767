<template>
  <div>
    <NoticeSubscriptionRenewal v-if="!userSubscriptions"/>

    <div class="card card-custom card--with-form">
      <div class="card-header card-header-tabs-line nav-tabs-line-3x">
        <div class="card-toolbar">
          <ul class="nav nav-tabs nav-bold nav-tabs-line nav-tabs-line-3x">
            <li class="nav-item">
              <a
                class="nav-link active"
                data-toggle="tab"
                href="#kt_tab_pane_1_4"
              >
                <span class="nav-icon">
                  <span class="svg-icon svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="media/svg/icons//Design/Layers.svg"
                    ></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
                <span class="nav-text">Мои данные</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#kt_tab_pane_2_4">
                <span class="nav-icon">
                  <span class="svg-icon svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="media/svg/icons/General/User.svg"
                    ></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
                <span class="nav-text">Редактировать профиль</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#kt_tab_pane_3_4">
                <span class="nav-icon">
                  <span class="svg-icon svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="media/svg/icons/Communication/Shield-user.svg"
                    ></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
                <span class="nav-text">Сменить пароль</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="tab-content">
        <div
          class="tab-pane fade show active"
          id="kt_tab_pane_1_4"
          role="tabpanel"
          aria-labelledby="kt_tab_pane_1_4"
        >
          <AccountInformation @questionnaireOpen="$emit('questionnaireOpen', userData)"></AccountInformation>
        </div>
        <div
          class="tab-pane fade"
          id="kt_tab_pane_2_4"
          role="tabpanel"
          aria-labelledby="kt_tab_pane_2_4"
        >
          <PersonalInformation @questionnaireOpen="$emit('questionnaireOpen', userData)"></PersonalInformation>
        </div>
        <div
          class="tab-pane fade"
          id="kt_tab_pane_3_4"
          role="tabpanel"
          aria-labelledby="kt_tab_pane_3_4"
        >
          <ChangePassword @questionnaireOpen="$emit('questionnaireOpen', userData)"></ChangePassword>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

import PersonalInformation from "@/components/profile/PersonalInformation";
import ChangePassword from "@/components/profile/ChangePassword";
import AccountInformation from "@/components/profile/AccountInformation";
import NoticeSubscriptionRenewal from '@/components/content/NoticeSubscriptionRenewal.vue';

export default {
  name: "profileTab",
  components: {
    PersonalInformation,
    ChangePassword,
    AccountInformation,
    NoticeSubscriptionRenewal
  },
  data() {
    return {
      tabIndex: 0
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Профайл" }]);
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo", "userData"]),

    getFullName() {
      return (
        this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname
      );
    },

    userSubscriptions() {
      return this.$store.getters.userSubscriptions;
    },
  },
  setPageData() {
    switch (this.socialIsMyProfile) {
      case true:
        this.pageTitle = "Профайл - FitStars.ru";
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Профайл" }]);

        break;
      case false:
        this.pageTitle = "Профайл - FitStars.ru";
        this.$store.dispatch(SET_BREADCRUMB, [
          {
            title: "Профайл - FitStars.ru"
          }
        ]);

        break;
    }
  }
};
</script>

<style lang="scss">
.card--with-form {
  padding-bottom: 80px;

  @media screen and (max-width: 767px) {
    padding-bottom: 40px;
  }
}
</style>
