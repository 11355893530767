<template>
  <div class="">
    <span v-if="timeString">{{ timeString }}</span>
  </div>
</template>

<script>
import { numberToText } from "@/assets/js/helpers";

export default {
  name: "BackTimer",
  props: {
    date: String
  },
  computed: {
    timeString() {
      let dateNow = Date.now();
      let date = new Date(this.date);
      let diffDate = dateNow - date;

      let sec = Math.floor(diffDate / 1000);
      let min = Math.floor(sec / 60);
      let hour = Math.floor(min / 60);
      let day = Math.floor(hour / 24);
      let month = Math.floor(day / 30);
      let year = Math.floor(month / 12);

      let diffTotal = null;

      if (year >= 1) {
        diffTotal = `${year} ${numberToText(year, [
          "год",
          "года",
          "лет"
        ])} назад`;
      } else if (month >= 1) {
        diffTotal = `${month} ${numberToText(month, [
          "месяц",
          "месяца",
          "месяцев"
        ])} назад`;
      } else if (day >= 1) {
        diffTotal = `${day} ${numberToText(day, [
          "день",
          "дня",
          "дней"
        ])} назад`;
      } else if (hour >= 1) {
        diffTotal = `${hour} ${numberToText(hour, [
          "час",
          "часа",
          "часов"
        ])} назад`;
      } else if (min >= 1) {
        diffTotal = `${min} ${numberToText(min, [
          "минута",
          "минуты",
          "минут"
        ])} назад`;
      } else {
        diffTotal = `менее минуты назад`;
      }

      return diffTotal;
    },
  }
};
</script>
