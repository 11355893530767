import videojs from 'video.js';
import 'animate.css';

const Plugin = videojs.getPlugin('plugin');

class CustomCountDown extends Plugin {

  constructor(player, options = {}) {
    super(player, options);

    this.player = player;
    this.animated = false;
    this.times = options.times || [];
    this.setup();
  }

  setup() {
    this.player.on('timeupdate', () => {
      if (this.times.find(time => time == Math.round(this.player.currentTime()))) {
        this.showCountdown();
      }
    });
  }
  showCountdown() {
    if (!this.animated) {
      this.animated = true;
      this.player.el().insertAdjacentHTML(
        'beforeend',
        `<div class="vjs-custom__counter animate__animated animate__zoomIn animate__repeat-3">03</div>`
      );

      setTimeout(() => {
        document.querySelectorAll(".vjs-custom__counter").forEach(e => e.innerHTML = '02');
      }, 1100)
      setTimeout(() => {
        document.querySelectorAll(".vjs-custom__counter").forEach(e => e.innerHTML = '01');
      }, 2000)
      setTimeout(() => {
        document.querySelectorAll(".vjs-custom__counter").forEach(e => e.innerHTML = '00');
        document.querySelectorAll(".vjs-custom__counter").forEach(e => e.remove());
        this.animated = false;
      }, 2700)
    }
  }
}

export default CustomCountDown;