import settings from "../../settings";
import instance from "./instance.service";
import { getAuthHeader } from "./auth.service";

const basePath = `${settings.API_URL}`;
const basePathV2 = `${settings.API_URL_V2}`;

export default {
  getOrders(successCallback, errorCallback) {
    instance
      .get(`${basePath}user/orders/`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getSubscriptions(successCallback, errorCallback) {
    instance
      .get(`${basePath}user/subscription/`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getCards(successCallback, errorCallback) {
    instance
      .get(`${basePath}user/cards/`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  removeCard(card, successCallback, errorCallback) {
    instance
      .delete(`${basePath}user/cards/${card.id}/${card.type}`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getReasonUnsubscribeList(successCallback, errorCallback) {
    instance
      .get(`${basePathV2}reason_unsubscribe/list`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  sendReasonUnsubscribeList(payload, successCallback, errorCallback) {
    instance
      .post(`${basePathV2}reason_unsubscribe/send`, payload, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  }
};
