import instance from "./instance.service";
import settings from "../../settings";
import { getAuthHeader } from "./auth.service";

const basePath = `${settings.API_URL}`;

export default {
  logTrainingYoutubeData(data, successCallback, errorCallback) {
    instance
      .post(`${basePath}user/training/log/youtube`, data, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getTraining(data, successCallback, errorCallback) {
    let url = `${basePath}user/training`;

    if (data.pack && data.id) {
      url += `?pack=${data.pack}&id=${data.id}`;
    } else if (data.pack && !data.id) {
      url += `?pack=${data.pack}`;
    }

    instance
      .get(url, { headers: getAuthHeader() })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getTrainingPlan(data, successCallback, errorCallback) {
    instance
      .get(`${basePath}user/programs/${data.pack_id}/trainings`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getComments(page, id, successCallback, errorCallback) {
    const params = {};

    if (page) {
      params.page = page;
    }

    instance
      .get(`${basePath}user/training/${id}/comments`, {
        params,
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  addComment(commentData, successCallback, errorCallback) {
    instance
      .post(`${basePath}user/comment/training`, commentData, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getPrevMonth(data, successCallback, errorCallback) {
    instance
      .get(`${basePath}${data.url}`, { headers: getAuthHeader() })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getNextMonth(data, successCallback, errorCallback) {
    instance
      .get(`${basePath}${data.url}`, { headers: getAuthHeader() })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getTrainingPack(data, successCallback, errorCallback) {
    instance
      .get(`${basePath}user/training?&pack=${data.pack}`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getFavoriteTrainings(successCallback, errorCallback) {
    instance
      .get(`${basePath}user/training/favorites`, { headers: getAuthHeader() })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getTrainingsCompleted(successCallback, errorCallback) {
    instance
      .get(`${basePath}user/training/completed`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  likeTraining(data, successCallback, errorCallback) {
    instance
      .post(`${basePath}user/toggle-like/training/${data.id}`, data.id, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  dislikeTraining(data, successCallback, errorCallback) {
    instance
      .post(`${basePath}user/toggle-dislike/training/${data.id}`, data.id, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  setTrainingReady(data, successCallback, errorCallback) {
    instance
      .post(
        `${basePath}user/complete/training/${data.id}/${data.pack}`,
        data.id,
        {
          headers: getAuthHeader()
        }
      )
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  deleteTrainingReady(data, successCallback, errorCallback) {
    instance
      .delete(
        `${basePath}user/cancel/training/${data.id}/${data.pack}`,
        {
          headers: getAuthHeader()
        }
      )
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  setFavoriteTraining(data, successCallback, errorCallback) {
    instance
      .post(`${basePath}user/training/favorites/`, data, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  removeFavoriteTraining(data, successCallback, errorCallback) {
    instance
      .delete(`${basePath}user/training/favorites/${data.id}`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  }
};
