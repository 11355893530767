import videojs from "video.js";
import 'animate.css';

const ClickableComponent = videojs.getComponent('ClickableComponent');

class CustomPlayCounter extends ClickableComponent {

  constructor(player, options = {}) {
    super(player, options);
    this.isShowCounter = options.showCounterBeforeStart;
    this.animated = false;
    this.on(player, 'play', () => this.hide());
  }

  createEl() {
    return videojs.dom.createEl('div', {
      className: 'vjs-custom__play'
    });
  }

  handleClick() {
    if (this.isShowCounter) {
      if (!this.animated) {
        this.animated = true;
        this.removeClass('vjs-custom__play');
        this.addClass('vjs-custom__counter');
        videojs.dom.appendContent(this.el(), '03');
        this.addClass('animate__animated');
        this.addClass('animate__zoomIn');
        this.addClass('animate__repeat-3');
        setTimeout(() => {
          videojs.dom.emptyEl(this.el());
          videojs.dom.appendContent(this.el(), '02');
        }, 1100)
        setTimeout(() => {
          videojs.dom.emptyEl(this.el());
          videojs.dom.appendContent(this.el(), '01');
        }, 2000)
        setTimeout(() => {
          videojs.dom.emptyEl(this.el());
          videojs.dom.appendContent(this.el(), '00');
          this.addClass('animate__animated');
          this.addClass('animate__zoomIn');
          this.addClass('animate__repeat-3');
          this.hide()
        }, 2700)
        setTimeout(() => {
          this.animated = false;
          this.player_.play()
        }, 3100)
      }
    } else {
      this.hide()
      this.player_.play()
    }
  }
}

export default CustomPlayCounter;