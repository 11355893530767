import $ from "jquery";
import trainigsService from "../../services/trainings.service";

export default {
  state: {
    trainingsList: [],
    trainingsFiltersData: {
      time: [],
      bode_parts: [],
      trainers: []
    },
    trainingsIsLoading: false
  },
  getters: {
    trainingsList: state => state.trainingsList,
    trainingsFiltersData: state => state.trainingsFiltersData,
    trainingsIsLoading: state => state.trainingsIsLoading
  },
  actions: {
    getTrainingsList({ commit }, filterData) {
      commit("trainings_list_loading_start");

      return new Promise((resolve, reject) => {
        trainigsService.getTrainingsList(
          filterData,
          data => {
            const response = {
              data: data,
              filter: filterData
            };

            commit("set_trainings_list", response);
            commit("trainings_list_loading_finish");
            resolve(data);
          },
          error => {
            commit("trainings_list_loading_finish");
            reject(error);
          }
        );
      });
    },
    getTrainingsFiltersData({ commit }) {
      return new Promise((resolve, reject) => {
        trainigsService.getTrainingsFiltersData(
          data => {
            commit("set_trainings_filters_data", data);
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
      });
    }
  },
  mutations: {
    set_trainings_list(state, data) {
      if (!data.data && data.filter.offset === 0) {
        state.trainingsList = [];
      } else if (data.data && data.filter.offset === 0) {
        state.trainingsList = data.data;
      } else if (data.data && data.filter.offset > 0) {
        // eslint-disable-next-line no-unused-vars
        $.each(data.data, (key, value) => {
          if (!value) {
            return;
          }
          state.trainingsList.push(value);
        });
      }
    },
    set_trainings_filters_data(state, trainingsFiltersData) {
      state.trainingsFiltersData = trainingsFiltersData;
    },
    trainings_list_loading_start(state) {
      state.trainingsIsLoading = true;
    },
    trainings_list_loading_finish(state) {
      state.trainingsIsLoading = false;
    }
  }
};
