import $ from "jquery";

import recipesFoodService from "../../services/recipesFood.service";

// action types
export const GET_RECIPES_FOOD_DATA = "getRecipesFoodData";
export const GET_RECIPES_FOOD_FILTERS = "getRecipesFoodFilters";
export const GET_RECIPES_FOOD_FILTER_BY_TYPE = "getRecipesFoodFiltersByType";
export const UPDATE_RECIPES_FOOD_FAVORITES = "updateRecipesFoodFavorites";

// mutator types
export const GET_RECIPES_FOOD_FILTERS_RECIPE = "getRecipesFoodFiltersRecipe";
export const SET_RECIPES_FOOD_FILTER = "setRecipesFoodFilter";
export const DELETE_RECIPES_FOOD_DATA = "deleteRecipesFoodData";
export const UPDATE_RECIPES_FOOD_DATA = "updateRecipesFoodData";

export const RECIPES_FOOD_DATA_IS_LOADED = "isRecipesFoodListLoaded";
export const RECIPES_FOOD_DATA_IS_LOADING = "isRecipesFoodListLoading";
export const RECIPES_FOOD_FILTERS_IS_LOADED = "isRecipesFoodFiltersLoaded";
export const RECIPES_FOOD_FILTERS_IS_LOADING = "isRecipesFoodFiltersLoading";
export const RECIPES_FOOD_FILTER_IS_LOADED = "isRecipesFoodFilterLoaded";
export const RECIPES_FOOD_FILTER_IS_LOADING = "isRecipesFoodFilterLoading";

export default {
  state: {
    filter: {
      type: null,
      allergies: []
    },
    filters: {
      recipes: [],
      allergies: [],
      columns: [],
      isLoaded: false,
      isLoading: false
    },
    KBJUByRation: {
      kkal: 0,
      protein: 0,
      fat: 0,
      carbon: 0
    },
    recipesByRation: {
      list: [],
      hasFavorite: false,
      kkal: 0,
      isFiltered: false,
      isLoaded: false,
      isLoading: false,
      isUpdated: false
    },
    recipesByType: [],
    recipesTypesByRation: {
      breakfast: ["breakfast"],
      snack: ["maindish", "sidedish", "salad"],
      lunch: ["maindish", "sidedish", "salad"],
      dinner: ["maindish", "salad"],
      supper: ["maindish", "salad"]
    },
    _calculateKBJU: (currentKBJU, recipe, action) => {
      const sign = action ? 1 : -1;

      let KBJU = {
        kkal: currentKBJU.kkal + sign * recipe.kkal || 0,
        fat: currentKBJU.fat + sign * recipe.fat || 0,
        carbon: currentKBJU.carbon + sign * recipe.carbon || 0,
        protein: currentKBJU.protein + sign * recipe.protein || 0
      };

      return KBJU;
    }
  },
  getters: {
    recipesFoodFilter: state => state.filter,
    recipesFoodFilters: state => state.filters,
    recipesFoodKBJUByRation: state => state.KBJUByRation,
    recipesFoodRecipesByType: state => state.recipesByType,
    recipesFoodHasFovorite: state => state.recipesByRation.hasFavorite,
    recipesFoodKkalFovorite: state => state.recipesByRation.kkal,
    recipesFoodRecipesByRation: state => state.recipesByRation.list
  },
  actions: {
    [UPDATE_RECIPES_FOOD_FAVORITES]({ commit }, requestData) {
      return new Promise((resolve, reject) => {
        recipesFoodService.updateRecipesDataFavorites(
          requestData,
          response => {
            commit(UPDATE_RECIPES_FOOD_FAVORITES, false);

            resolve(response);
          },
          error => {
            commit(UPDATE_RECIPES_FOOD_FAVORITES, false);

            reject(error);
          }
        );
      });
    },
    [SET_RECIPES_FOOD_FILTER]({ commit }, selected) {
      commit(SET_RECIPES_FOOD_FILTER, selected);
    },
    [GET_RECIPES_FOOD_FILTERS_RECIPE]({ commit }, rationType) {
      commit(GET_RECIPES_FOOD_FILTERS_RECIPE, rationType);
    },
    [GET_RECIPES_FOOD_FILTERS]({ commit }) {
      commit(RECIPES_FOOD_FILTERS_IS_LOADING, true);

      return new Promise((resolve, reject) => {
        recipesFoodService.getRecipesFoodFilters(
          response => {
            commit(GET_RECIPES_FOOD_FILTERS, response);

            resolve(response);

            commit(RECIPES_FOOD_FILTERS_IS_LOADING, false);
            commit(RECIPES_FOOD_FILTERS_IS_LOADED, true);
          },
          error => {
            commit(RECIPES_FOOD_FILTERS_IS_LOADING, false);
            commit(RECIPES_FOOD_FILTERS_IS_LOADED, false);

            reject(error);
          }
        );
      });
    },
    [GET_RECIPES_FOOD_FILTER_BY_TYPE]({ commit }, requestData) {
      commit(RECIPES_FOOD_FILTER_IS_LOADING, true);

      return new Promise((resolve, reject) => {
        recipesFoodService.getRecipesFoodFilterByType(
          requestData,
          response => {
            commit(GET_RECIPES_FOOD_FILTER_BY_TYPE, response);

            resolve(response);

            commit(RECIPES_FOOD_FILTER_IS_LOADING, false);
            commit(RECIPES_FOOD_FILTER_IS_LOADED, true);
          },
          error => {
            reject(error);

            commit(RECIPES_FOOD_FILTER_IS_LOADING, false);
            commit(RECIPES_FOOD_FILTER_IS_LOADED, false);
          }
        );
      });
    },
    [GET_RECIPES_FOOD_DATA]({ commit }, request) {
      commit(RECIPES_FOOD_DATA_IS_LOADING, true);

      return new Promise((resolve, reject) => {
        recipesFoodService.getRecipesData(
          request,
          response => {
            commit(GET_RECIPES_FOOD_DATA, response.data);

            resolve(response);

            commit(RECIPES_FOOD_DATA_IS_LOADED, true);
            commit(RECIPES_FOOD_DATA_IS_LOADING, false);
          },
          error => {
            commit(RECIPES_FOOD_DATA_IS_LOADED, false);
            commit(RECIPES_FOOD_DATA_IS_LOADING, false);

            reject(error);
          }
        );
      });
    },
    [UPDATE_RECIPES_FOOD_DATA]({ commit }, requestData) {
      commit(UPDATE_RECIPES_FOOD_DATA, requestData);
    },
    [DELETE_RECIPES_FOOD_DATA]({ commit }, data) {
      commit(DELETE_RECIPES_FOOD_DATA, data);
    }
  },
  mutations: {
    [RECIPES_FOOD_FILTER_IS_LOADED](state, isLoaded) {
      state.filters.isLoaded = isLoaded;
    },
    [RECIPES_FOOD_FILTER_IS_LOADING](state, isLoading) {
      state.filters.isLoading = isLoading;
    },
    [RECIPES_FOOD_FILTERS_IS_LOADED](state, isLoaded) {
      state.filters.isLoaded = isLoaded;
    },
    [RECIPES_FOOD_FILTERS_IS_LOADING](state, isLoading) {
      state.filters.isLoading = isLoading;
    },
    [RECIPES_FOOD_DATA_IS_LOADED](state, isLoaded) {
      state.recipesByRation.isLoaded = isLoaded;
    },
    [RECIPES_FOOD_DATA_IS_LOADING](state, isLoading) {
      state.recipesByRation.isLoading = isLoading;
    },
    [GET_RECIPES_FOOD_FILTERS](state, data) {
      if (data.allergies) {
        state.filters.allergies = data.allergies;
      }
    },
    [SET_RECIPES_FOOD_FILTER](state, selected) {
      state.filter.allergies = selected;
    },
    [GET_RECIPES_FOOD_FILTER_BY_TYPE](state, recipes) {
      state.filters.recipes = recipes;
    },
    [GET_RECIPES_FOOD_FILTERS_RECIPE](state, rationType) {
      let isEmptyRationType = rationType ? false : true;
      let isEmptyFilter = state.filters.selected.length === 0 ? true : false;
      let recipesList = JSON.parse(JSON.stringify(state.filters.recipes));

      let recipesByType = recipesList.filter(recipe => {
        let isNeedToAddItem = false;

        $.each(recipe.allergies, (index, allergy) => {
          if (
            isNeedToAddItem === false &&
            $.inArray(allergy.id, state.filters.selected) > -1
          ) {
            isNeedToAddItem = true;
          }
        });

        if (
          (isNeedToAddItem === true || isEmptyFilter === true) &&
          (isEmptyRationType === true ||
            (isEmptyRationType === false &&
              $.inArray(
                recipe.recipe_type.code,
                state.recipesTypesByRation[rationType]
              ) > -1))
        ) {
          return true;
        }

        return false;
      });

      state.recipesByType = recipesByType;
    },
    [GET_RECIPES_FOOD_DATA](state, data) {
      state.KBJUByRation = {
        kkal: 0,
        protein: 0,
        fat: 0,
        carbon: 0
      };

      $.each(data.recipes, (eatingType, recipes) => {
        $.each(recipes, (indexRecipe, recipe) => {
          state.KBJUByRation.kkal = state.KBJUByRation.kkal + recipe.kkal;
          state.KBJUByRation.protein =
            state.KBJUByRation.protein + recipe.protein;
          state.KBJUByRation.fat = state.KBJUByRation.fat + recipe.fat;
          state.KBJUByRation.carbon = state.KBJUByRation.carbon + recipe.carbon;
        });
      });

      state.filter.type = data.type;
      state.filters.columns = data.columns;
      state.recipesByRation.list = data.recipes;
      state.recipesByRation.kkal = data.kkalFavorite;
      state.recipesByRation.hasFavorite = data.hasFavorite;
    },
    [UPDATE_RECIPES_FOOD_DATA](state, data) {
      const currentRecipeIndex = data.currentRecipeIndex;
      const recipeIndex = parseInt(data.recipeIndex);
      const eatingType = data.eatingType;
      const selectedRecipes = JSON.parse(JSON.stringify(state.filters.recipes));
      const selectedRecipe = selectedRecipes[recipeIndex];

      let currentRecipesList = JSON.parse(
        JSON.stringify(state.recipesByRation.list)
      );

      if (currentRecipeIndex != null || currentRecipeIndex === 0) {
        let KBJU = state._calculateKBJU(
          state.KBJUByRation,
          currentRecipesList[eatingType][currentRecipeIndex],
          false
        );

        state.KBJUByRation = state._calculateKBJU(KBJU, selectedRecipe, true);

        currentRecipesList[eatingType][currentRecipeIndex] = selectedRecipe;
      } else {
        state.KBJUByRation = state._calculateKBJU(
          state.KBJUByRation,
          selectedRecipe,
          true
        );

        currentRecipesList[eatingType].push(selectedRecipe);
      }

      state.recipesByRation.list = currentRecipesList;
    },
    [DELETE_RECIPES_FOOD_DATA](state, data) {
      state.KBJUByRation = state._calculateKBJU(
        state.KBJUByRation,
        state.recipesByRation.list[data.eatingType][data.index],
        false
      );

      state.recipesByRation.list[data.eatingType].splice(data.index, 1);
    },
    [UPDATE_RECIPES_FOOD_FAVORITES](state, isUpdated) {
      state.recipesByRation.isUpdated = isUpdated;
      state.recipesByRation.hasFavorite = true;
      state.recipesByRation.kkal = state.KBJUByRation.kkal;
    }
  }
};
