import Vue from "vue";
import Router from "vue-router";

import Layout from "@/views/Layout.vue";
import Profile from "@/views/Profile/index.vue";
import ProfileSettings from "@/views/Profile/Settings.vue";
import Favorites from "@/views/Favorites.vue";
import Orders from "@/views/Orders.vue";
import Programs from "@/views/Programs.vue";
import Courses from "@/views/Courses.vue";
import Trainings from "@/views/Trainings/index.vue";
import TrainingsItem from "@/views/Trainings/Item.vue";
import Progress from "@/views/Progress.vue";
import InviteFriends from "@/views/InviteFriends.vue";
import Recipes from "@/views/Recipes/index.vue";
import RecipesFood from "@/views/Recipes/Food.vue";
import RecipesItem from "@/views/Recipes/Item.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "layout",
      component: Layout,
      children: [
        {
          path: "/fitness-profile/:url_slug",
          name: "fitness-profile",
          component: Profile,
          props: true
        },
        {
          path: "/new-profile",
          name: "new-profile",
          component: ProfileSettings
        },
        {
          path: "/favorites",
          name: "favorites",
          component: Favorites
        },
        {
          path: "/orders",
          name: "orders",
          component: Orders
        },
        {
          path: "/programs",
          name: "programs",
          component: Programs
        },
        {
          path: "/programs/my",
          name: "programs-my",
          component: Programs
        },
        {
          path: "/courses",
          name: "courses",
          component: Courses
        },
        {
          path: "/courses/my",
          name: "courses-my",
          component: Courses
        },
        {
          path: "/trainings",
          name: "trainings",
          component: Trainings
        },
        {
          path: "/training",
          name: "training-pack",
          component: TrainingsItem
        },
        {
          path: "/training/:pack",
          name: "training-pack-pack",
          component: TrainingsItem
        },
        {
          path: "/training/:pack/:training",
          name: "training-pack-pack-training",
          component: TrainingsItem
        },
        {
          path: "/progress",
          name: "progress",
          component: Progress
        },
        {
          path: "/invite-friends",
          name: "invite-friends",
          component: InviteFriends
        },
        {
          path: "/recipes",
          name: "recipes",
          component: Recipes
        },
        {
          path: "/recipes/calculator",
          name: "recipe",
          component: RecipesFood
        },
        {
          path: "/recipes/:id",
          name: "recipe",
          component: RecipesItem
        },
      ]
    },
    {
      path: "/login",
      redirect: () => {
        window.location.href = "http://fitstars.ru/login";
      }
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/Error.vue")
    }
  ]
});
