<template>
  <!-- begin::Content loader -->
  <div class="content-loader content-loader-logo">
    <img alt="Logo" :src="logo" width="100" />
    <div class="spinner" v-bind:class="spinnerClass || 'spinner-primary'"></div>
  </div>
  <!-- end::Content Loader -->
</template>

<script>
export default {
  name: "ContentLoader",
  props: {
    logo: String,
    spinnerClass: String
  }
};
</script>
